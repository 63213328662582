import DatePicker from 'react-datepicker';
import {useDispatch, useSelector} from 'react-redux';
import React, {useState} from 'react';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import {ChevronDownIcon} from '@primer/octicons-react';
import './MyDatePicker.scss';
import {setGlobal} from '../../../../redux/slices/globalSlice';
export const MyDatePicker = props => {
  const dispatch = useDispatch();
  const {global} = useSelector(state => state);
  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState(
    props?.dateRange || [
      moment().subtract(7, 'days').toDate(),
      moment().toDate(),
    ],
  );
  const [startDate, endDate] = dateRange;
  const CustomDatePickerInput = ({value, onClick}) => (
    <button onClick={() => setIsOpen(!isOpen)} className="main_btn">
      {value}
      <span>
        <ChevronDownIcon />
      </span>
    </button>
  );
  const handleDateRangeChange = (amount, unit) => {
    const newDateRange = [
      moment().subtract(amount, unit).toDate(),
      moment().toDate(),
    ];
    setDateRange(newDateRange);
    props?.onChange(newDateRange);
    dispatch(setGlobal({timesheetDateRange: newDateRange}));
  };

  return (
    <>
      <div className="my_date_picker" data-theme={global.isMode}>
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          open={isOpen}
          endDate={endDate}
          onChange={newDateRange => {
            setDateRange(newDateRange);
            if (newDateRange[1]) {
              props?.onChange(newDateRange);
              setIsOpen(false);
              dispatch(setGlobal({timesheetDateRange: newDateRange}));
            }
          }}
          // inline
          dateFormat="MMMM d, yyyy"
          customInput={<CustomDatePickerInput />}
          maxDate={new Date()}
          isClearable={false}>
          <div className="bottom_btn_div">
            <button onClick={() => handleDateRangeChange(1, 'week')}>
              Last Week
            </button>
            <button onClick={() => handleDateRangeChange(1, 'month')}>
              Last month
            </button>
            <button onClick={() => handleDateRangeChange(6, 'months')}>
              Last 6 months
            </button>
            <button onClick={() => handleDateRangeChange(1, 'year')}>
              Last year
            </button>
            {props?.userJoinedDays ? (
              <button
                onClick={() =>
                  handleDateRangeChange(props?.userJoinedDays, 'days')
                }>
                All Time
              </button>
            ) : null}
          </div>
        </DatePicker>
      </div>
    </>
  );
};
