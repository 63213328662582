import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Skeleton} from 'antd';
import {IoIosPlay} from 'react-icons/io';
import {MdOutlinePause} from 'react-icons/md';
import {TriangleRightIcon} from '@primer/octicons-react';
import useClickOutside from '../../../CustomHooks/ClickOutside';
import NoActivityImg from '../../../../../pics/Management/no_activity.svg';
import {TimesheetMenu} from './TimesheetMenu';
import {useDispatch, useSelector} from 'react-redux';
import {
  getUserActivity,
  setUserActivity,
  fetchUserActivities,
} from '../../../../../redux/slices/userActivitySlice';
import moment from 'moment';
import {Block, Notify, Report} from 'notiflix';
import Axios from './../../../../../config/api';
import {formatDuration} from '../../../../../utils/functions';

const ActivityData = ({title, time, isRunning, TimerhandleButtonClick}) => (
  <div className="activity_data_div">
    <span
      style={{background: isRunning ? '#1F883D' : '#9747FF'}}
      onClick={TimerhandleButtonClick}>
      {isRunning ? (
        <MdOutlinePause />
      ) : (
        <IoIosPlay style={{marginLeft: '2px'}} />
      )}
    </span>
    <div className="activity_data_text">
      <p>{title}</p>
      <small>{time}</small>
    </div>
  </div>
);

const NoDataUi = ({TimesheetNoDataLinkPage}) => (
  <div className="Timesheet_no_data_div">
    <img src={NoActivityImg} alt="img" />
    <p className="no_data_title">No activity today</p>
    <p onClick={TimesheetNoDataLinkPage} className="no_data_link">
      View your timesheet
    </p>
  </div>
);

export const Timesheet = () => {
  const dispatch = useDispatch();
  //? redux
  const {recentActivities, currentActivity, status} =
    useSelector(getUserActivity);

  //? states
  const [currentActivityTime, setCurrentActivityTime] = useState(
    currentActivity?.isRunning
      ? moment(new Date()).diff(currentActivity?.updatedAt, 'seconds') +
          currentActivity?.duration
      : currentActivity?.duration,
  );
  const [isTimeSheetOpen, setIsTimeSheetOpen] = useState(false);
  const domNode = useClickOutside(() => setIsTimeSheetOpen(false));
  //? hooks
  useEffect(() => {
    dispatch(fetchUserActivities());
  }, []);
  useEffect(() => {
    if (isTimeSheetOpen) dispatch(fetchUserActivities());
  }, [dispatch, isTimeSheetOpen]);

  useEffect(() => {
    if (status === 'loading')
      Block.dots('#activitiesPopup', 'Fetching latest activities...');
    else Block.remove('#activitiesPopup');
  }, [status]);

  useEffect(() => {
    let timer;
    if (currentActivity?.isRunning)
      timer = setInterval(
        () => setCurrentActivityTime(prevTime => prevTime + 1),
        1000,
      );
    else clearInterval(timer);

    return () => {
      clearInterval(timer);
    };
  }, [currentActivity?.isRunning]);

  useEffect(() => {
    setCurrentActivityTime(
      currentActivity?.isRunning
        ? moment(new Date()).diff(currentActivity?.updatedAt, 'seconds') +
            currentActivity?.duration
        : currentActivity?.duration,
    );
  }, [currentActivity]);
  //? functions
  const handleStartBreak = () => {
    //initializing network request
    Axios.post('maxproject/user/break/start_pause', {
      status: 1,
    })
      .then(({data}) => {
        if (data?.status == -1) Report.info('Attention', data.message, 'Okay');
        else {
          Notify.success(data.message);
          // updating user activity
          dispatch(
            setUserActivity({
              currentActivity: {
                isRunning: true,
                duration: data?.UserBreak?.duration,
                id: data?.UserBreak?.id,
                name: 'Break',
                type: 'BREAK',
                updatedAt: new Date(),
              },
              recentActivities: [
                ...(currentActivity?.id
                  ? [
                      {
                        isRunning: false,
                        duration: currentActivityTime,
                        id: currentActivity?.id,
                        name: currentActivity?.name,
                        type: currentActivity?.type,
                        updatedAt: new Date(),
                      },
                    ]
                  : []),
                ...recentActivities.filter(
                  recentActivity => recentActivity?.type !== 'BREAK',
                ),
              ],
            }),
          );
        }
      })
      .catch(err => {
        console.log(err);
        Notify.failure(err?.response?.data?.message);
      });
  };
  const OpenhandleButtonClick = () => {
    setIsTimeSheetOpen(!isTimeSheetOpen);
  };
  const handleChangeCurrentActivityTime = () => {
    if (!currentActivity?.id) return;
    switch (currentActivity?.type) {
      case 'MEETING': {
        //initializing network request
        Axios.put(`maxproject/meeting/timer_status/${currentActivity?.id}`, {
          status: Number(!currentActivity?.isRunning),
        })
          .then(({data}) => {
            if (data?.status == -1)
              Report.info('Attention', data.message, 'Okay');
            else {
              Notify.success(
                `Meeting ${!currentActivity?.isRunning ? 'started' : 'paused'}`,
              );
              // updating user activity
              dispatch(
                setUserActivity({
                  currentActivity: {
                    isRunning: !currentActivity?.isRunning,
                    duration: data?.MeetingTimer?.duration,
                    id: currentActivity?.id,
                    name: currentActivity?.name,
                    type: 'MEETING',
                    updatedAt: new Date(),
                  },
                }),
              );
            }
          })
          .catch(err => {
            console.log(err);
            Notify.failure(err?.response?.data?.message);
          });
        break;
      }
      case 'TASK':
        //initializing network request
        Axios.put(
          `maxproject/task/internal_task/timer_status/${currentActivity?.id}`,
          {
            status: Number(!currentActivity?.isRunning),
          },
        )
          .then(({data}) => {
            if (data?.status == -1)
              Report.info('Attention', data.message, 'Okay');
            else {
              Notify.success(
                `Task ${!currentActivity?.isRunning ? 'started' : 'paused'}`,
              );
              // Calculate the sum of durations for TaskTimers of each member
              const totalDuration = data.UserTimers.reduce(
                (sum, timer) => sum + timer.duration,
                0,
              );
              // updating user activity
              dispatch(
                setUserActivity({
                  currentActivity: {
                    isRunning: !currentActivity?.isRunning,
                    duration: totalDuration,
                    id: currentActivity?.id,
                    name: currentActivity?.name,
                    type: 'TASK',
                    updatedAt: new Date(),
                  },
                }),
              );
            }
          })
          .catch(err => {
            console.log(err);
            Notify.failure(err?.response?.data?.message);
          });
        break;
      case 'BREAK':
        //initializing network request
        Axios.post('maxproject/user/break/start_pause', {
          status: Number(!currentActivity?.isRunning),
        })
          .then(({data}) => {
            if (data?.status == -1)
              Report.info('Attention', data.message, 'Okay');
            else {
              Notify.success(data.message);
              // updating user activity
              dispatch(
                setUserActivity({
                  currentActivity: {
                    isRunning: !currentActivity?.isRunning,
                    duration: data?.UserBreak?.duration,
                    id: data?.UserBreak?.id,
                    name: 'Break',
                    type: 'BREAK',
                    updatedAt: new Date(),
                  },
                }),
              );
            }
          })
          .catch(err => {
            console.log(err);
            Notify.failure(err?.response?.data?.message);
          });
        break;
      default:
        break;
    }
  };
  const handleStartRecentActivity = Activity => {
    switch (Activity?.type) {
      case 'MEETING': {
        //initializing network request
        Axios.put(`maxproject/meeting/timer_status/${Activity?.id}`, {
          status: Number(!Activity?.isRunning),
        })
          .then(({data}) => {
            if (data?.status == -1)
              Report.info('Attention', data.message, 'Okay');
            else {
              Notify.success(`Meeting started`);
              // updating user activity
              dispatch(
                setUserActivity({
                  currentActivity: {
                    isRunning: true,
                    duration: data?.MeetingTimer?.duration,
                    id: Activity?.id,
                    name: Activity?.name,
                    type: 'MEETING',
                    updatedAt: new Date(),
                  },
                  recentActivities: [
                    ...(currentActivity?.id
                      ? [
                          {
                            isRunning: false,
                            duration: currentActivityTime,
                            id: currentActivity?.id,
                            name: currentActivity?.name,
                            type: currentActivity?.type,
                            updatedAt: new Date(),
                          },
                        ]
                      : []),
                    ...recentActivities.filter(
                      Item =>
                        !(
                          Item?.type === 'MEETING' && Item?.id === Activity?.id
                        ),
                    ),
                  ],
                }),
              );
            }
          })
          .catch(err => {
            console.log(err);
            Notify.failure(err?.response?.data?.message);
          });
        break;
      }
      case 'TASK':
        //initializing network request
        Axios.put(
          `maxproject/task/internal_task/timer_status/${Activity?.id}`,
          {
            status: Number(!Activity?.isRunning),
          },
        )
          .then(({data}) => {
            if (data?.status == -1)
              Report.info('Attention', data.message, 'Okay');
            else {
              Notify.success(
                `Task ${!Activity?.isRunning ? 'started' : 'paused'}`,
              );
              // Calculate the sum of durations for TaskTimers of each member
              const totalDuration = data.UserTimers.reduce(
                (sum, timer) => sum + timer.duration,
                0,
              );
              // updating user activity
              dispatch(
                setUserActivity({
                  currentActivity: {
                    isRunning: true,
                    duration: totalDuration,
                    id: Activity?.id,
                    name: Activity?.name,
                    type: 'TASK',
                    updatedAt: new Date(),
                  },
                  recentActivities: [
                    ...(currentActivity?.id
                      ? [
                          {
                            isRunning: false,
                            duration: currentActivityTime,
                            id: currentActivity?.id,
                            name: currentActivity?.name,
                            type: currentActivity?.type,
                            updatedAt: new Date(),
                          },
                        ]
                      : []),
                    ...recentActivities.filter(
                      Item =>
                        !(Item?.type === 'TASK' && Item?.id === Activity?.id),
                    ),
                  ],
                }),
              );
            }
          })
          .catch(err => {
            console.log(err);
            Notify.failure(err?.response?.data?.message);
          });
        break;
      case 'BREAK':
        //initializing network request
        Axios.post('maxproject/user/break/start_pause', {
          status: Number(!Activity?.isRunning),
        })
          .then(({data}) => {
            if (data?.status == -1)
              Report.info('Attention', data.message, 'Okay');
            else {
              Notify.success(data.message);
              // updating user activity
              dispatch(
                setUserActivity({
                  currentActivity: {
                    isRunning: true,
                    duration: data?.UserBreak?.duration,
                    id: data?.UserBreak?.id,
                    name: 'Break',
                    type: 'BREAK',
                    updatedAt: new Date(),
                  },
                  recentActivities: [
                    ...(currentActivity?.id
                      ? [
                          {
                            isRunning: false,
                            duration: currentActivityTime,
                            id: currentActivity?.id,
                            name: currentActivity?.name,
                            type: currentActivity?.type,
                            updatedAt: new Date(),
                          },
                        ]
                      : []),
                    ...recentActivities.filter(
                      recentActivity => recentActivity?.type !== 'BREAK',
                    ),
                  ],
                }),
              );
            }
          })
          .catch(err => {
            console.log(err);
            Notify.failure(err?.response?.data?.message);
          });
        break;
      default:
        break;
    }
  };
  return (
    <>
      <div className="Timesheet_main_btn" ref={domNode}>
        <span
          style={{
            background: currentActivity?.isRunning ? '#1F883D' : '#9747FF',
          }}
          onClick={handleChangeCurrentActivityTime}>
          {currentActivity?.isRunning ? (
            <MdOutlinePause />
          ) : (
            <IoIosPlay style={{marginLeft: '2px'}} />
          )}
        </span>
        <p ref={domNode} onClick={OpenhandleButtonClick}>
          {currentActivity?.id ? formatDuration(currentActivityTime) : '00:00'}
        </p>
      </div>
      <div ref={domNode} className="avatar_container Timesheet_main_open_div">
        <div
          ref={domNode}
          id="activitiesPopup"
          className={isTimeSheetOpen ? 'menu_active menu' : 'menu'}>
          <div className=" position-relative">
            <div>
              <div className="row m-0 title_div">
                <div className="col-6 p-0 timesheet_title_inner_div">
                  <p>Timesheet</p>
                </div>
                <div className="col-6 p-0 timesheet_title_menu_div">
                  <TimesheetMenu handleStartBreak={handleStartBreak} />
                </div>
              </div>
              <hr />
              {currentActivity?.name ? (
                <div className="time_sheet_activity_div">
                  <p className="activity_title">
                    <span>
                      <TriangleRightIcon />
                    </span>
                    Current activity
                  </p>
                  <ActivityData
                    title={currentActivity?.name}
                    time={formatDuration(currentActivityTime)}
                    isRunning={currentActivity?.isRunning}
                    TimerhandleButtonClick={handleChangeCurrentActivityTime}
                  />
                </div>
              ) : null}
              <hr />
              <div className="time_sheet_activity_div">
                <p className="activity_title">
                  <span>
                    <TriangleRightIcon />
                  </span>
                  Last activity/ies
                </p>
                <div className="activity_data_scroll_div">
                  {recentActivities?.map(Activity => (
                    <ActivityData
                      title={Activity?.name}
                      time={formatDuration(Activity?.duration)}
                      isRunning={Activity?.isRunning}
                      TimerhandleButtonClick={() =>
                        handleStartRecentActivity(Activity)
                      }
                    />
                  ))}
                </div>
              </div>
              {/* data view */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
