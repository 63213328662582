import { ContractorManagementIndex } from "../components/Pages/Management/ContractorManagementIndex";
const ContractorManagement = () => {
  return (
    <>
      <div className="custom_container">
        <ContractorManagementIndex />
      </div>
    </>
  );
};
export default ContractorManagement;
