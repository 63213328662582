import './TimesheetProfileCard.scss';
import NoImagesFound from '../../../../pics/Management/noimagesfound.png';
import {DotFillIcon, InfoIcon} from '@primer/octicons-react';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import {styled} from '@mui/material/styles';
import {useSelector} from 'react-redux';
import {getUser} from '../../../../redux/slices/userSlice';
import {countWeekdays} from '../../../../utils/functions';
const BootstrapTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} arrow classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
export const TimesheetProfileCard = props => {
  const workingDays = countWeekdays(props?.dateRange[0], props?.dateRange[1]);
  return (
    <div className="timesheet_profile_card_div">
      <div className="row g-2">
        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 inner_card_div_1">
          <div>
            {props?.avatar ? (
              <img src={props?.avatar} alt={props?.name} />
            ) : (
              <img src={NoImagesFound} alt={props?.name} />
            )}
            <h3>{props?.name}</h3>
          </div>
          <span>{props?.designation}</span>
        </div>
        {props?.dateRange ? (
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 inner_card_div_2">
            <div>
              <p>Total Working Days</p>
              <h3>
                {workingDays} working day{workingDays > 1 ? 's' : ''}
              </h3>
            </div>
          </div>
        ) : null}
        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 inner_card_div_2">
          <div>
            <p>
              Total working time
              {/* <BootstrapTooltip
                title={
                  <>
                    {props?.TotalTimeTooltip.map((val, i) => {
                      return (
                        <div key={i} className="card_total_time_tooltip">
                          <p>{props?.date}</p>
                          <div className="row">
                            <div className="col-6 li_1">
                              <div>Total time</div>
                            </div>
                            <div className="col-6 li_2">
                              <div>
                                <span>{props?.TotalTime}</span>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-6 li_1">
                              <div>Activities</div>
                            </div>
                            <div className="col-6 li_2">
                              <div>
                                <span>{val.Activities}</span>
                              </div>
                            </div>
                          </div>
                          <div className="line_div">
                            <div className="row">
                              <div className="col-6 li_1">
                                <div>Tasks</div>
                              </div>
                              <div className="col-6 li_2">
                                <div>
                                  <span>{val.Tasks}</span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 li_1">
                                <div>Meetings</div>
                              </div>
                              <div className="col-6 li_2">
                                <div>
                                  <span>{val.Meetings}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6 li_1">
                              <div>Break</div>
                            </div>
                            <div className="col-6 li_2">
                              <div>
                                <span>{val.Break}</span>
                              </div>
                            </div>
                          </div>
                          <div className="line_div mb-0">
                            <div className="row">
                              <div className="col-6 li_1">
                                <div>Short break</div>
                              </div>
                              <div className="col-6 li_2">
                                <div>
                                  <span>{val.ShortBreak}</span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 li_1">
                                <div>Lunch break</div>
                              </div>
                              <div className="col-6 li_2">
                                <div>
                                  <span>{val.LunchBreak}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                }
                placement="top">
                <span>
                  <InfoIcon />
                </span>
              </BootstrapTooltip> */}
            </p>
            <h3>{props?.totalWorkingTime}</h3>
            <small>{props?.totalWorkingTimeSummary}</small>
          </div>
        </div>
        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 inner_card_div_2">
          <div>
            <p>
              Variance
              <BootstrapTooltip title={VarianceInfo} placement="top">
                <span>
                  <InfoIcon />
                </span>
              </BootstrapTooltip>
            </p>
            <h3
              style={{
                color: 'black',
              }}>
              {props?.varianceData?.variance
                ? props?.varianceData?.variance > 0
                  ? `Positive (${props?.varianceData?.variance}%)`
                  : `Negative (${props?.varianceData?.variance}%)`
                : 'N/A'}
            </h3>
            {props?.varianceData?.variance ? (
              <small>{props?.varianceData?.varianceSummary}</small>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
const VarianceInfo = (
  <div>
    <h4>Understanding Task Variance</h4>
    <p>
      <strong>Variance</strong> is the difference between the planned end date
      and the actual completion date of a task.
    </p>
    <ul>
      <li>
        <strong>
          <u>Positive Variance</u>
        </strong>
        : Indicates the task was completed later than planned (a delay).
      </li>
      <li>
        <strong>
          <u>Negative Variance</u>
        </strong>
        : Indicates the task was completed earlier than planned (ahead of
        schedule).
      </li>
      <li>
        <strong>
          <u>Zero Variance</u>
        </strong>
        : Indicates the task was completed on time.
      </li>
    </ul>
    <p>
      The <strong>overall variance percentage</strong> helps measure the
      performance across multiple tasks.
    </p>
  </div>
);
