import React, {useEffect, useState} from 'react';
import './ReminderIndex.scss';
import {useSelector, useDispatch} from 'react-redux';
import {Drawer} from '@mui/material';
import {MdAlarm} from 'react-icons/md';
import {NewBootstrapTooltip} from '../../../../common/NewComponents/NewTooltip/NewTooltip';
import {
  closeReminderDrawer,
  openReminderDrawer,
} from '../../../../../redux/slices/ReminderSlice';
import {DefaultData} from './DefaultDataView/DefaultData';
import {AddReminder} from './AddReminder/AddReminder';

export const ReminderIndex = () => {
  const dispatch = useDispatch();
  const [view, setView] = useState('default');
  const [reminderId, setReminderId] = useState(null);
  const isReminderDrawerOpen = useSelector(
    state => state.reminder.isReminderDrawerOpen,
  );
  const isDarkMode = useSelector(state => state.global.isMode);

  const toggleDrawer = isOpen => () =>
    dispatch(isOpen ? openReminderDrawer() : closeReminderDrawer());

  useEffect(() => {
    if (view === 'default') setReminderId(null);
  }, [view]);

  return (
    <div>
      <NewBootstrapTooltip
        ChildComponent={
          <p className="top_nav_left_icon" onClick={toggleDrawer(true)}>
            <MdAlarm className="new_icon top_nav_left_icon" />
          </p>
        }
        title="Reminder"
        placement="bottom"
      />
      <Drawer
        anchor="right"
        open={isReminderDrawerOpen}
        onClose={toggleDrawer(false)}
        className="quick_note_one_main_div_top"
        data-theme={isDarkMode}>
        <div className="Riminder_one_main_new_div">
          {view === 'addReminder' ? (
            <AddReminder setView={setView} reminderId={reminderId} />
          ) : (
            <DefaultData
              setView={setView}
              toggleDrawer={toggleDrawer}
              setReminderId={setReminderId}
            />
          )}
        </div>
      </Drawer>
    </div>
  );
};
