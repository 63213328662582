import {NavLink, useNavigate} from 'react-router-dom';
import {LoginInput} from '../common/LoginComponents';
import LoginImg from '../../pics/loginImg.svg';
import React, {useEffect} from 'react';
import Slider from 'react-slick';
import {Notify} from 'notiflix/build/notiflix-notify-aio';
import {Block} from 'notiflix/build/notiflix-block-aio';
import Axios from './../../config/api';
import {useForm} from 'react-hook-form';
import {MemoryClient} from './../../utils';
import {useSelector, useDispatch} from 'react-redux';
import {registerServiceWorker} from './../../helpers/registerSW';

import {setUser} from './../../redux/slices/userSlice';
import {setGlobal} from './../../redux/slices/globalSlice';
import {getGlobal} from './../../redux/slices/globalSlice';

export const Login = () => {
  const dispatch = useDispatch();
  const {isLoggedIn} = useSelector(getGlobal);

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm();

  const Navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) Navigate('/Management/dashboard');
    let user = MemoryClient.get('user');
    if (user) Navigate('/Management/dashboard');
    return () => {};
  }, []);

  const handleLoginSubmit = data => {
    Block.circle('.form_div', 'Logging in');

    Axios.post('common/auth/login', data)
      .then(async res => {
        let {data} = res.data;
        Block.remove('.form_div');
        MemoryClient.set('lp', data.access_token);
        MemoryClient.set('rlp', data.refresh_token);
        MemoryClient.set('user', JSON.stringify(data.user));

        dispatch(setUser(data.user));
        dispatch(setGlobal({isLoading: false, isLoggedIn: true}));

        registerServiceWorker();

        Navigate('/Management/dashboard');
      })
      .catch(err => {
        Block.remove('.form_div');
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  return (
    <>
      <div className="login_top_div">
        <div className="login_top_div_inner">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-0">
              <div className="login_slider_col_div">
                <div>
                  <SliderBottom />
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-0 form_div">
              <div className="login_input_col_div">
                <div className="login_input_div_top">
                  <div className="login_input_div">
                    <h3>Maxlence</h3>
                    <h4>Hello!</h4>
                    <p>
                      Manage projects efficiently by collaborating, customizing
                      workflows and getting personalized insights. Login to get
                      started!
                    </p>
                  </div>
                </div>
                <div className="login_form_div_top">
                  <form
                    className="login_form_div"
                    onSubmit={handleSubmit(handleLoginSubmit)}>
                    <div className="row g-3">
                      <div className="col-12">
                        <LoginInput
                          register={() =>
                            register('email', {
                              required: 'Please enter your email',
                            })
                          }
                          label="Login ID/Email"
                          type="email"
                          EyeIcon={false}
                          className="form-control"
                          placeholder="Login ID/Email"
                          errorMessage={errors.email?.message}
                          defaultValue="test"
                        />
                      </div>
                      <div className="col-12">
                        <LoginInput
                          register={() =>
                            register('password', {
                              required: 'Please enter your password',
                              minLength: {
                                value: 5,
                                message: 'Minimum length should be 5',
                              },
                            })
                          }
                          label="Password"
                          type="password"
                          EyeIcon={true}
                          className="form-control login_password_outer"
                          placeholder="Enter your password"
                          errorMessage={errors.pass?.message}
                        />
                        <div className="login_forgot_btn">
                          <NavLink to="/forgot_password">
                            Forgot Password?
                          </NavLink>
                        </div>
                      </div>
                      <div className="col-12">
                        <input
                          value="login"
                          type="submit"
                          className="btn login_btn"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <SliderBottom /> */}
    </>
  );
};
const SliderBottom = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  const SliderData = [
    {
      img: LoginImg,
      title: 'Simplify project management with our tool',
      subTitle:
        'Manage projects efficiently by collaborating, customizing workflows and getting personalized insights. Login to get started!',
    },
    {
      img: LoginImg,
      title: 'Simplify project management with our tool',
      subTitle:
        'Manage projects efficiently by collaborating, customizing workflows and getting personalized insights. Login to get started!',
    },
    {
      img: LoginImg,
      title: 'Simplify project management with our tool',
      subTitle:
        'Manage projects efficiently by collaborating, customizing workflows and getting personalized insights. Login to get started!',
    },
  ];
  const SliderApp = props => {
    return (
      <>
        <div>
          <div className="slider_text_my_top">
            <img className="login_img" src={props.img} alt="login photo" />
            <div className="slider_text_my_inner">
              <div className="slider_text_my">
                <h3>{props.title}</h3>
                <p>{props.subTitle}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <Slider {...settings}>
        {SliderData.map((val, i) => {
          return <SliderApp key={i} {...val} />;
        })}
      </Slider>
    </>
  );
};
