import {useSelector} from 'react-redux';
import './MyTimesheet.scss';
import {useNavigate} from 'react-router-dom';
// list
import {FilterIcon, StopwatchIcon, XIcon} from '@primer/octicons-react';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import {styled} from '@mui/material/styles';
// list
import {MyDatePicker} from '../../../../common/NewComponents/MyDatePicker/MyDatePicker';
import {SearchBox} from '../../../../common/NewComponents/SearchBox/SearchBox';
import React from 'react';
import Switch from '@mui/material/Switch';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import {NewOutlinedButtonWithIcon} from '../../../../common/NewComponents/NewButtons/Buttons';
import {NewSelect} from '../../../../common/NewComponents/NewSelect/NewSelect';
import {Pagination} from '@mui/material';
import {useState} from 'react';
import {useNonInitialEffect} from '../../../../../utils/hooks/non-initial-effect.tsx';
import {Block, Confirm, Notify} from 'notiflix';
import moment from 'moment';
import {ProjectOverviewCard} from '../../../../common/NewComponents/Timesheet/ProjectOverviewCard.jsx';
import {useEffect} from 'react';
import Axios from './../../../../../config/api';
import {getQueryParam, setQueryParam} from '../../../../../utils/functions.js';

export const AllTimesheet = () => {
  const storData = useSelector(state => state);
  const navigate = useNavigate();
  const {global} = useSelector(state => state);

  const [query, setQuery] = useState('');
  const [page, setPage] = useState(getQueryParam('page') || 1);
  const [overallProjectTime, setOverallProjectTime] = useState('Nil');
  const [filters, setFilters] = useState({projects: [], users: []});
  const [selectedFilters, setSelectedFilters] = useState({
    project: null,
    users: [],
  });
  const [dateRange, setDateRange] = useState(
    global?.timesheetDateRange || [
      moment().subtract(7, 'days').toDate(),
      moment().toDate(),
    ],
  );
  const [data, setData] = useState([]);

  useNonInitialEffect(() => {
    // Timer ID for the delayed fetch
    let timerId;
    // Clear previous timer, if any
    clearTimeout(timerId);
    // Set a new timer for the delayed fetch
    timerId = setTimeout(fetchTeamTimesheet, 500);
    // Clean up timer on component unmount
    return () => clearTimeout(timerId);
  }, [query]);

  //hooks
  useEffect(async () => {
    fetchTeamTimesheet();
    return () => {};
  }, []);

  useEffect(async () => {
    if (dateRange[1]) fetchTeamTimesheet();

    return () => {};
  }, [dateRange]);

  useEffect(() => {
    setQueryParam('page', page);
  }, [page]);

  useEffect(() => {
    fetchProjectMembers();
  }, [selectedFilters.project]);

  const onApplyFilters = () => {
    fetchTeamTimesheet(true);
  };

  const fetchProjectMembers = () => {
    const projectId = selectedFilters?.project?.value;
    if (!projectId) return false;
    Axios.fetch(`maxproject/timesheet/project_members/` + projectId)
      .then(({data}) => {
        setFilters(old => ({...old, users: data?.Users}));
      })
      .catch(err => {});
  };

  const fetchTeamTimesheet = (applyFilters = false) => {
    const projectId = selectedFilters?.project?.value || 0;

    Block.circle('.loading', 'Loading');
    const q = new URLSearchParams({
      projectId,
      query,
      start_date: moment(dateRange[0]).format('YYYY-MM-DD'),
      end_date: moment(dateRange[1]).format('YYYY-MM-DD'),
      projects: JSON.stringify(
        selectedFilters.project?.value ? [selectedFilters.project?.value] : [],
      ),
      users: JSON.stringify(selectedFilters.users.map(m => m.value)),
    });
    Axios.fetch(`maxproject/timesheet/team_timesheet?${q.toString()}`)
      .then(({data}) => {
        Block.remove('.loading');
        setData(data?.UsersData);
        //updating filters data
        setFilters(old => ({
          ...old,
          projects: data?.filters?.projects,
        }));
        //updating project overall time
        setOverallProjectTime(data?.OverallTime);
      })
      .catch(err => {
        if (err.response.status == 500) navigate('/Management/my_timesheet');
        Block.remove('.loading');
      });
  };

  const handleSearch = e => {
    setQuery(e.target.value);
  };

  return (
    <>
      <div
        className="my_timesheet_main_div loading"
        data-theme={storData.global.isMode}>
        <div className="my_timesheet_nav_div">
          <button
            onClick={() => navigate('/Management/my_timesheet')}
            style={{marginLeft: '1rem'}}>
            My Timesheet
          </button>
          <button
            className="active"
            onClick={() => navigate('/Management/all_timesheet')}
            style={{marginLeft: '1rem'}}>
            all Timesheet <div></div>
          </button>
        </div>

        <div className="my_timesheet_inner_div">
          <div className="row g-3">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <MyDatePicker dateRange={dateRange} onChange={setDateRange} />
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 search_box_page_div">
              <SearchBox
                value={query}
                onChange={handleSearch}
                placeholder="Search user"
              />
              <NewFilters
                filters={filters}
                onApplyFilters={onApplyFilters}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
              />
            </div>
            {selectedFilters.project ? (
              <div className="col-12">
                <ProjectOverviewCard
                  projectId={selectedFilters.project?.value}
                  startDate={dateRange[0]}
                  endDate={dateRange[1]}
                  setOverallProjectTime={setOverallProjectTime}
                  overallProjectTime={overallProjectTime}
                />
              </div>
            ) : null}
            <div className="col-12">
              <TimesheetList
                page={page}
                setPage={setPage}
                data={data}
                date_range={dateRange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const BootstrapTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} arrow classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
const TimesheetList = ({
  data = [],
  date_range = [],
  page,
  setPage = () => {},
}) => {
  //? hooks
  const navigate = useNavigate();
  //? Variables
  // Calculate the total number of days between the start and end dates
  const daysDiff = moment(date_range[1]).diff(date_range[0], 'days');
  // Generate the array of dates
  const Dates = Array.from({length: daysDiff + 1}, (_, i) => {
    return moment(date_range[0]).clone().add(i, 'days').toISOString();
  });

  //? Functions
  const handleOpenUserTimesheet = (userId, user) => {
    navigate('/Management/user_timesheet_detail/' + userId, {
      state: {
        user,
        designation: user?.Designation?.name,
      },
    });
  };
  const handleTaskPagination = (e, page) => {
    setPage(page);
  };
  //? Components
  const TableThDate = ({dateString = null}) => {
    if (!dateString)
      return (
        <th scope="col" className="table_th_inner_main_div">
          <div className={`table_th_inner_div`}>
            <span>-</span>
          </div>
        </th>
      );
    const date = moment(dateString);
    const isActive = date.isSame(moment(), 'day');
    return (
      <th scope="col" className="table_th_inner_main_div">
        <div className={`table_th_inner_div ${isActive ? 'active_class' : ''}`}>
          <span>{date.format('ddd, MMM D')}</span>
          <div
            className="line"
            style={{display: isActive ? 'block' : 'none'}}></div>
        </div>
      </th>
    );
  };
  const UserCell = ({User, isTimerActive, idx, runningActivityName}) => {
    const [timerStatus, setTimerStatus] = useState(isTimerActive);
    const pauseUserTimers = () => {
      Confirm.prompt(
        'Pause user timers?',
        'Please enter a valid reason',
        '',
        'Answer',
        'Cancel',
        handleProcessPauseTimers,
        {},
      );
    };

    const handleProcessPauseTimers = reason => {
      if (!reason) return false;
      Axios.put(`maxproject/timesheet/pause_user_timers/` + User.id, {reason})
        .then(({data}) => {
          setTimerStatus(false);
          Notify.success('User timers has been paused');
        })
        .catch(err => {
          console.log(err);
        });
    };

    return (
      <div
        style={{cursor: 'pointer'}}
        className="timesheet_list_project_name_div top_border">
        <span className="sr_no">{idx + 1 + (page - 1) * 10}</span>
        <img src={User?.avatar} alt="icon" />
        <h3
          onClick={() => handleOpenUserTimesheet(User.id, User)}
          className="page_link">
          {User?.name.length > 15
            ? `${User?.name.substring(0, 15)}...`
            : User?.name}
        </h3>
        {timerStatus ? (
          <div className="mx-3">
            <BootstrapTooltip placement="top" title={runningActivityName}>
              <span
                className="time_icon"
                style={{
                  display: 'flex',
                }}>
                <div style={{marginRight: 10}}>
                  <AntSwitch
                    inputProps={{'aria-label': 'ant design'}}
                    checked={true}
                    onChange={pauseUserTimers}
                  />
                </div>
                <StopwatchIcon />
              </span>
            </BootstrapTooltip>
          </div>
        ) : null}
      </div>
    );
  };

  const TimerRow = ({Timers}) => {
    const TimerTd = ({idx, day}) => {
      //getting current column date and formatting
      const currentDate = moment(day).format('YYYY-MM-DD');

      return (
        <td key={idx}>
          <div className="text_box">{Timers[currentDate]?.duration || '-'}</div>
        </td>
      );
    };
    return (
      <tr className="table_tr_inner_div">
        {Dates?.map((day, idx) => (
          <TimerTd day={day} idx={idx} />
        ))}
      </tr>
    );
  };
  const RowTotalDurationCell = UserData => {
    return (
      <div className="end_text_box_div top_border">
        <span className="end_text_box">
          {UserData?.totalWorkingTime || '-'}
        </span>
      </div>
    );
  };
  return (
    <>
      <div className="timesheet_list_top_main_div timesheet_list_top_inner_main_div">
        <div className="timesheet_list_top_div">
          {/* ---------------------------------------------------------------------------------------------------- */}
          <div className="row m-0">
            <div className="col-3 p-0">
              {/* ProjectName */}
              <div className="timesheet_list_top_title_div">
                <span>S.No</span>
                <p>People ({data?.length})</p>
              </div>
              {data?.slice((page - 1) * 10, 10 * page)?.map((UserData, idx) => (
                <UserCell
                  idx={idx}
                  User={UserData?.User}
                  isTimerActive={UserData?.isTimerActive}
                  runningActivityName={UserData?.runningActivityName}
                />
              ))}

              {/* Task title */}
            </div>
            <div className="col-7 p-0">
              <div className="timesheet_list_table_div">
                <table class="table">
                  <thead>
                    <tr>
                      {Dates?.map(Date => (
                        <TableThDate key={1} dateString={Date} />
                      ))}
                      {Dates.length >= 7
                        ? []
                        : Array(7 - Dates.length)
                            .fill(0)
                            ?.map(Date => <TableThDate />)}
                    </tr>
                  </thead>
                  <tbody>
                    {data
                      ?.slice((page - 1) * 10, 10 * page)
                      ?.map((UserData, i) => {
                        return <TimerRow idx={i} Timers={UserData?.timers} />;
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-2 p-0">
              <div className="timesheet_list_top_Total_title_div">
                <span>Total</span>
              </div>
              {data?.slice((page - 1) * 10, 10 * page)?.map((UserData, i) => {
                return <RowTotalDurationCell key={i} {...UserData} />;
              })}
            </div>
          </div>
          {data?.length > 10 ? (
            <div className="page_pagination_list_div">
              <div className="page_pagination_main_number_div">
                <Pagination
                  onChange={handleTaskPagination}
                  count={Math.ceil(data?.length / 10)}
                  defaultPage={Number(page)}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
const NewFilters = ({
  filters = {projects: [], users: []},
  selectedFilters = {project: null, users: []},
  setSelectedFilters = () => {},
  onApplyFilters = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = open => {
    setIsOpen(open);
  };
  const storData = useSelector(state => state);
  return (
    <React.Fragment>
      <NewOutlinedButtonWithIcon
        icon={<FilterIcon />}
        onClick={() => toggleDrawer(true)}
        title="Filter"
      />
      <Drawer
        anchor={'right'}
        open={isOpen}
        onClose={() => toggleDrawer(false)}
        className="new_filters_one_main_div_top">
        <div
          className="new_filters_one_main_div"
          data-theme={storData.global.isMode}>
          <div className="top_title_and_close_btn">
            <h3>Filter</h3>
            <span onClick={() => toggleDrawer(false)}>
              <XIcon />
            </span>
          </div>
          <div className="inner_div">
            <div className="row g-2">
              <div className="col-12 ">
                <NewSelect
                  label="Projects"
                  isMulti={false}
                  data={filters.projects}
                  placeholder="Select projects"
                  selected={selectedFilters.project}
                  setSelected={project =>
                    setSelectedFilters(old => ({...old, project}))
                  }
                  // ErrorMessage="Please select employee name"
                />
              </div>
              <div className="col-12">
                <NewSelect
                  label="Users"
                  disabled={!selectedFilters.project}
                  isMulti={true}
                  data={filters.users}
                  placeholder="Select users"
                  selected={selectedFilters.users}
                  setSelected={users =>
                    setSelectedFilters(old => ({...old, users}))
                  }
                  // ErrorMessage="Please select project"
                />
              </div>
              <div className="col-12 new_filters_bottom_btn">
                <Button
                  onClick={() => {
                    onApplyFilters();
                    toggleDrawer(false);
                  }}
                  variant="contained"
                  className="btn_1">
                  Apply
                </Button>
                <Button
                  onClick={() => toggleDrawer(false)}
                  variant="outlined"
                  className="btn_2">
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

const AntSwitch = styled(Switch)(({theme}) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));
