// drawerNotificationSlice.js
import {createSlice} from '@reduxjs/toolkit';

const drawerNotificationSlice = createSlice({
  name: 'drawerNotification',
  initialState: {
    isOpen: false,
  },
  reducers: {
    openNotificationDrawer: state => {
      state.isOpen = true;
    },
    closeNotificationDrawer: state => {
      state.isOpen = false;
    },
    toggleNotificationDrawer: state => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const {
  openNotificationDrawer,
  closeNotificationDrawer,
  toggleNotificationDrawer,
} = drawerNotificationSlice.actions;

export default drawerNotificationSlice.reducer;
