import * as React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';

export const Reports = () => {
  const [startDate, setStartDate] = React.useState(moment());
  const [endDate, setEndDate] = React.useState(moment().add(1, 'hour')); // default is 1 hour ahead

  const handleStartDateChange = newDate => {
    if (newDate.isBefore(endDate)) {
      setStartDate(newDate);
    } else {
      alert("Start date/time must be before end date/time");
    }
  };

  const handleEndDateChange = newDate => {
    if (newDate.isAfter(startDate)) {
      setEndDate(newDate);
    } else {
      alert("End date/time must be after start date/time");
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Stack spacing={3}>
        <DateTimePicker
          label="Start Date & Time"
          value={startDate}
          onChange={handleStartDateChange}
          renderInput={params => <TextField {...params} />}
        />
        <DateTimePicker
          label="End Date & Time"
          value={endDate}
          onChange={handleEndDateChange}
          renderInput={params => <TextField {...params} />}
          minDateTime={startDate} // setting the minimum date and time for the end date/time picker
        />
      </Stack>
    </LocalizationProvider>
  );
};
