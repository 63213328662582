import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useState } from "react";

export const LoginInput = ({
  label,
  EyeIcon,
  register,
  type,
  placeholder,
  errorMessage,
  className,
}) => {
  const [showPass, setShowPass] = useState(false);
  return (
    <>
      <div className="login_input_com">
        <label>{label}</label>
        <div className="login_input_com_icon">
          <span onClick={() => setShowPass(!showPass)}>
            {EyeIcon &&
              (showPass ? (
                <AiFillEye className=" text-black" />
              ) : (
                <AiFillEyeInvisible />
              ))}
          </span>
          <input
            {...register()}
            type={EyeIcon ? (showPass ? "text" : type) : type}
            className={className}
            placeholder={placeholder}
          />
          <small className="error_message">{errorMessage}</small>
        </div>
      </div>
    </>
  );
};
