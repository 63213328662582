import {useSelector, useDispatch} from 'react-redux';
import useClickOutside from '../../CustomHooks/ClickOutside';
import {Avatar, Button} from '@mui/material';
import {NavLink, useNavigate} from 'react-router-dom';
import {GearIcon, LinkExternalIcon, PersonIcon} from '@primer/octicons-react';
import {
  closeProfileDropdown,
  toggleProfileDropdown,
} from '../../../../redux/slices/ProfileDropdownConfigSlice';
import {useState} from 'react';
import Axios from './../../../../config/api';
import {Confirm, Notify} from 'notiflix';
import {setGlobal} from '../../../../redux/slices/globalSlice';
import {clearUser} from '../../../../redux/slices/userSlice';
import moment from 'moment';
import {MemoryClient} from '../../../../utils';
import {useEffect} from 'react';
import {
  clearUserActivity,
  setUserActivity,
} from '../../../../redux/slices/userActivitySlice';

export const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const User = useSelector(state => state?.user);
  const globalState = useSelector(state => state?.global);

  // Using Redux state for profile dropdown open/close status
  const isProfileOpen = useSelector(
    store => store.profileDropdown.isProfileOpen,
  );

  const [activeButton, setActiveButton] = useState(null);
  const [checkedInAt, setCheckedInAt] = useState(null);
  const [checkedOutAt, setCheckedOutAt] = useState(null);
  // profile open
  const [isTimeSheetOpen, setIsTimeSheetOpen] = useState(false);
  const domNode = useClickOutside(() => setIsTimeSheetOpen(false));
  const OpenhandleButtonClick = () => {
    setIsTimeSheetOpen(!isTimeSheetOpen);
  };
  // profile open end
  useEffect(() => {
    fetchUserDropdown();
    return () => {};
  }, []);

  // Avatar generation
  const stringToColor = string => {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    return `#${[0, 1, 2]
      .map(i => `00${((hash >> (i * 8)) & 0xff).toString(16)}`.slice(-2))
      .join('')}`;
  };

  const stringAvatar = name => ({
    sx: {bgcolor: stringToColor(name)},
    children: `${name?.split(' ')?.[0]?.[0]}${name?.split(' ')?.[1]?.[0]}`,
  });

  const handleCheckIn = () => {
    //initializing network request
    Axios.put('maxproject/user/online_status', {status: true}).then(
      ({data}) => {
        Notify.success(data?.message);
        setCheckedInAt(data?.checkedInAt);
        dispatch(toggleProfileDropdown());
      },
    );
  };

  const handleCheckOut = () => {
    //initializing network request
    Axios.put('maxproject/user/online_status', {status: false}).then(
      ({data}) => {
        Notify.success(data?.message);
        setCheckedOutAt(data?.checkedOutAt);
        dispatch(toggleProfileDropdown());
      },
    );
  };

  const handleSignOut = () => {
    Confirm.show(
      'Logout',
      'Do you really want to logout?',
      'Yes',
      'No',
      () => {
        MemoryClient.clearAll();
        dispatch(clearUser());
        dispatch(setGlobal({isLoggedIn: false}));
        navigate('/');
        dispatch(clearUserActivity());
      },
      () => {},
      {
        borderRadius: '5px',
        okButtonBackground: '#0f62fe',
        okButtonColor: 'white',
      },
    );
  };

  const fetchUserDropdown = () => {
    Axios.fetch('maxproject/user/navbar').then(({data}) => {
      if (data?.Checkin?.createdAt) setCheckedInAt(data?.Checkin?.createdAt);
      if (data?.Checkin?.checkedOutAt)
        setCheckedOutAt(data?.Checkin?.checkedOutAt);
    });
  };

  if (!globalState?.isLoggedIn) return null;

  return (
    <>
      <div
        style={{borderColor: '#9747FF'}}
        className="top_nav_propfile_top_icon_div">
        {User?.avatar ? (
          <img
            className="user_img"
            src={User?.avatar}
            alt="img"
            ref={domNode}
            onClick={OpenhandleButtonClick}
          />
        ) : (
          <Avatar
            {...stringAvatar(User?.name)}
            ref={domNode}
            onClick={OpenhandleButtonClick}
          />
        )}
      </div>
      <div className="avatar_container_profile">
        <div
          ref={domNode}
          className={isTimeSheetOpen ? 'menu_active menu' : 'menu'}
          data-theme={globalState.isMode}>
          <div className="top_nav_top_inner_section">
            <div className="top_nav_top_section">
              <div className="row m-0">
                <div className="col-6 p-0 sign_out_main_title_div">
                  <div>Maxlence Digital</div>
                </div>
                <div className="col-6 p-0 sign_out_btn_div">
                  <Button onClick={handleSignOut} variant="text">
                    sign out
                  </Button>
                </div>
              </div>
              <ul className="user_inner_icon_div">
                <li className="user_li">
                  {User?.avatar ? (
                    <img className="user_img" src={User?.avatar} alt="img" />
                  ) : (
                    <Avatar {...stringAvatar('sunny charkhwal')} />
                  )}
                </li>
                <li>
                  <h3>{User?.name}</h3>
                  <p>{User?.email}</p>
                </li>
              </ul>
            </div>
            <div className="top_nav_top_subscription">
              <h3>Upgrade</h3>
              <p>Your portal is currently in Free plan</p>
              <NavLink to="/">Subscription Details</NavLink>
            </div>
            <div className="top_nav_top_attendence">
              <h3>Attendence</h3>
              {checkedInAt && (
                <p>Checked in at {moment(checkedInAt).format('h:mm a')}</p>
              )}
              {checkedOutAt && (
                <p>Checked out at {moment(checkedOutAt).format('h:mm a')}</p>
              )}
              <div className="btn_inner_div">
                <Button
                  disabled={checkedInAt}
                  style={{
                    cursor: checkedInAt ? 'not-allowed' : 'pointer',
                  }}
                  variant="text"
                  onClick={handleCheckIn}
                  className={activeButton === 'button1' ? 'active' : ''}>
                  Check in
                </Button>
                <Button
                  variant="text"
                  onClick={handleCheckOut}
                  className={activeButton === 'button2' ? 'active' : ''}>
                  Check out
                </Button>
              </div>
              <NavLink to="#">
                <LinkExternalIcon />
              </NavLink>
            </div>
            <div className="top_nav_top_account">
              <h3>Account</h3>
              <div className="link_div">
                <NavLink to="/Management/settings">
                  <span>
                    <PersonIcon />
                  </span>
                  Profile
                </NavLink>
              </div>
              <div className="link_div">
                <NavLink to="/Management/settings">
                  <span>
                    <GearIcon />
                  </span>
                  Settings
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};