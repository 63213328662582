import Select from 'react-select';
import {
  OutlineBtn,
  FullBtn,
} from '../../../../../common/ManagementComponents/Btn/Btn';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Accordion from 'react-bootstrap/Accordion';
import {IoCloseSharp} from 'react-icons/io5';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
//
import {OutlineBtnWithIcon} from '../../../../../common/ManagementComponents/Btn/Btn';
import {FaFilter} from 'react-icons/fa';
import {IoSearchOutline} from 'react-icons/io5';
import {NavLink} from 'react-router-dom';
import {BiDotsHorizontalRounded} from 'react-icons/bi';
import {PageAllTopTitle} from '../../../../../common/ManagementComponents/PageAllTopTitle';
// mui
import * as React from 'react';
import {useState, useEffect} from 'react';
import {Block} from 'notiflix/build/notiflix-block-aio';
import Axios from './../../../../../../config/api';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useNavigate} from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {ExistingClientModal} from '../ExistingClientModal';
import {useNonInitialEffect} from '../../../../../../utils/hooks/non-initial-effect.tsx';
import moment from 'moment';
import Img from '../../../../../common/components/img/Img';
import NoResultsSVG from '../../../../../../pics/empty_state_search_not_found.svg';
//
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import {BsFillCircleFill} from 'react-icons/bs';
import {AiFillCaretDown} from 'react-icons/ai';
//
export const ProjectList = () => {
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(null);
  const [projectStatuses, setProjectStatuses] = useState([]);
  const [projectStatusFilters, setProjectStatusFilters] = useState([]);
  const [projectPriorityFilters, setProjectPriorityFilters] = useState([]);
  const [query, setQuery] = useState('');
  const [permissions, setPermissions] = useState({hasSuperPermission: false});
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetchFilters();
    fetchStatuses();
    getClients();
    return () => {};
  }, []);

  useNonInitialEffect(() => {
    fetchProjects();
    return () => {};
  }, [selectedStatusFilter]);

  useNonInitialEffect(() => {
    // Timer ID for the delayed fetch
    let timerId;
    // Clear previous timer, if any
    clearTimeout(timerId);
    // Set a new timer for the delayed fetch
    timerId = setTimeout(fetchProjects, 500);
    // Clean up timer on component unmount
    return () => clearTimeout(timerId);
  }, [query]);

  const getClients = () => {
    Axios.fetch('maxproject/client/select_list').then(({data}) => {
      let clientsList = data.clients.map(client => ({
        value: client.id,
        label: client.client_name,
      }));
      setClients(clientsList);
    });
  };
  const processSelectedClient = client => {
    if (client) navigate('/Management/add_project/' + client);
    else navigate('/Management/add_project');
  };
  const handleSearch = e => {
    setQuery(e.target.value);
  };
  const fetchFilters = () => {
    Block.circle('.projects_table');
    Axios.fetch(
      `maxproject/random/status_filters/project?includePriorityFilters=1`,
    )
      .then(({data}) => {
        Block.remove('.projects_table');
        let filters = data?.filters?.map(filter => ({
          value: filter.id,
          label: <UserChangeLabel title={filter.name} />,
        }));
        setProjectStatuses(filters);
        setSelectedStatusFilter(0);
      })
      .catch(err => {
        Block.remove('.projects_table');
      });
  };
  const fetchStatuses = () => {
    Axios.fetch(`maxproject/random/statuses/project`)
      .then(({data}) => {
        setProjectPriorityFilters(data?.priority_statuses);
        setProjectStatusFilters(data?.statuses);
      })
      .catch(err => {
        Block.remove('.projects_table');
      });
  };
  const fetchProjects = () => {
    Block.circle('.projects_table');
    Axios.fetch(
      `maxproject/project?status_id=${
        selectedStatusFilter.value || 0
      }&query=${query}`,
    )
      .then(({data}) => {
        Block.remove('.projects_table');
        setProjects(data.projects);
        setPermissions({hasSuperPermission: data?.hasSuperPermission});
      })
      .catch(err => {
        Block.remove('.projects_table');
      });
  };
  const ProjectsTable = () => {
    const [page, setPage] = useState(1);

    const handleTaskPagination = (e, page) => {
      setPage(page);
    };
    const Project = props => {
      const [priority, setPriority] = useState(props?.Priority);
      const [status, setStatus] = useState(props?.Status);
      const ProjectListDropDown = () => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const handleClick = event => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };
        const navigate = useNavigate();
        const options = [
          {
            link: '/Management/project_details/' + props.id,
            name: 'View Project Details',
          },
          {
            link: '/Management/assign_project/' + props.id,
            name: 'Assign Project',
            disabled: !(permissions.hasSuperPermission || props.isTeamLeader),
          },
          {
            link: `/Management/add_project/${props?.client_id}/${props.id}`,
            name: 'Edit Project',
            disabled: !permissions.hasSuperPermission,
          },
          {link: '/Management/create_task', name: 'Create Task'},
          {
            // link: '/Management/meeting_notes',
            name: 'View Notes',
            disabled: true,
          },
        ];

        const ITEM_HEIGHT = 30;
        return (
          <>
            <div className="chat_option">
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                className=" m-0 p-0">
                <BiDotsHorizontalRounded />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '15ch',
                  },
                }}>
                {options.map(option => (
                  <div
                    key={option.name}
                    onClick={() => !option?.disabled && navigate(option.link)}>
                    <MenuItem
                      disabled={option?.disabled}
                      selected={option === 'Pyxis'}
                      onClick={handleClose}>
                      <div className="chat_option_name_div">{option.name}</div>
                    </MenuItem>
                  </div>
                ))}
              </Menu>
            </div>
          </>
        );
      };
      const PriorityDropdown = () => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const handleClick = event => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };
        const handlePriorityChange = priority => {
          Block.circle('.projects_table');
          Axios.put(`maxproject/project/${props.id}/priority`, {
            priority_id: priority.id,
          })
            .then(({data}) => {
              Block.remove('.projects_table');
              setPriority(priority);
              setAnchorEl(null);
            })
            .catch(err => {
              Block.remove('.projects_table');
            });
        };
        return (
          <React.Fragment>
            <Box>
              <Tooltip arrow>
                <p
                  onClick={handleClick}
                  className="Priority_dropdown"
                  style={{backgroundColor: priority?.bg_color}}>
                  <span>
                    {priority?.name}
                    <AiFillCaretDown />
                  </span>
                </p>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{horizontal: 'right', vertical: 'top'}}
              anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
              <Divider />
              {projectPriorityFilters?.map(p => (
                <MenuItem onClick={() => handlePriorityChange(p)}>
                  <p className="Priority_dropdown_item">
                    <BsFillCircleFill style={{color: p.bg_color}} />
                    {p.name}
                  </p>
                </MenuItem>
              ))}
            </Menu>
          </React.Fragment>
        );
      };
      const StatusDropdown = () => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const handleClick = event => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };
        const handleStatusChange = status => {
          Block.circle('.projects_table');
          Axios.put(`maxproject/project/${props.id}/status`, {
            status_id: status.id,
          })
            .then(({data}) => {
              Block.remove('.projects_table');
              setStatus(status);
              setAnchorEl(null);
            })
            .catch(err => {
              Block.remove('.projects_table');
            });
        };
        return (
          <React.Fragment>
            <Box>
              <Tooltip arrow>
                <p
                  onClick={handleClick}
                  className="Priority_dropdown"
                  style={{backgroundColor: status?.bg_color}}>
                  <span>
                    {status?.name}
                    <AiFillCaretDown />
                  </span>
                </p>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{horizontal: 'right', vertical: 'top'}}
              anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
              <Divider />
              {projectStatusFilters?.map(s => (
                <MenuItem onClick={() => handleStatusChange(s)}>
                  <p className="Priority_dropdown_item">
                    <BsFillCircleFill style={{color: s.bg_color}} />
                    {s.name}
                  </p>
                </MenuItem>
              ))}
            </Menu>
          </React.Fragment>
        );
      };
      return (
        <tr className="progress_all_tabel_tr_mor">
          <td className="project_list_name">
            <Tooltip title={props.name} placement="bottom-start" arrow>
              <NavLink
                className="link_a"
                to={'/Management/project_details/' + props.id}>
                {(page - 1) * 10 + props.idx + 1}
                {' - '}
                {props.name}
              </NavLink>
            </Tooltip>
          </td>
          <td>
            <PriorityDropdown />
          </td>
          <td>
            <StatusDropdown />
          </td>
          <td className="progress_all_tabel_td">
            <div className="progress_all_tabel">
              <div className="row align-items-center">
                <div className="col-2">
                  <span>{props.progress}%</span>
                </div>
                <div className="col-10">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: props.progress + '%',
                      }}
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td>{moment(props.due_date).calendar()}</td>
          <td>
            <div className="progress_all_option_icon">
              <ProjectListDropDown />
            </div>
          </td>
        </tr>
      );
    };

    const NoDataView = () => {
      return (
        <div className="row">
          <div className="col-12">
            <div
              style={{
                minHeight: '30rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '2rem',
              }}>
              <Img
                backgroundImage={NoResultsSVG}
                style={{
                  height: 250,
                  width: 250,
                }}
                className="project_summary_card_icon"
              />
              <span className="mb-3 w-50">
                Sorry, We couldn't find any details that match your filter
                criteria. Please consider clearing your filter or refining your
                criteria for better results.
              </span>
            </div>
          </div>
        </div>
      );
    };

    // if (!projects.length) return <NoDataView />;
    return (
      <>
        <div>
          <table className="table table-hover text-capitalize progress_all_tabel_tabel">
            <thead>
              <tr className="progress_all_tabel_tr">
                <th scope="col">Project Name</th>
                <th scope="col">Priority</th>
                <th scope="col">Status</th>

                <th scope="col">Progress</th>
                <th scope="col">Due Date</th>
                <th scope="col">action</th>
              </tr>
            </thead>
            <tbody>
              {projects?.slice((page - 1) * 10, 10 * page)?.map((val, i) => (
                <Project idx={i} key={i} {...val} />
              ))}
            </tbody>
          </table>
        </div>
        {projects?.length > 10 ? (
          <div className="projec_tabelt_list_pagination_div">
            <Stack spacing={2}>
              <Pagination
                onChange={handleTaskPagination}
                count={Math.ceil(projects?.length / 10)}
              />
            </Stack>
          </div>
        ) : null}
      </>
    );
  };
  const UserChangeLabel = props => {
    return (
      <div className="view_change_label">
        {props.icon}
        <span>{props.title}</span>
      </div>
    );
  };
  const PageFilters = () => {
    return (
      <>
        <div>
          <div className="row g-3">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 order-md-1 order-2 ">
              <div className="all_user_page all_user_page_new">
                <Select
                  placeholder="Select Status"
                  options={projectStatuses}
                  defaultValue={selectedStatusFilter}
                  styles={{
                    menuList: styles => ({
                      ...styles,
                      position: 'relative',
                      top: -10,
                      paddingTop: 10,
                    }),
                  }}
                  isSearchable={false}
                  onChange={option => {
                    setSelectedStatusFilter(option);
                  }}
                />
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 order-md-2 order-1">
              <div className="new_btn_nav ">
                <PageRightFilters />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="custom_container">
      <div className="row g-3 header_my">
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-1 col-sm-12 col-12 order-md-1 order-2">
          <PageAllTopTitle title="Project" />
        </div>
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 order-md-2 order-1">
          <div className="header_input_div">
            <IoSearchOutline />
            <input
              type="search"
              value={query}
              onChange={handleSearch}
              className="form-control"
              placeholder="Search your project here or Enter project name"
            />
          </div>
        </div>
        {permissions.hasSuperPermission ? (
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12 header_btn order-md-3 order-3">
            <div>
              <ExistingClientModal
                data={clients}
                placeholder="Select Client"
                onClick={processSelectedClient}
              />
            </div>
          </div>
        ) : null}
      </div>
      <PageFilters />
      <div className="project_list_tabs_main_div">
        <div className="projects_table">
          <div div className="dashbaord_white_card">
            <ProjectsTable />
          </div>
        </div>
      </div>
    </div>
  );
};
const PageRightFilters = () => {
  const [showFilters, setShowFilters] = React.useState(false);
  let UserIcon = 'https://source.unsplash.com/random/500x500/?user,face';
  const PriorityInner = props => {
    return (
      <div
        className="Priority_filter_inner"
        style={{backgroundColor: props.backgroundColor}}>
        {props.title}
      </div>
    );
  };
  const RequestedAndRejected = props => {
    return <div style={{color: props.color}}>{props.title}</div>;
  };
  const RequestedBy = props => {
    return (
      <div className="Requested_by_div">
        <img src={props.UserPhoto} alt="icon" />
        {props.title}
      </div>
    );
  };
  return (
    <>
      <OutlineBtnWithIcon
        onClick={() => setShowFilters(true)}
        name="All Filters"
        icon={<FaFilter />}
      />

      <Drawer anchor="right" open={showFilters}>
        <Box role="presentation" className="filter_new_top_div">
          <IoCloseSharp onClick={() => setShowFilters(false)} />
          <div className="row mt-3 mb-4">
            <div className="col-6">
              <p className="filter_title_1">All Filters</p>
            </div>
            <div className="col-6 text-end">
              <p className="filter_title_2">Clear All</p>
            </div>
          </div>
          <div className="filter_new_top_div_inner">
            <div className="header_input_div">
              <IoSearchOutline />
              <input
                type="text"
                className="form-control"
                placeholder="Search Filter"
              />
            </div>
            <Accordion alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Request</Accordion.Header>
                <Accordion.Body>
                  <div>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Website Hompeage Design Review"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Website Hompeage Design Review"
                      />
                    </FormGroup>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Priority</Accordion.Header>
                <Accordion.Body>
                  <div>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={
                          <PriorityInner
                            backgroundColor="#FF3E48"
                            title="high"
                          />
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={
                          <PriorityInner
                            backgroundColor="#31C971"
                            title="Low"
                          />
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={
                          <PriorityInner
                            backgroundColor="#F3D32C"
                            title="Medium"
                          />
                        }
                      />
                    </FormGroup>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Status</Accordion.Header>
                <Accordion.Body>
                  <div>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={
                          <RequestedAndRejected
                            title="Requested"
                            color="#0054B7"
                          />
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={
                          <RequestedAndRejected
                            title="Rejected"
                            color="#FF3E48"
                          />
                        }
                      />
                    </FormGroup>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Requested By</Accordion.Header>
                <Accordion.Body>
                  <div>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={
                          <RequestedBy title="User" UserPhoto={UserIcon} />
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={
                          <RequestedBy title="User" UserPhoto={UserIcon} />
                        }
                      />
                    </FormGroup>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className=" mt-4 d-flex justify-content-center align-items-center">
            <div className=" me-3">
              <FullBtn name="Apply" />
            </div>
            <div className="add_project_modal_btn_one">
              <OutlineBtn onClick={() => setShowFilters(false)} name="Close" />
            </div>
          </div>
        </Box>
      </Drawer>
    </>
  );
};
