import {LinkExternalIcon} from '@primer/octicons-react';
import {Skeleton} from 'antd';
import {AiOutlineDelete} from 'react-icons/ai';
import {NavLink, useNavigate} from 'react-router-dom';
import {NewBootstrapTooltip} from '../../../../common/NewComponents/NewTooltip/NewTooltip';
import {Confirm} from 'notiflix';
export const SearchResultAllTypeUI = ({
  title = null,
  data,
  loading,
  handleClearRecent = () => {},
}) => {
  const navigate = useNavigate();
  const handleDelete = () => {
    Confirm.show(
      'Delete?',
      'This action is undonable!',
      'Delete',
      'Cancel',
      () => {},
      () => {},
    );
  };
  const handleClearAll = () => {
    Confirm.show(
      'Delete?',
      'This action is undonable!',
      'Clear All',
      'Cancel',
      handleClearRecent,
      () => {},
    );
  };
  if (!data.length) return null;
  return (
    <div className="top_nav_search_all_scroll">
      <div className="top_nav_search_all_inner_scroll">
        <div className="top_nav_search_all">
          <Skeleton active small paragraph={{rows: 0}} loading={loading}>
            <div className="row m-0">
              {title ? (
                <>
                  <div className="col-9 p-0">
                    <h3 className="top_title">{title}</h3>
                  </div>
                  <div className="col-3 p-0 align-items-end d-flex justify-content-end">
                    <span onClick={handleClearAll} className="ClearAll_btn">
                      Clear all
                    </span>
                  </div>
                </>
              ) : null}
            </div>
          </Skeleton>
          <div className="row g-1">
            {data.map((item, i) => (
              <div key={i} className="col-12">
                <Skeleton
                  active
                  small
                  avatar
                  paragraph={{rows: 0}}
                  loading={loading}>
                  <div className="top_nav_search_data_all">
                    <span>{item.icon}</span>
                    {item.title.length > 60
                      ? `${item.title.substring(0, 60)}...`
                      : item.title}

                    <NewBootstrapTooltip
                      ChildComponent={
                        <NavLink
                          to={item.link}
                          onClick={item?.onClick}
                          target="_blank">
                          <LinkExternalIcon />
                        </NavLink>
                      }
                      title="Open Link"
                      placement="bottom"
                    />
                    <NewBootstrapTooltip
                      ChildComponent={
                        <span onClick={handleDelete} className="delete_btn">
                          <AiOutlineDelete />
                        </span>
                      }
                      title="Delete"
                      placement="bottom"
                    />
                  </div>
                </Skeleton>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export const SearchResultInner = ({
  title = null,
  data,
  loading,
  handleClearRecent = () => {},
  showClearAll = false,
}) => {
  const navigate = useNavigate();
  const handleDelete = () => {
    Confirm.show(
      'Delete?',
      'This action is undonable!',
      'Delete',
      'Cancel',
      () => {},
      () => {},
    );
  };
  const handleClearAll = () => {
    Confirm.show(
      'Delete?',
      'This action is undonable!',
      'Clear All',
      'Cancel',
      handleClearRecent,
      () => {},
    );
  };
  if (!data.length) return null;
  return (
    <div className="top_nav_search_all">
      <Skeleton active small paragraph={{rows: 0}} loading={loading}>
        <div className="row m-0">
          {title ? (
            <>
              <div className="col-9 p-0">
                <h3 className="top_title">{title}</h3>
              </div>
              {showClearAll ? (
                <div className="col-3 p-0 align-items-end d-flex justify-content-end">
                  <span onClick={handleClearAll} className="ClearAll_btn">
                    Clear all
                  </span>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      </Skeleton>
      <div className="row g-1">
        {data.map((item, i) => (
          <div key={i} className="col-12">
            <Skeleton
              active
              small
              avatar
              paragraph={{rows: 0}}
              loading={loading}>
              <div className="top_nav_search_data_all">
                <span>{item.icon}</span>
                {item.title.length > 60
                  ? `${item.title.substring(0, 60)}...`
                  : item.title}

                <NewBootstrapTooltip
                  ChildComponent={
                    <NavLink
                      onClick={item?.onClick}
                      to={item.link}
                      target="_blank">
                      <LinkExternalIcon />
                    </NavLink>
                  }
                  title="Open Link"
                  placement="bottom"
                />
                <NewBootstrapTooltip
                  ChildComponent={
                    <span onClick={handleDelete} className="delete_btn">
                      <AiOutlineDelete />
                    </span>
                  }
                  title="Delete"
                  placement="bottom"
                />
              </div>
            </Skeleton>
          </div>
        ))}
      </div>
    </div>
  );
};
