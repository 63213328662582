import {useDispatch, useSelector} from 'react-redux';
import './MyTimesheet.scss';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
// list
import {
  FilterIcon,
  DeviceCameraVideoIcon,
  StopwatchIcon,
  TasklistIcon,
  XIcon,
  ProjectIcon,
} from '@primer/octicons-react';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import {styled} from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import {NewBackButton} from '../../../../common/NewComponents/NewButtons/Buttons';
import Axios from './../../../../../config/api';
// list
import {MyDatePicker} from '../../../../common/NewComponents/MyDatePicker/MyDatePicker';
import {SearchBox} from '../../../../common/NewComponents/SearchBox/SearchBox';
import React, {useEffect, useState} from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import {NewOutlinedButtonWithIcon} from '../../../../common/NewComponents/NewButtons/Buttons';
import {NewSelect} from '../../../../common/NewComponents/NewSelect/NewSelect';
import {FormGroup, Pagination, Stack, Typography} from '@mui/material';
import {useNonInitialEffect} from '../../../../../utils/hooks/non-initial-effect.tsx';
import {Block} from 'notiflix';
import moment from 'moment';
import {
  formatDuration,
  getQueryParam,
  setQueryParam,
} from '../../../../../utils/functions';
import {setGlobal} from '../../../../../redux/slices/globalSlice';
import {ImageWithFallbackComp} from './MyTimesheet';

export const UserTimesheetProjectDetail = () => {
  const storData = useSelector(state => state);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const {global} = useSelector(state => state);
  let {state} = useLocation();
  if (!state) state = window.history.state;
  //states
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(getQueryParam('page') || 1);
  const [hideEmptyActivities, setHideEmptyActivities] = useState(
    global?.hideEmptyActivities || false,
  );
  const [filters, setFilters] = useState({tasks: [], meetings: []});
  const [selectedFilters, setSelectedFilters] = useState({
    tasks: [],
    meetings: [],
  });
  const [dateRange, setDateRange] = useState(
    global?.timesheetDateRange || [
      moment().subtract(7, 'days').toDate(),
      moment().toDate(),
    ],
  );
  const [data, setData] = useState({
    meetings: [],
    tasks: [],
    project: {name: '', logo: ''},
  });

  useNonInitialEffect(() => {
    // Timer ID for the delayed fetch
    let timerId;
    // Clear previous timer, if any
    clearTimeout(timerId);
    // Set a new timer for the delayed fetch
    timerId = setTimeout(fetchUserProjectTimesheet, 500);
    // Clean up timer on component unmount
    return () => clearTimeout(timerId);
  }, [query]);

  //hooks
  useEffect(async () => {
    if (!params?.project_id) navigate(-1);
    fetchUserProjectTimesheet();

    return () => {};
  }, []);
  useEffect(async () => {
    if (dateRange[1]) fetchUserProjectTimesheet();

    return () => {};
  }, [dateRange, hideEmptyActivities]);

  useEffect(() => {
    setQueryParam('page', page, state);
  }, [page]);

  const onApplyFilters = () => {
    fetchUserProjectTimesheet(true);
  };

  const fetchUserProjectTimesheet = () => {
    Block.circle('.loading', 'Loading');
    const q = new URLSearchParams({
      query,
      start_date: moment(dateRange[0]).format('YYYY-MM-DD'),
      end_date: moment(dateRange[1]).format('YYYY-MM-DD'),
      tasks: JSON.stringify(selectedFilters.tasks.map(p => p.value)),
      meetings: JSON.stringify(selectedFilters.meetings.map(m => m.value)),
    });
    Axios.fetch(
      `maxproject/timesheet/${params?.user_id}/project/${
        params?.project_id
      }?${q.toString()}`,
    )
      .then(({data}) => {
        Block.remove('.loading');
        setData({
          meetings: data?.meetings_data?.filter(Meeting => {
            if (!hideEmptyActivities) return true;
            if (Object.keys(Meeting?.timers).length) return true;
            return false;
          }),
          tasks: data?.tasks_data?.filter(Task => {
            if (!hideEmptyActivities) return true;
            if (Object.keys(Task?.timers).length) return true;
            return false;
          }),
          project: data?.project_data,
        });
        //updating filters data
        setFilters(data?.filters);
      })
      .catch(err => {
        if (err.response.status == 500) navigate('/Management/my_timesheet');
        Block.remove('.loading');
      });
  };

  const handleSearch = e => {
    setQuery(e.target.value);
  };

  return (
    <>
      <div
        className="my_timesheet_main_div loading"
        data-theme={storData.global.isMode}>
        <div className="my_timesheet_nav_div">
          <button
            onClick={() => navigate('/Management/my_timesheet')}
            style={{marginLeft: '1rem'}}>
            My Timesheet
          </button>
          <button
            className="active"
            onClick={() => navigate('/Management/all_timesheet')}
            style={{marginLeft: '1rem'}}>
            all Timesheet
            <div></div>
          </button>
        </div>
        <NewBackButton
          link={-1}
          img={state?.user?.avatar}
          title={state?.user?.name + ' / ' + state?.Project?.name}
        />
        <div className="my_timesheet_inner_div">
          <div className="row g-3">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <MyDatePicker dateRange={dateRange} onChange={setDateRange} />
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 search_box_page_div">
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                style={{marginRight: 10}}>
                <Typography className="approval_request_input_switch_text">
                  Hide Empty Activities
                </Typography>
                <AntSwitch
                  inputProps={{'aria-label': 'ant design'}}
                  checked={hideEmptyActivities}
                  onChange={() => {
                    setHideEmptyActivities(!hideEmptyActivities);
                    setPage(1);
                    dispatch(
                      setGlobal({
                        hideEmptyActivities: !hideEmptyActivities,
                      }),
                    );
                  }}
                />
              </Stack>
              <SearchBox
                // Data={SearchBoxData}
                placeholder="Search here"
                value={query}
                onChange={handleSearch}
              />
              <NewFilters
                filters={filters}
                onApplyFilters={onApplyFilters}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
              />
            </div>
            <div className="col-12">
              <TimesheetList
                page={page}
                setPage={setPage}
                data={data}
                date_range={dateRange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const BootstrapTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} arrow classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
const TimesheetList = ({
  data = {
    dates_data: [],
    meetings: [],
    tasks: [],
    project: {name: '', logo: ''},
  },
  page,
  date_range = [],
  setPage = () => {},
}) => {
  //?states
  const combinedData = [...data?.meetings, ...data?.tasks];
  //? hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //? Variables
  // Calculate the total number of days between the start and end dates
  const daysDiff = moment(date_range[1]).diff(date_range[0], 'days');
  // Generate the array of dates
  const Dates = Array.from({length: daysDiff + 1}, (_, i) => {
    return moment(date_range[0]).clone().add(i, 'days').toISOString();
  });
  const TotalWorkingTimeByDate = calculateTotalDuration(data);

  //? Functions
  const handleOpenMeeting = Meeting => {
    if (Meeting.isDeleted) return false;
    navigate('/Management/meeting/' + Meeting.id);
  };
  const handleOpenTask = Task => {
    if (Task.isDeleted) return false;
    dispatch(setGlobal({taskId: Task.id, showTask: true}));
  };
  const handleTaskPagination = (e, page) => {
    setPage(page);
  };

  //? Components
  const TableThDate = ({dateString = null}) => {
    if (!dateString)
      return (
        <th scope="col" className="table_th_inner_main_div">
          <div className={`table_th_inner_div`}>
            <span>-</span>
          </div>
        </th>
      );
    const date = moment(dateString);
    const formattedDate = date.format('YYYY-MM-DD');
    const isActive = date.isSame(moment(), 'day');
    return (
      <th scope="col" className="table_th_inner_main_div">
        <div className={`table_th_inner_div ${isActive ? 'active_class' : ''}`}>
          <span>{date.format('ddd, MMM D')}</span>
          <p>{TotalWorkingTimeByDate?.dates[formattedDate] || '-'}</p>
          <div
            className="line"
            style={{display: isActive ? 'block' : 'none'}}></div>
        </div>
      </th>
    );
  };
  const TaskCell = ({Task, idx}) => {
    return (
      <div
        style={{
          cursor: 'pointer',
          ...(Task.isDeleted ? {opacity: '0.5', cursor: 'not-allowed'} : {}),
        }}
        onClick={() => handleOpenTask(Task)}
        className="timesheet_list_project_name_div top_border">
        <span className="sr_no">{idx}</span>
        <span className="task_icon">
          <TasklistIcon />
        </span>
        <h3>
          {Task?.taskName.length > 15
            ? `${Task?.taskName.substring(0, 15)}...`
            : Task?.taskName}
        </h3>
        {Task?.taskTimer ? (
          <BootstrapTooltip title={Task?.taskName} placement="top">
            <span className="time_icon">
              <StopwatchIcon />
            </span>
          </BootstrapTooltip>
        ) : null}
      </div>
    );
  };
  const MeetingCell = ({Meeting, idx}) => {
    return (
      <div
        style={{
          cursor: 'pointer',
          ...(Meeting.isDeleted ? {opacity: '0.5', cursor: 'not-allowed'} : {}),
        }}
        onClick={() => handleOpenMeeting(Meeting)}
        key={idx}
        className="timesheet_list_project_name_div top_border">
        <span className="sr_no">{idx}</span>
        <span className="task_icon">
          <DeviceCameraVideoIcon />
        </span>
        <h3>
          {Meeting?.meetingName.length > 15
            ? `${Meeting?.meetingName.substring(0, 15)}...`
            : Meeting?.meetingName}
        </h3>
        {Meeting?.meetingTimer ? (
          <BootstrapTooltip title={Meeting?.meetingName} placement="top">
            <span className="time_icon">
              <StopwatchIcon />
            </span>
          </BootstrapTooltip>
        ) : null}
      </div>
    );
  };
  const TimerRow = Task => {
    const TimerTd = ({idx, day}) => {
      //getting current column date and formatting
      const currentDate = moment(day).format('YYYY-MM-DD');
      return (
        <td key={idx}>
          <div className="text_box">
            {Task?.timers[currentDate]?.duration || '-'}
          </div>
        </td>
      );
    };
    return (
      <tr className="table_tr_inner_div">
        {Dates?.map((day, idx) => (
          <TimerTd day={day} idx={idx} />
        ))}
      </tr>
    );
  };
  const RowTotalDurationCell = Task => {
    return (
      <div className="end_text_box_div top_border">
        <span className="end_text_box">{Task?.totalWorkingTime || '-'}</span>
      </div>
    );
  };
  const getCell = (item, i) => {
    let idx = (page - 1) * 10 + i + 1;
    switch (item.type) {
      case 'MEETING':
        return <MeetingCell idx={idx} Meeting={item} />;
      case 'TASK':
        return <TaskCell idx={idx} Task={item} />;
      default:
        return null;
    }
  };
  return (
    <>
      <div className="timesheet_list_top_main_div timesheet_list_top_inner_main_div">
        <div className="timesheet_list_top_div">
          {/* ---------------------------------------------------------------------------------------------------- */}
          <div className="row m-0">
            <div className="col-3 p-0">
              {/* ProjectName */}
              <div className="timesheet_list_top_title_div">
                <span>S.No</span>
                <ImageWithFallbackComp
                  src={data?.project?.logo}
                  alt="icon"
                  ErrorComponent={() => (
                    <div style={{marginLeft: '1rem', marginRight: 5}}>
                      <ProjectIcon size={15} />
                    </div>
                  )}
                />
                <p>
                  {data?.project?.name.length > 15
                    ? `${data?.project?.name.substring(0, 15)}...`
                    : data?.project?.name}
                </p>
              </div>
              {combinedData
                ?.slice((page - 1) * 10, page * 10)
                ?.map((item, i) => getCell(item, i))}
              {/* Task title */}
            </div>
            <div className="col-7 p-0">
              <div className="timesheet_list_table_div">
                <table class="table">
                  <thead>
                    <tr>
                      {Dates?.map(Date => (
                        <TableThDate key={1} dateString={Date} />
                      ))}
                      {Dates.length >= 7
                        ? []
                        : Array(7 - Dates.length)
                            .fill(0)
                            ?.map(Date => <TableThDate />)}
                    </tr>
                  </thead>
                  <tbody>
                    {combinedData
                      ?.slice((page - 1) * 10, page * 10)
                      ?.map((item, i) => (
                        <TimerRow idx={i} {...item} />
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-2 p-0">
              <div className="timesheet_list_top_Total_title_div">
                <span>Total</span>
                <p>{TotalWorkingTimeByDate?.totalWorkingTime || '-'}</p>
              </div>
              {combinedData
                ?.slice((page - 1) * 10, page * 10)
                ?.map((item, i) => (
                  <RowTotalDurationCell key={i} {...item} />
                ))}
            </div>
          </div>
          {combinedData.length > 10 ? (
            <div className="page_pagination_list_div">
              <div className="page_pagination_main_number_div">
                <Pagination
                  defaultPage={Number(page)}
                  onChange={handleTaskPagination}
                  count={Math.ceil(combinedData.length / 10)}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
const NewFilters = ({
  filters = {tasks: [], meetings: []},
  selectedFilters = {tasks: [], meetings: []},
  setSelectedFilters = () => {},
  onApplyFilters = () => {},
}) => {
  //hooks
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = open => {
    setIsOpen(open);
  };
  const storData = useSelector(state => state);
  return (
    <React.Fragment>
      <NewOutlinedButtonWithIcon
        icon={<FilterIcon />}
        onClick={() => toggleDrawer(true)}
        title="Filter"
      />
      <Drawer
        anchor={'right'}
        open={isOpen}
        onClose={() => toggleDrawer(false)}
        className="new_filters_one_main_div_top">
        <div
          className="new_filters_one_main_div"
          data-theme={storData.global.isMode}>
          <div className="top_title_and_close_btn">
            <h3>Filter</h3>
            <span onClick={() => toggleDrawer(false)}>
              <XIcon />
            </span>
          </div>
          <div className="inner_div">
            <div className="row g-2">
              <div className="col-12 ">
                <NewSelect
                  label="Tasks"
                  isMulti={true}
                  data={filters.tasks}
                  placeholder="Select tasks"
                  selected={selectedFilters.tasks}
                  setSelected={tasks =>
                    setSelectedFilters(old => ({...old, tasks}))
                  }
                  // ErrorMessage="Please select employee name"
                />
              </div>
              <div className="col-12">
                <NewSelect
                  label="Meetings"
                  isMulti={true}
                  data={filters.meetings}
                  placeholder="Select meetings"
                  selected={selectedFilters.meetings}
                  setSelected={meetings =>
                    setSelectedFilters(old => ({...old, meetings}))
                  }
                  // ErrorMessage="Please select project"
                />
              </div>
              <div className="col-12 new_filters_bottom_btn">
                <Button
                  onClick={() => {
                    onApplyFilters();
                    toggleDrawer(false);
                  }}
                  variant="contained"
                  className="btn_1">
                  Apply
                </Button>
                <Button
                  onClick={() => toggleDrawer(false)}
                  variant="outlined"
                  className="btn_2">
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
};
function calculateTotalDuration(data) {
  const {meetings = [], tasks = []} = data;

  const dateDurations = {};
  let overallTotalSeconds = 0;

  const addDuration = (date, seconds) => {
    if (!dateDurations[date]) {
      dateDurations[date] = 0;
    }
    dateDurations[date] += seconds;
    overallTotalSeconds += seconds;
  };

  meetings.forEach(meeting => {
    Object.entries(meeting.timers).forEach(([date, timer]) => {
      addDuration(date, timer.seconds);
    });
  });

  tasks.forEach(task => {
    Object.entries(task.timers).forEach(([date, timer]) => {
      addDuration(date, timer.seconds);
    });
  });

  const dates = {};
  console.log('dateDurations', dateDurations);
  Object.entries(dateDurations).forEach(([date, seconds]) => {
    console.log(date, seconds);
    dates[date] = formatDuration(seconds, true);
  });

  return {dates, totalWorkingTime: formatDuration(overallTotalSeconds)};
}
const AntSwitch = styled(Switch)(({theme}) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));
