import {PageAllTopTitle} from '../../../../common/ManagementComponents/PageAllTopTitle';
import {ProfileImg} from '../../../../common/ManagementComponents/ProfileImg';
import {BiPhoneCall} from 'react-icons/bi';
import {MdAlternateEmail} from 'react-icons/md';
import Owner from '../../../../../pics/Management/Owner.png';
import {FiMapPin} from 'react-icons/fi';
import {AiOutlinePlus, AiOutlineFieldTime} from 'react-icons/ai';
import {FullBtnWithIcon} from '../../../../common/ManagementComponents/Btn/Btn';
// mui
import * as React from 'react';
import {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Axios from './../../../../../config/api';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import AvatarGroup from '@mui/material/AvatarGroup';
import {AiFillCaretDown} from 'react-icons/ai';
import {Notify} from 'notiflix/build/notiflix-notify-aio';
import {Block} from 'notiflix/build/notiflix-block-aio';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import {DEFAULT_AVATAR, getProjectFileUrl} from '../../../../../constants';
import moment from 'moment';
import {
  getClientAvatarUrl,
  DEFAULT_CLIENT_AVATAR,
} from '../../../../../constants';
// index
export const ClientProfileDetails = () => {
  const params = useParams();
  const [data, setData] = useState({client: {}, tasks: [], services: []});
  useEffect(() => {
    getClientDashboard();
    return () => {};
  }, [params?.client_id]);

  const getClientDashboard = () => {
    Block.circle('.loading_card');
    Axios.fetch(`maxproject/client/${params?.client_id}/dashboard`)
      .then(({data}) => {
        Block.remove('.loading_card');
        //preparing payload
        let payload = data;
        //checking if client avatar available
        console.log(payload?.client?.avatar);
        if (payload?.client?.avatar)
          //updating client avatar url
          payload.client.avatar = getClientAvatarUrl(data.client.avatar);
        //updating state
        setData(payload);
      })
      .catch(err => {
        Block.remove('.loading_card');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  return (
    <div className="custom_container">
      <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
        <div className="col-12 ">
          <PageAllTopTitle title="Client Profile Details" />
        </div>
        <div className="col-12">
          <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
            <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-12 col-sm-12 col-12">
              <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
                <div className="col-12">
                  <ProfileCard
                    client_id={params?.client_id}
                    data={data?.client}
                    setData={setData}
                  />
                </div>
                <div className="col-12">
                  <ProjectList data={data?.services} />
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-5 col-md-12 col-sm-12 col-12">
              <ClientTasks data={data?.tasks} client_id={params?.client_id} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
// index end
// Profile Card
const ProfileCard = ({data, setData, client_id}) => {
  const onAvatarChange = e => {
    if (e.target.files && e.target.files[0]) {
      //showning loader
      Block.circle('.client_card');
      //preparint form data
      let formdata = new FormData();
      //appending avatar
      formdata.append('avatar', e.target.files[0]);
      //initializing network
      Axios.put('maxproject/client/avatar/' + client_id, formdata)
        .then(({data}) => {
          //removing loader
          Block.remove('.client_card');
          //updating avatar
          setData(old => ({
            ...old,
            client: {
              ...old.client,
              avatar: URL.createObjectURL(e.target.files[0]),
            },
          }));
          Notify.success(data.message);
        })
        .catch(err => {
          //removing loader
          Block.remove('.client_card');
          //checking for any error message
          if (err?.response?.data?.message)
            Notify.failure(err?.response?.data?.message);
        });
    }
  };
  return (
    <div className="dashbaord_white_card loading_card client_card">
      <div className="row">
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 clint_profile_card_img">
          <ProfileImg
            className="ProjectDetailsCard_img"
            Photo={data?.avatar || DEFAULT_AVATAR}
            onChange={onAvatarChange}
          />
        </div>
        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
          <div className="row g-1">
            <div className="col-12">
              <div className="clint_profile_card_text">
                <h3>{data?.client_name}</h3>
                <p>{data?.org_name}</p>
              </div>
            </div>

            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="clint_profile_card_poits clint_profile_card_poits_mob">
                <span>
                  <BiPhoneCall />
                </span>
                <p>
                  +{data?.CountryCode?.code} {data?.phone}
                </p>
              </div>
            </div>
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
              <div>
                <div className="clint_profile_card_poits ">
                  <span>
                    <MdAlternateEmail />
                  </span>
                  <p>{data?.client_email}</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="clint_profile_card_poits clint_profile_card_poits_mob">
                <span>
                  <img src={Owner} alt="icon" />
                </span>
                <p>{data?.designation}</p>
              </div>
            </div>
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="clint_profile_card_poits">
                <span>
                  <FiMapPin />
                </span>
                <p>
                  {data?.address_1}, {data?.city}, {data?.Country?.name}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
// Profile Card end

// Client Tasks
const ClientTasks = ({data, client_id}) => {
  const Task = props => {
    const [state, setState] = React.useState(false);
    return (
      <>
        <Drawer anchor="right" open={state} onClick={() => setState(false)}>
          <Box style={{minWidth: 300, padding: 20}} role="presentation">
            {props.name}
          </Box>
        </Drawer>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="open_tasks_card">
            <h3 onClick={() => setState(true)} className="link_a">
              {props.name}
            </h3>
            <div className="row">
              <div className="col-12">
                <div className="open_tasks_img_div">
                  <UserParticipants data={[{User: props?.User}]} />
                </div>
              </div>
            </div>
            <div
              className="project_list_priority_div"
              style={{backgroundColor: props?.Priority?.bg_color}}>
              <span
                className="project_list_priority"
                style={{color: props?.Priority?.text_color}}>
                {props?.Priority?.name}
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="inner_taska_div_main client_tasks_div loading_card">
      <div className="inner_taska_title_div_main">
        <div className="row">
          <div className="col-xxl-6 col-xl-6 col-lg-5 col-md-6 col-sm-12 col-12">
            <h3>Client Tasks</h3>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-6 col-sm-12 col-12 text-end">
            <div>
              <FullBtnWithIcon
                link={'/Management/create_client_task/' + client_id}
                name="Create Client Task"
                icon={<AiOutlinePlus />}
              />
            </div>
          </div>
        </div>
        <div className="clints_list_scroll">
          <div className="row g-3 mt-2">
            {data.map((val, i) => (
              <Task key={i} {...val} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
// Client Tasks end
// project list
const ProjectList = ({data}) => {
  const ProjectCard = props => {
    return (
      <div className="col-12">
        <div className="Project_list_card">
          <div className="Project_list_card_time">
            <span>
              <AiOutlineFieldTime />
            </span>
            {moment(props?.end_date).calendar()}
          </div>
          <div className="Project_list_card_img_div">
            <ProfileImg
              Photo={getProjectFileUrl(props?.Project?.project_image)}
              className="Project_list_card_img"
            />
            <p>{props?.Project?.name}</p>
            <div className="Project_list_card_title">
              <h3>{props?.Service?.name}</h3>
            </div>
          </div>
          <div className="Project_list_card_inner_img_top_div">
            <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 Project_list_card_inner_img_div">
                <div className="Project_list_card_inner_img">
                  {/* <UserParticipants data={props.AssigneeData} /> */}
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 Project_list_card_inner_card_div">
                <div className="project_details_card_box w-100 mb-2">
                  <p>{moment(props?.end_date).calendar()} </p>
                  <span>Due Date</span>
                </div>
                <div className="project_details_card_box project_details_card_box_inner w-100">
                  <p>
                    {props?.pending_tasks}/{props?.total_tasks}
                  </p>
                  <span>Open Tasks</span>
                </div>
              </div>
              <div className="col-12">
                <div className="Project_list_card_progress">
                  <p>{props?.Project?.progress}%</p>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{width: props.Project?.progress + '%'}}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="dashbaord_white_card loading_card">
      <div className="inner_taska_title_div_main">
        <h3>Project Services</h3>
      </div>
      <div className="project_list_scroll">
        <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
          {data.map((val, i) => (
            <ProjectCard key={i} {...val} />
          ))}
        </div>
      </div>
    </div>
  );
};
// project list end
const UserParticipants = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ParticipantsModal = props => {
    const ParticipantsApp = props => {
      return (
        <div className="col-12">
          <div className="user_card_div_inner">
            <Avatar
              alt={props?.User?.name}
              src={props?.User?.avatar}
              sx={{width: 15, height: 15}}
            />
            <p>{props?.User?.name}</p>
            <h6>{props?.User?.Designation?.name}</h6>
          </div>
        </div>
      );
    };
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: '#ddc8c8',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
        <div className="user_card_div_title">
          <h3>Created By</h3>
        </div>
        <div className="user_card_div">
          <div className="row g-2 ">
            {props.data.map((val, i) => {
              return <ParticipantsApp key={i} {...val} />;
            })}
          </div>
        </div>
      </Menu>
    );
  };
  return (
    <>
      <ParticipantsModal data={props.data} />
      <div className="Participants_icon_div">
        <AvatarGroup onClick={handleClick} max={5}>
          {props.data.map((val, i) => (
            <Avatar key={i} alt={val?.User?.name} src={val?.User?.avatar} />
          ))}
        </AvatarGroup>
        <AiFillCaretDown onClick={handleClick} className="Participants_icon" />
      </div>
    </>
  );
};
