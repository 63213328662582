import React, {useState} from 'react';
import ActiveIcon from '../../../../../../pics/Management/checkColor.svg';
import {NewBootstrapTooltip} from '../../../../../common/NewComponents/NewTooltip/NewTooltip';
import {colorButtons} from '../../Data';

export const ColorBtn = ({handleColorChange}) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index, color) => {
    setActiveIndex(index);
    handleColorChange(color);
  };

  return (
    <>
      {colorButtons.map((val, index) => (
        <NewBootstrapTooltip
          key={index}
          ChildComponent={
            <span
              className={`button ${
                activeIndex === index
                  ? 'active top_nav_note_visible_color_div_btn'
                  : 'top_nav_note_visible_color_div_btn'
              }`}
              onClick={() => handleClick(index, val.colorName)}>
              <span
                className="color_div"
                style={{backgroundColor: val.colorName}}></span>
              {activeIndex === index && (
                <img className="ActiveIcon" src={ActiveIcon} alt="icon" />
              )}
            </span>
          }
          title={val.tooltip}
          placement="bottom"
        />
      ))}
    </>
  );
};
