import React, {useEffect, useState} from 'react';
import {BsArrowLeft} from 'react-icons/bs';
import {PageAllTopTitle} from '../../../../common/ManagementComponents/PageAllTopTitle';
import {
  Avatar,
  Pagination,
  Stack,
  TextField,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import {UserParticipants} from '../../../../common/ManagementComponents/Participants';
import {Modal, ButtonToolbar, Placeholder} from 'rsuite';
import DemoImg from '../../../../../pics/Management/tsc.png';
import DemoPdf from '../../../../../pics/Management/DemoPDF.pdf';
import {FaRegEye} from 'react-icons/fa';
import DemoPDFIcon from '../../../../../pics/Management/DemoPDF.png';
import {IoIosCloseCircleOutline, IoMdAttach, IoMdClose} from 'react-icons/io';
import {OutlineBtn} from '../../../../common/ManagementComponents/Btn/Btn';
import CommantIcon from '../../../../../pics/Management/commantIcon.svg';
import SubTaskIcon from '../../../../../pics/Management/task_alt.png';
import {
  MdFileDownload,
  MdOutlineApproval,
  MdOutlineMessage,
} from 'react-icons/md';
import {TbCalendarPlus} from 'react-icons/tb';
import {styled} from '@mui/material/styles';
import Accordionn from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Modall from 'react-bootstrap/Modal';
import {IoClose} from 'react-icons/io5';
import {Button} from 'react-bootstrap';
import {Block, Confirm, Notify} from 'notiflix';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Axios from './../../../../../config/api';
import {getUser} from '../../../../../redux/slices/userSlice';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import CommentsPdfIcon from '../../../../../pics/pdf.svg';
import CommentsPdfIcon2 from '../../../../../pics/word.svg';
import CommentsPdfIcon3 from '../../../../../pics/powerpoint.svg';
import CommentsPdfIcon4 from '../../../../../pics/excel.svg';
import CommentsPdfIcon5 from '../../../../../pics/file.png';
import RequestedIcon from '../../../../../pics/Management/RequestedIcon.svg';
import Approvedicon from '../../../../../pics/Management/Approvedicon.svg';
import Rejectedicon from '../../../../../pics/Management/Rejectedicon.svg';
import TaskIcon from '../../../../../pics/Management/add_task2.svg';
import {setGlobal} from '../../../../../redux/slices/globalSlice';

const BootstrapTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} arrow classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export const SentApprovalList = () => {
  const user = useSelector(getUser);
  const params = useParams();
  const dispatch = useDispatch();
  //states
  const [approval, setApproval] = useState({});
  const [Task, setTask] = useState({});

  //? --> Hooks <--
  useEffect(() => {
    if (params?.approval_id) getApproavalData();
  }, [params?.approval_id]);

  //? --> Functions <--
  const getApproavalData = () => {
    //showing loader
    Block.circle('.approval_body', 'Loading...');
    const URL = `maxproject/approval/sent_approval/${params?.approval_id}`;
    Axios.fetch(URL)
      .then(({data}) => {
        setApproval(data?.approval);
        const ApprovalUser = data?.approval?.ApprovalUsers?.find(
          u => u?.User?.id == user.id,
        );
        //getting task owner
        let TaskOwner = data?.approval?.Task?.TaskMembers.find(
          member => member.isOwner,
        );
        setTask(data?.approval?.Task || data?.approval?.ClientTask);
        setApproval({
          ...data?.approval,
          TaskOwner: TaskOwner?.User,
        });
        //removing loader
        Block.remove('.approval_body');
      })
      .catch(err => {
        //removing loader
        Block.remove('.approval_body');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleOpenTaskDetail = () => {
    dispatch(setGlobal({taskId: approval?.task_id, showTask: true}));
  };
  //? --> Components <--
  const Docs = ({data}) => {
    return (
      <div className="add_new_subtask_Uploaded_mai_div">
        {data?.map(doc => (
          <div className="item">
            <div className="add_new_subtask_Uploaded_img_div">
              <img
                src={doc.type == 'application/pdf' ? DemoPDFIcon : doc.url}
                alt="Uploaded photo"
              />
              <p>
                {doc.original_name.length > 12
                  ? doc.original_name?.substr(0, 12) + '...'
                  : doc.original_name}
              </p>

              <div className="add_new_subtask_Uploaded_img_div_overlay">
                <div className="modal_img_view_btn_div">
                  <a
                    className="modal_img_view_btn me-1"
                    href={doc.url}
                    download>
                    <MdFileDownload />
                  </a>
                  <ModalImgView
                    title={doc.original_name}
                    downloadItem={doc.url}
                    modalUi={
                      doc?.type === 'application/pdf' ? (
                        <object
                          data={doc.url}
                          style={{
                            width: '100%',
                            height: '35rem',
                          }}></object>
                      ) : (
                        <img
                          className="sub_task_modul_img_div"
                          src={doc.url}
                          alt="img"
                        />
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  const ApprovalUsersTimeline = () => (
    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
      <div className="dashbaord_white_card">
        <p className="send_list_page_detai_approval_status_title">
          Approval status: <span>{approval?.status}</span>
        </p>
        <div className="received_list_modal_timeline_uper_div">
          <ul className=" approval_request_details_feedback_ul">
            <li>
              <img
                className="feedback_modal_ul_icon"
                src={approval?.RequestedUser?.avatar}
                alt={approval?.RequestedUser?.name}
              />
              <div className="feedback_modal_ul_main_div">
                <p className="feedback_modal_ul_name mb-1">
                  {approval?.RequestedUser?.name}
                  <span>({approval?.RequestedUser?.Designation?.name})</span>
                </p>
                <span
                  className="feedback_modal_ul_requested "
                  style={{
                    backgroundColor: '#e8f2fc',
                    borderColor: '#1a7fdd',
                  }}>
                  Requested By
                </span>

                <p className="feedback_modal_ul_time">
                  <TbCalendarPlus />
                  {moment(approval?.createdAt).calendar()}
                </p>
              </div>
            </li>
            {approval?.ApprovalUsers?.map(ApprovalUser => (
              <li>
                <img
                  className="feedback_modal_ul_icon"
                  src={ApprovalUser?.User?.avatar}
                  alt={ApprovalUser?.User?.name}
                />
                <div className="feedback_modal_ul_main_div">
                  <p className="feedback_modal_ul_name mb-1">
                    {ApprovalUser?.User?.name}
                    <span>({ApprovalUser?.User?.Designation?.name})</span>
                  </p>
                  <span
                    className="feedback_modal_ul_requested"
                    style={{
                      backgroundColor: getStatusBackgroundColor(
                        ApprovalUser?.status,
                      ),
                      borderColor: getStatusBorderColor(ApprovalUser?.status),
                    }}>
                    {ApprovalUser?.status === 'REASSIGNED'
                      ? 'Shift Control'
                      : ApprovalUser?.status}
                    {ApprovalUser?.status == 'REASSIGNED'
                      ? ` to (${ApprovalUser?.AssignedUser?.name})`
                      : null}
                  </span>
                  {ApprovalUser?.comment || ApprovalUser?.reason ? (
                    <>
                      <p className="feedback_modal_ul_commant mt-2">
                        <img src={CommantIcon} alt="icon" />
                        <span className="w-100">
                          {ApprovalUser?.comment
                            ? ReactHtmlParser(ApprovalUser?.comment)
                            : ApprovalUser?.reason}
                        </span>
                      </p>
                      <div style={{marginLeft: '20px'}}>
                        <Docs
                          data={ApprovalUser?.ApprovalUserAttachments || []}
                        />
                      </div>
                    </>
                  ) : null}
                  <p className="feedback_modal_ul_time">
                    <TbCalendarPlus />
                    {moment(ApprovalUser?.updatedAt).calendar()}
                  </p>
                </div>
                {ApprovalUser?.ApprovalFeedbacks?.map(Feedback => (
                  <div className="feedback_modal_ul_main_div">
                    <span
                      className="feedback_modal_ul_requested "
                      style={{
                        backgroundColor: '#e8f2fc',
                        borderColor: '#1a7fdd',
                      }}>
                      FEEDBACK
                    </span>
                    <p className="feedback_modal_ul_commant mt-2">
                      <img src={CommantIcon} alt="icon" />
                      <span className="w-100">
                        {Feedback?.comment
                          ? ReactHtmlParser(Feedback?.comment)
                          : Feedback?.reason}
                      </span>
                    </p>
                    <div style={{marginLeft: '20px'}}>
                      <Docs
                        data={Feedback?.ApprovalFeedbackAttachments || []}
                      />
                    </div>
                    <p className="feedback_modal_ul_time">
                      <TbCalendarPlus />
                      {moment(Feedback?.updatedAt).calendar()}
                    </p>
                  </div>
                ))}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );

  const ModalImgView = props => {
    const [open, setOpen] = React.useState(false);
    const [size, setSize] = React.useState();
    const handleOpen = value => {
      setSize(value);
      setOpen(true);
    };
    const handleClose = () => setOpen(false);

    return (
      <>
        <ButtonToolbar>
          <span
            className="modal_img_view_btn p-0"
            size="lg"
            onClick={() => handleOpen('lg')}>
            <FaRegEye />
          </span>
        </ButtonToolbar>
        <Modal size={size} open={open} onClose={handleClose}>
          <Modal.Body
            className="sub_task_modul_body_div"
            style={{maxHeight: '35rem'}}>
            <p className="sub_task_modul_title_div">{props.title}</p>
            <hr />
            <IoMdClose
              className="sub_task_modul_top_close_btn_div"
              onClick={handleClose}
            />
            {/* ui */}
            {props.modalUi}
            {/* ui end */}
          </Modal.Body>
          <Modal.Footer>
            <div className="mt-3 d-flex align-items-center justify-content-end approval_modal_btn">
              <div className="me-2">
                <OutlineBtn onClick={handleClose} name="Cancel" />
              </div>
              <div className=" ms-2">
                <a
                  className="management_btn_full2 sub_task_modul_download_btn"
                  href={props.downloadItem}
                  download>
                  download
                </a>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
  const NewSubTasks = () => {
    //? -->States<--
    const [page, setPage] = useState(1);
    const [subTasks, setSubTasks] = useState(approval?.ApprovalSubTasks || []);
    const [selectedFile, setSelectedFile] = useState(null);
    const [checkboxSwitch, setShowCheckboxSwitch] = useState(false);
    const [feedbackShow, setFeedbackShow] = useState(false);

    //? -->Functions<--
    const handlePagination = (e, page) => {
      setPage(page);
    };

    //? -->Components<--
    const ModalImgView = props => {
      const [open, setOpen] = React.useState(false);
      const [size, setSize] = React.useState();
      const handleOpen = value => {
        setSize(value);
        setOpen(true);
      };
      const handleClose = () => setOpen(false);

      return (
        <>
          <ButtonToolbar>
            <span
              className="modal_img_view_btn"
              size="lg"
              onClick={() => handleOpen('lg')}>
              <FaRegEye />
            </span>
          </ButtonToolbar>
          <Modal size={size} open={open} onClose={handleClose}>
            <Modal.Body
              className="sub_task_modul_body_div"
              style={{maxHeight: '35rem'}}>
              <p className="sub_task_modul_title_div">{props.title}</p>
              <hr />
              <IoMdClose
                className="sub_task_modul_top_close_btn_div"
                onClick={handleClose}
              />
              {/* ui */}
              {props.modalUi}
              {/* ui end */}
            </Modal.Body>
            <Modal.Footer>
              <div className="mt-3 d-flex align-items-center justify-content-end approval_modal_btn">
                <div className="me-2">
                  <OutlineBtn onClick={handleClose} name="Cancel" />
                </div>
                <div className=" ms-2">
                  <a
                    className="management_btn_full2 sub_task_modul_download_btn"
                    href={props.downloadItem}
                    download>
                    download
                  </a>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      );
    };
    const FeedbackModal = props => {
      return (
        <Modall
          show={feedbackShow}
          onHide={() => setFeedbackShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Body className="modal_body_new">
            <div>
              <p className="sub_task_modul_title_div">Feedback?</p>
              <hr />
              <IoMdClose className="sub_task_modul_top_close_btn_div" />
              {/* ui */}
              <div className="mt-3">
                <TextField
                  id="fullWidth"
                  label="Comments here"
                  placeholder="Write your comments here"
                  multiline
                  fullWidth
                />
              </div>
              <div className="add_new_subtask_Uploaded_mai_div">
                <div className="item">
                  <div className="add_new_subtask_Uploaded_img_div">
                    <img src={DemoImg} alt="Uploaded photo" />
                    <p>
                      {'Get a specific image by adding'.length > 15
                        ? 'Get a specific image by adding'?.substr(0, 15) +
                          '...'
                        : 'Get a specific image by adding'}
                    </p>

                    <span className="close_btn">
                      <IoClose />
                    </span>

                    <div className="add_new_subtask_Uploaded_img_div_overlay">
                      <div className="modal_img_view_btn_div">
                        <a
                          className="modal_img_view_btn me-1"
                          href={DemoImg}
                          download>
                          <MdFileDownload />
                        </a>
                        <ModalImgView
                          title="photo demo title"
                          downloadItem={DemoImg}
                          modalUi={
                            <>
                              <img
                                className="sub_task_modul_img_div"
                                src={DemoImg}
                                alt="img"
                              />
                            </>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="add_new_subtask_Uploaded_img_div">
                    <img
                      src="https://img.freepik.com/premium-vector/open-folder-icon-with-pdf-file-inside-folder-with-documents-blue-background-flat-design-vector-illustration_476325-459.jpg?w=1060"
                      alt="Uploaded photo"
                    />
                    <p>
                      {'pdf title'.length > 15
                        ? 'pdf title'?.substr(0, 15) + '...'
                        : 'pdf title'}
                    </p>

                    <span className="close_btn">
                      <IoClose />
                    </span>

                    <div className="add_new_subtask_Uploaded_img_div_overlay">
                      <div className="modal_img_view_btn_div">
                        <a
                          className="modal_img_view_btn me-1"
                          href={DemoImg}
                          download>
                          <MdFileDownload />
                        </a>
                        <ModalImgView
                          title="pdf demo title"
                          downloadItem={DemoImg}
                          modalUi={
                            <>
                              <object
                                data={DemoPdf}
                                style={{
                                  width: '100%',
                                  height: '35rem',
                                }}></object>
                            </>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {selectedFile && (
                  <div className="item">
                    <div className="add_new_subtask_Uploaded_img_div">
                      <img
                        src={URL.createObjectURL(selectedFile)}
                        alt="Uploaded photo"
                      />
                      <p>
                        {selectedFile.name.length > 10
                          ? selectedFile.name?.substr(0, 10) + '...'
                          : selectedFile.name}
                      </p>

                      <span className="close_btn">
                        <IoClose />
                      </span>

                      <div className="add_new_subtask_Uploaded_img_div_overlay">
                        <div className="modal_img_view_btn_div">
                          <a
                            className="modal_img_view_btn me-1"
                            href={URL.createObjectURL(selectedFile)}
                            download>
                            <MdFileDownload />
                          </a>
                          <ModalImgView
                            title={selectedFile.name}
                            downloadItem={URL.createObjectURL(selectedFile)}
                            modalUi={
                              <>
                                <img
                                  className="sub_task_modul_img_div"
                                  src={URL.createObjectURL(selectedFile)}
                                  alt="img"
                                />
                              </>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="add_new_subtask_top_ui_btn_div">
                <div className="task_detail_main_btn_div">
                  <input
                    type="file"
                    // onChange={handleFileChange}
                    id="subTaskFile2"
                    hidden
                  />
                  <BootstrapTooltip title="Attachment" placement="top">
                    <label htmlFor="subTaskFile2">
                      <IoMdAttach />
                    </label>
                  </BootstrapTooltip>
                </div>
                <Button
                  onClick={() => {
                    setFeedbackShow(false);
                  }}
                  className="btn1"
                  variant="outlined">
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setFeedbackShow(false);
                  }}
                  className="btn2"
                  variant="contained">
                  Save
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modall>
      );
    };
    const SubTask = ({subTaskData, idx}) => {
      //? -->States<--
      const [subTask, setSubTask] = useState(subTaskData || {});
      const [comments, setComments] = useState(
        subTask?.ApprovalSubTaskComments || [],
      );
      //? -->Components<--
      const SubTaskComment = ({comment, idx}) => {
        return (
          <div key={idx} style={{marginLeft: '1rem'}}>
            <div className="sub_task_approval_request_details_commants_view_div">
              <img src={CommantIcon} alt="icon" />
              <p className="sub_task_approval_request_details_commants_view">
                {comment?.comment}
              </p>
            </div>
            <Docs data={comment?.ApprovalSubTaskCommentAttachments || []} />
          </div>
        );
      };
      const ModalViewFeedback = () => {
        const [open, setOpen] = React.useState(false);
        const [size, setSize] = React.useState();
        const handleOpen = value => {
          setSize(value);
          setOpen(true);
        };
        const handleClose = () => setOpen(false);

        return (
          <>
            <ButtonToolbar>
              <BootstrapTooltip title="View feedback" placement="top">
                <label
                  size="sm"
                  className="show_btn ms-0"
                  onClick={() => handleOpen('sm')}>
                  <MdOutlineMessage />
                </label>
              </BootstrapTooltip>
            </ButtonToolbar>
            <Modal size={size} open={open} onClose={handleClose}>
              <Modal.Body
                className="sub_task_modul_body_div"
                style={{maxHeight: '35rem'}}>
                <p className="sub_task_modul_title_div">
                  Final Status: {subTask?.status}
                </p>
                <hr />
                {comments?.map((Comment, i) => (
                  <>
                    <IoMdClose
                      className="sub_task_modul_top_close_btn_div"
                      onClick={handleClose}
                    />
                    {/* ui */}
                    <ul className="feedback_modal_ul">
                      <li>
                        <img
                          className="feedback_modal_ul_icon"
                          src={Comment?.User?.avatar}
                          alt={Comment?.User?.name}
                        />
                        <div className="feedback_modal_ul_main_div">
                          <p className="feedback_modal_ul_name mb-2">
                            {Comment?.User?.name}
                            <span>({Comment?.User?.Designation?.name})</span>
                          </p>
                          {Comment?.type == 'STATUS_CHANGE' ? (
                            <span
                              className="feedback_modal_ul_requested"
                              style={{
                                backgroundColor: getStatusBackgroundColor(
                                  Comment?.status,
                                ),
                                borderColor: getStatusBorderColor(
                                  Comment?.status,
                                ),
                              }}>
                              {Comment?.status}
                            </span>
                          ) : (
                            <SubTaskComment comment={Comment} idx={i} />
                          )}
                          <p className="feedback_modal_ul_time">
                            <TbCalendarPlus />
                            {moment(Comment?.createdAt).calendar()}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </>
                ))}

                {/* ui end */}
              </Modal.Body>
            </Modal>
          </>
        );
      };
      return (
        <div key={idx} className="sub_task_scroll_new" id={'sub_task_' + idx}>
          <div className="row m-0 ">
            {/* item view */}
            <div className="col-12 p-0">
              <div className="row m-0 task_detail_top_main_div">
                <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-8 col-sm-8 col-8 p-0 p-0">
                  <div className="task_detail_top_mani_div">
                    <div className="task_detail_chakbox">
                      {/* <FormControlLabel
                        control={<Checkbox onChange={setShowCheckboxSwitch} />}
                        className="my_checkbox"
                      /> */}
                      {/* <img
                        className="task_icon my_checkbox_icon"
                        src={SubTaskIcon}
                        alt="icon"
                      /> */}
                      <img
                        className="task_icon"
                        src={getSubTaskIcon(subTask?.status)}
                        alt="icon"
                      />
                    </div>
                    <div className="task_detail_top_inner_div">
                      <div className="row m-0">
                        <div className="col-6 p-0 task_detail_text_div_top_col">
                          <div className="task_detail_text_div ms-0">
                            <div>
                              <p>{subTask?.SubTask?.name}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 p-0 ">
                          <div className="task_detail_main_btn_div task_date_div_rel">
                            <div className="task_date_div_rel2">
                              <ModalViewFeedback />
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-6 p-0 ">
                          <div className=" task_date_div_rel">
                            <div className="task_date_div_rel2 task_detail_main_btn_div">
                              <BootstrapTooltip
                                title="View feedback"
                                placement="top">
                                <label className="show_btn">
                                  <MdOutlineMessage />
                                </label>
                              </BootstrapTooltip>
                            </div>
                          </div>
                        </div> */}
                        <div className="col-12 p-0">
                          <Docs
                            data={subTask?.SubTask?.SubTaskAttachments || []}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2 task_date_div">
                  <p>{moment(subTask?.SubTask?.start_date).calendar()}</p>
                </div>
                <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2 task_date_div">
                  <p>{moment(subTask?.SubTask?.end_date).calendar()}</p>
                </div>
              </div>
            </div>
            {/* item view end */}
          </div>
        </div>
      );
    };
    return (
      <>
        <FeedbackModal />
        <div className="row ">
          <div className="col-12">
            <div className="row mt-0 g-3">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-lg-8 col-md-8 col-sm-12 col-12 d-flex align-items-center">
                <div className="add_new_subtask_menu_div_top">
                  <span className="add_new_subtask_ui_title mt-0 me-3">
                    Subtasks
                  </span>
                </div>
              </div>
              {checkboxSwitch ? (
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-lg-4 col-md-4 col-sm-12 approval_request_details_subtask_top_btn_div">
                  <label
                    style={{
                      backgroundColor: 'rgb(26 127 221 / 10%)',
                      border: '1px solid #1A7FDD',
                    }}
                    onClick={() => {
                      setFeedbackShow(true);
                    }}>
                    <MdOutlineMessage style={{color: '#1A7FDD'}} />
                    Feedback?
                  </label>
                  <label
                    style={{
                      backgroundColor: 'rgb(57 194 34 / 10%)',
                      border: '1px solid #39C222',
                    }}
                    onClick={() => {
                      Confirm.show(
                        `Approve?`,
                        `This action is undonable!`,
                        'Cancel',
                        'Approve',
                        () => {},
                        () => {},
                      );
                    }}>
                    <MdOutlineApproval style={{color: '#3BA317'}} /> Approve
                  </label>
                  <label
                    style={{
                      backgroundColor: '#rgb(255 62 72 / 10%)',
                      border: '1px solid #FF3E48',
                    }}
                    onClick={() => {
                      Confirm.show(
                        `Reject?`,
                        `This action is undonable!`,
                        'Cancel',
                        'Reject',
                        () => {},
                        () => {},
                      );
                    }}>
                    <IoIosCloseCircleOutline style={{color: '#FF3E48'}} />
                    Reject
                  </label>
                </div>
              ) : null}
            </div>
            {/*subtask data view ui */}
            <div className="task_scrol_div1">
              <div className="add_new_subtask_top_ui_div p-0 task_scrol_div2">
                {/* item header */}
                <div className="row">
                  <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-sm-8 col-8">
                    <div className=" new_task_detail_top_title_one">
                      {/* <FormControlLabel
                        control={<Checkbox onChange={setShowCheckboxSwitch} />}
                      /> */}
                      <span style={{marginLeft: 50}} className="small">
                        Subtask Name
                      </span>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="new_task_detail_top_title_two">
                      <span className="small">Start date</span>
                    </div>
                  </div>
                  <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2">
                    <div className="new_task_detail_top_title_two task_date_div_rel_new">
                      <span className="small">End date</span>
                    </div>
                  </div>
                </div>
                {/* item header end */}
                {subTasks
                  ?.slice((page - 1) * 5, 5 * page)
                  ?.map((subTask, i) => (
                    <SubTask subTaskData={subTask} idx={i} />
                  ))}
              </div>
              {subTasks?.length > 5 ? (
                <Stack spacing={2} className=" align-items-end mt-2">
                  <Pagination
                    onChange={handlePagination}
                    count={Math.ceil(subTasks?.length / 5)}
                  />
                </Stack>
              ) : null}
            </div>
            {/*subtask data view ui end */}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="custom_container approval_body">
      <PageAllTopTitle link={-1} icon={<BsArrowLeft />} title="Approvals" />
      <div className="row g-xxl-3 g-xl-3 g-lg-3 g-md-3 g-sm-3 g-3">
        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12">
          <div className="dashbaord_white_card send_list_page_detail_div">
            <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
              <div className="col-12">
                <h3 className="my_title">{approval?.title}</h3>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="sub_title_div">
                  <p>Date</p>
                  <div>{moment(approval?.createdAt).calendar()}</div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="sub_title_div">
                  <p>Approval requested by</p>
                  <div>{approval?.RequestedUser?.name}</div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="sub_title_div">
                  <p>Project</p>
                  <div>{approval?.Project?.name || 'N/A'}</div>
                </div>
              </div>
              <div className="col-12">
                <div className="sub_title_div" onClick={handleOpenTaskDetail}>
                  <p>Task</p>
                  <div className="link-primary" style={{cursor: 'pointer'}}>
                    {Task?.name}
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="sub_title_div">
                  <p>Task start date</p>
                  <div>{moment(Task?.start_date).calendar()}</div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="sub_title_div">
                  <p>Task end date</p>
                  <div>{moment(Task?.end_date).calendar()}</div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="sub_title_div">
                  <p>Priority</p>
                  <span
                    style={{
                      border: approval?.Priority?.bg_color,
                      backgroundColor: approval?.Priority?.bg_color,
                    }}>
                    {approval?.Priority?.name}
                  </span>
                </div>
              </div>
              {/* {approval?.ImpactData.map((val, i) => {
                  return (
                    <div
                      key={i}
                      className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                      <div className="sub_title_div">
                        <p>Impact</p>
                        <span
                          style={{
                            border: approval?.borderColor,
                            backgroundColor: approval?.backgroundColor,
                          }}>
                          {approval?.PriorityTitle}
                        </span>
                      </div>
                    </div>
                  );
                })}
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                  <div className="sub_title_div">
                    <p>Impact category</p>
                    <div>{approval?.ImpactCategory}</div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                  <div className="sub_title_div">
                    <p>Impact sub category</p>
                    <div>
                      {approval?.ImpactSubCategory.length > 15
                        ? `${approval?.ImpactSubCategory.substring(0, 15)}...`
                        : approval?.ImpactSubCategory}
                      <BootstrapTooltip
                        title={approval?.ImpactSubCategory}
                        placement="top">
                        <label>
                          <FaInfoCircle />
                        </label>
                      </BootstrapTooltip>
                    </div>
                  </div>
                </div> */}
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="sub_title_div">
                  <p>Task created by</p>
                  <Avatar
                    alt={Task?.User?.name}
                    src={Task?.User?.avatar}
                    sx={{width: 24, height: 24}}
                  />
                </div>
              </div>
              {approval?.TaskOwner ? (
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                  <div className="sub_title_div">
                    <p>Task owner</p>
                    <Avatar
                      alt={approval?.TaskOwner?.name}
                      src={approval?.TaskOwner?.avatar}
                      sx={{width: 24, height: 24}}
                    />
                  </div>
                </div>
              ) : null}
              {approval?.Task ? (
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                  <div className="sub_title_div">
                    <p>Task assign to</p>
                    <div className="Participants_main_div_inner">
                      <UserParticipants data={Task?.TaskMembers || []} />
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="col-12">
                <div className="sub_title_div">
                  <p>Description</p>
                  <div>{ReactHtmlParser(approval?.desc)}</div>
                </div>
              </div>
              <div className="col-12">
                <div className="sub_title_div">
                  <p>Attachments</p>
                  <Docs data={approval?.ApprovalAttachments || []} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ApprovalUsersTimeline />
        {approval?.ApprovalSubTasks?.length ? (
          <div className="col-12">
            <div className="dashbaord_white_card">
              <Accordionn className="approval_modal_sub_title_Description_top_div2 mt-0">
                <div className="approval_modal_sub_title_Description_div">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon className="pm_icon" />}
                    aria-controls="panel1-content"
                    id="panel1-header"></AccordionSummary>
                  <div className="ms-1 approval_modal_sub_title_Description_new mt-0 mb-0">
                    Subtasks
                  </div>
                </div>
                <AccordionDetails>
                  <NewSubTasks />
                </AccordionDetails>
              </Accordionn>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
const getFileIcon = (file, crateImageObject = false) => {
  switch (file.type) {
    case 'image/jpeg':
      return crateImageObject ? URL.createObjectURL(file) : file.url;
    case 'image/png':
      return crateImageObject ? URL.createObjectURL(file) : file.url;
    case 'application/pdf':
      return CommentsPdfIcon;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return CommentsPdfIcon2;
    case 'application/ppx':
      return CommentsPdfIcon3;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return CommentsPdfIcon4;
    case 'application/zip':
      return CommentsPdfIcon5;
    default:
      return CommentsPdfIcon5;
  }
};
const getStatusBackgroundColor = status => {
  switch (status) {
    case 'APPROVED':
      return '#dfe9dc';
    case 'REJECTED':
      return '#ff000073';
    case 'REASSIGNED':
      return '#e8f2fc';
    default:
      return '#e8f2fc';
  }
};
const getStatusBorderColor = status => {
  switch (status) {
    case 'APPROVED':
      return '#3ca318';
    case 'REJECTED':
      return '#ff000073';
    case 'REASSIGNED':
      return '#1a7fdd';
    default:
      return '#1a7fdd';
  }
};
const getSubTaskIcon = status => {
  switch (status) {
    case 'PENDING':
      return RequestedIcon;
    case 'APPROVED':
      return Approvedicon;
    case 'REJECTED':
      return Rejectedicon;
    default:
      return TaskIcon;
  }
};
