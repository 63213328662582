import './AddNotesIndex.scss';
import React, {useState, useRef, useEffect} from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import {
  OnlyIconButton,
  TextButton,
} from '../../../../../common/NewComponents/NewButtons/Buttons';
import {
  CopyIcon,
  InfoIcon,
  PencilIcon,
  PersonAddIcon,
} from '@primer/octicons-react';
import {useDispatch, useSelector} from 'react-redux';
import {openAssignToModal} from '../../../../../../redux/slices/AssignToModalSlice';
import {participants, QuickNoteAssignUsers} from '../../Data';
import {UserParticipants} from '../../../../../common/ManagementComponents/Participants';
import {Block, Confirm, Notify} from 'notiflix';
import {Pagination} from '@mui/material';
import {FaAngleDown, FaExclamation} from 'react-icons/fa';
import {BsThreeDots} from 'react-icons/bs';
import {GoClock, GoTasklist} from 'react-icons/go';
import {IoVideocamOutline} from 'react-icons/io5';
import {IoMdCheckmark} from 'react-icons/io';
import {MdOutlineDelete} from 'react-icons/md';
import Dropdown from 'rsuite/Dropdown';
import {AssignToModalComponent} from '../../../../../common/NewComponents/AssignToModalComponent/AssignToModalComponent';
import Axios from './../../../../../../config/api';
import moment from 'moment';
import {getUser} from '../../../../../../redux/slices/userSlice';
//
export const AddNotesIndex = ({notes, collectionId}) => {
  const dispatch = useDispatch();
  const USER = useSelector(getUser);
  const h3Ref = useRef(null); // Reference to the h3 element
  const [Notes, setNotes] = useState(notes || []);
  const [selectValues, setSelectValues] = useState({
    users: [],
    priorities: [],
  });

  useEffect(() => {
    getAllUsers();
    fetchPriorities();
  }, []);

  useEffect(() => {
    setNotes(notes);
  }, [notes]);

  const getAllUsers = () => {
    Axios.fetch(`maxproject/random/members`)
      .then(({data}) => {
        setSelectValues(old => ({
          ...old,
          users: data?.members?.map(User => ({
            value: User?.id,
            label: User?.name,
          })),
        }));
      })
      .catch(err => {
        console.log(err);
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchPriorities = () => {
    Axios.fetch(`maxproject/quick_note/priorities`)
      .then(({data}) => {
        setSelectValues(old => ({
          ...old,
          priorities: data?.priorities,
        }));
      })
      .catch(err => {
        console.log(err);
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };

  const copyText = () => {
    const h3Text = h3Ref.current.innerText;
    navigator.clipboard.writeText(h3Text);
  };

  const handleOpenModal = () => dispatch(openAssignToModal());

  const NoteCard = ({note}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [priority, selectPriority] = useState(note?.PriorityMaster);
    const [editorData, setEditorData] = useState(note?.content);
    const [selectedUsers, setSelectedUsers] = useState(
      note?.RichNoteParticipants?.map(User => ({
        value: User?.user_id,
        label: User?.User?.name,
      })),
    );
    const [showModal, setShowModal] = useState(false);

    const isCompleted = note?.RichNoteParticipants?.some(
      User => User?.isCompleted && User?.user_id === USER?.id,
    );

    const onUsersChange = data => {
      setSelectedUsers(data);
    };

    const handleUpdatePriority = Priority => {
      selectPriority(Priority);
      updatePriority(Priority);
    };

    const updatePriority = Priority => {
      //intializing network request
      Axios.put(`maxproject/quick_note/sub_note/priority/${note.id}`, {
        priority_id: Priority?.id,
      }).then(res => {
        Notify.success('Priority updated successfully');
      });
    };

    const PriorityMenu = () => {
      return (
        <div className="MobileMenuMain_div PriorityMenu_div">
          <Dropdown
            title={
              <>
                <FaExclamation
                  className="icon_1"
                  style={{
                    color: priority?.color,
                  }}
                />
                <FaAngleDown
                  className="icon_2"
                  style={{
                    color: priority?.color,
                  }}
                />
              </>
            }
            menuStyle={{minWidth: 120}}>
            {selectValues.priorities.map((Priority, i) => (
              <Dropdown.Item
                key={i}
                className="MobileMenuMainItem_div"
                onClick={() => handleUpdatePriority(Priority)}>
                <span className="icon_div">
                  <FaExclamation style={{color: Priority?.bg_color}} />
                </span>
                <span style={{color: Priority?.bg_color}}>
                  {Priority?.name}
                </span>
              </Dropdown.Item>
            ))}
          </Dropdown>
        </div>
      );
    };

    const Note = () => (
      <div className="New_ckEditer_dataView_div">
        <div className="dataDiv">
          <div className="data_show" ref={h3Ref}>
            <div dangerouslySetInnerHTML={{__html: editorData}} />
          </div>
          <div className="dataDiv_inner">
            {priority ? (
              <p className="Priority_show" style={{color: priority?.bg_color}}>
                <span>
                  <FaExclamation style={{color: priority?.bg_color}} />
                </span>
                {priority?.name}
              </p>
            ) : null}
            <div>
              <div className="Participants_main_div_inner">
                <UserParticipants
                  data={note?.RichNoteParticipants}
                  showMax={3}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="New_ckEditer_InnerBtnDiv">
          <OnlyIconButton
            onClick={() => setIsEditing(true)}
            icon={<PencilIcon />}
            marginRight="4px"
            Tooltip="Edit"
            Tooltipplacement="bottom"
          />
          <OnlyIconButton
            onClick={copyText}
            icon={<CopyIcon />}
            marginRight="4px"
            Tooltip="Copy"
            Tooltipplacement="bottom"
          />
          <PriorityMenu />
          <OnlyIconButton
            onClick={handleOpenModal}
            icon={<PersonAddIcon />}
            marginRight="4px"
            Tooltip="Add participants"
            Tooltipplacement="bottom"
          />

          <OnlyIconButton
            icon={<InfoIcon />}
            marginRight="4px"
            marginLeft="10px"
            Tooltip={
              <>
                <div>
                  Created by: <br />
                  {note?.User?.name}
                </div>
                <div className="mt-2">
                  Create on: <br /> {moment(note?.createdAt).format('lll')}
                </div>
              </>
            }
            Tooltipplacement="bottom"
          />
          <BigMenu Note={note} setNotes={setNotes} isCompleted={isCompleted} />
        </div>
      </div>
    );
    const EditNote = () => {
      const handleUpdateNote = (content, cbUpdateState) => {
        // Update the note in the database
        Block.circle(`#note_${note.id}`, 'Updating note...');
        //initializing network requeset
        Axios.put(`maxproject/quick_note/sub_note/${note.id}`, {
          content,
          users: JSON.stringify(selectedUsers?.map(el => el?.value)),
        }).then(res => {
          // Update the note in the UI
          Block.remove(`#note_${note.id}`);
          Notify.success('Note updated successfully');
          // updating the state
          // Update the note in the UI
          setNotes(old =>
            old.map(Note => (Note.id === note.id ? res.data?.Note : Note)),
          );
          setIsEditing(false);
        });
      };
      return (
        <div className="New_ckEditer_div" id={`note_${note.id}`}>
          <Editor
            selectValues={selectValues}
            editorData={editorData}
            handleOpenModal={() => setShowModal(true)}
            setIsEditing={setIsEditing}
            cbUpdateNote={handleUpdateNote}
          />
        </div>
      );
    };

    return (
      <>
        <AssignToModalComponent
          QuickNoteAssignUsers={selectValues.users}
          selectedUsers={selectedUsers}
          onChange={onUsersChange}
          showModal={showModal}
          setShowModal={setShowModal}
        />
        {isEditing ? <EditNote /> : <Note />}
      </>
    );
  };
  const AddUi = () => {
    const [editorData, setEditorData] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const onUsersChange = data => {
      setSelectedUsers(data);
    };

    const handleSaveNote = (content, cbUpdateState) => {
      // Save the note in the database
      Block.circle('#add-note', 'Saving note...');
      Axios.post('maxproject/quick_note/sub_note/' + collectionId, {
        content,
        users: JSON.stringify(selectedUsers?.map(el => el?.value)),
      }).then(res => {
        // Update the note in the UI
        Block.remove('#add-note');
        Notify.success('Note saved successfully');
        // updating the state
        cbUpdateState('');
        // updating note list
        setNotes([res.data?.Note, ...Notes]);
      });
    };
    return (
      <div className="New_ckEditer_div" id="add-note">
        <AssignToModalComponent
          QuickNoteAssignUsers={selectValues.users}
          selectedUsers={selectedUsers}
          onChange={onUsersChange}
          showModal={showModal}
          setShowModal={setShowModal}
        />
        <Editor
          selectValues={selectValues}
          editorData={editorData}
          handleOpenModal={() => setShowModal(true)}
          cbUpdateNote={handleSaveNote}
        />
      </div>
    );
  };
  return (
    <div>
      <AddUi SaveBtnTitle="save" />
      {Notes?.map((Note, i) => (
        <NoteCard note={Note} />
      ))}
      {/* <div className="New_mui_Pagination">
        <Pagination count={10} />
      </div> */}
    </div>
  );
};

const Editor = ({editorData, setIsEditing, handleOpenModal, cbUpdateNote}) => {
  const editorRef = useRef(null);
  const toolbarId = useRef(
    `toolbar-${Math.random().toString(36).substr(2, 9)}`,
  );
  const [editorState, setEditorState] = useState(editorData);

  const handleEditorChange = (_, editor) => {
    setEditorState(editor.getData());
  };

  const handleUpdateNote = () => {
    cbUpdateNote(editorState, setEditorState);
  };
  const handleEditorReady = editor => {
    editorRef.current = editor;
    const toolbarContainer = document.querySelector(`#${toolbarId.current}`);
    toolbarContainer?.appendChild(editor.ui.view.toolbar.element);
    // When editor is ready, attach keydown event
    editor.editing.view.document.on('keydown', (event, domEventData) => {
      if (domEventData.domEvent.key == 'Enter') {
        if (domEventData.domEvent.shiftKey || domEventData.domEvent.ctrlKey)
          return;
        cbUpdateNote(editor.getData(), setEditorState);
      }
    });
  };

  return (
    <div className="editor-container">
      <div id={toolbarId.current}></div>
      <CKEditor
        editor={DecoupledEditor}
        data={editorState}
        onReady={handleEditorReady}
        onChange={handleEditorChange}
        config={{
          toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'fontFamily',
            'fontSize',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'link',
            'imageUpload',
            'mediaEmbed',
            '|',
            'bulletedList',
            'numberedList',
            'outdent',
            'indent',
            '|',
            'blockQuote',
            'insertTable',
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'codeBlock',
            '|',
            'undo',
            'redo',
            '|',
            'highlight',
            'trackChanges',
            'comment',
            'revisionHistory',
          ],
          image: {
            toolbar: [
              'imageStyle:full',
              'imageStyle:side',
              '|',
              'imageTextAlternative',
            ],
          },
          table: {
            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
          },
          mediaEmbed: {previewsInData: true},
          simpleUpload: {
            uploadUrl: 'https://your-backend.com/upload',
            headers: {Authorization: 'Bearer your-token'},
          },
        }}
      />
      <div className="comments-container"></div>
      <div className="row m-0 New_ckEditer_div_bottom_btn">
        <div className="col-6 p-0 d-flex">
          <OnlyIconButton
            onClick={handleOpenModal}
            icon={<PersonAddIcon />}
            Tooltip="Add participants"
          />
          {/* <div className="Participants_main_div_inner">
            <UserParticipants data={participants} showMax={3} />
          </div> */}
        </div>
        <div className="col-6 p-0 d-flex justify-content-end align-items-center">
          <div className="add_note_Btn_bottom">
            {setIsEditing ? (
              <TextButton title="Close" onClick={() => setIsEditing(false)} />
            ) : null}
            <TextButton
              title={setIsEditing ? 'Update' : 'Save'}
              onClick={handleUpdateNote}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const BigMenu = ({Note, setNotes, isCompleted}) => {
  const handleDelete = () => {
    const proceedDelete = () => {
      //intializing network request
      Axios.delete(`maxproject/quick_note/sub_note/${Note.id}`)
        .then(res => {
          Notify.success('Note deleted successfully');
          setNotes(old => old.filter(n => n.id !== Note.id));
        })
        .catch(err => {
          console.log(err);
          //checking for any error message
          if (err?.response?.data?.message)
            Notify.failure(err?.response?.data?.message);
        });
    };
    Confirm.show(
      'Delete?',
      'This action is undonable!',
      'Delete',
      'Cancel',
      proceedDelete,
      () => {},
    );
  };

  const handleMarkComplete = () => {
    //intializing network request
    Axios.put(
      `maxproject/quick_note/sub_note/mark_unmark_complete_status/${Note.id}`,
    )
      .then(res => {
        Notify.success(res.data?.message);
        //getting new note
        const NewNote = res.data?.NewNote;
        //updating the state
        setNotes(old =>
          old.map(Note => (Note.id === NewNote.id ? NewNote : Note)),
        );
      })
      .catch(err => {
        console.log(err);
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };

  const menuItems = [
    {icon: <GoTasklist />, text: 'Convert to task', disabled: true},
    {icon: <GoClock />, text: 'Create reminder', disabled: true},
    {icon: <IoVideocamOutline />, text: 'Schedule a meeting', disabled: true},
    {
      icon: <IoMdCheckmark />,
      text: `${isCompleted ? 'Unmark' : 'Mark'} complete`,
      onClick: handleMarkComplete,
    },
    {
      icon: <MdOutlineDelete />,
      text: 'Delete',
      onClick: handleDelete,
      classNameDelete: 'ckDeletBtnColor',
    },
  ];

  return (
    <div className="MobileMenuMain_div">
      <Dropdown title={<BsThreeDots />} menuStyle={{minWidth: 120}}>
        {menuItems.map(
          ({icon, text, classNameDelete, onClick, disabled = false}, i) => (
            <Dropdown.Item
              disabled={disabled}
              key={i}
              className="MobileMenuMainItem_div"
              onClick={onClick}>
              <span
                className={`icon_div ${classNameDelete || classNameDelete}`}>
                {icon}
              </span>
              <span className={classNameDelete}>{text}</span>
            </Dropdown.Item>
          ),
        )}
      </Dropdown>
    </div>
  );
};
