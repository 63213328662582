import React, {useState, useEffect} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import {useDispatch, useSelector} from 'react-redux';
import {MdOutlineDarkMode, MdOutlineLightMode} from 'react-icons/md';
import {HiOutlineComputerDesktop} from 'react-icons/hi2';
import {
  isDark,
  isLight,
  isSystem,
  updateSystemMode,
} from '../../../../redux/slices/globalSlice';

const ModeIcon = ({icon}) => <span className="mode_nav_icon">{icon}</span>;

const getAvatarContent = mode => {
  const modeIcons = {
    dark: <MdOutlineDarkMode />,
    light: <MdOutlineLightMode />,
    system: <HiOutlineComputerDesktop />,
  };
  return <ModeIcon icon={modeIcons[mode] || <MdOutlineLightMode />} />;
};

export const DarkModeMenu = () => {
  const dispatch = useDispatch();
  const {isMode, systemMode} = useSelector(state => state.global);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (systemMode === 'system') {
      const handleSystemChange = () => {
        dispatch(updateSystemMode());
      };
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      mediaQuery.addEventListener('change', handleSystemChange);
      handleSystemChange(); // Set initial system mode
      return () => mediaQuery.removeEventListener('change', handleSystemChange);
    }
  }, [dispatch, systemMode]);

  return (
    <div className="timesheet_menu_div mode_nav_inner_icon">
      <IconButton
        className="top_nav_left_icon"
        id="basic-button"
        onClick={handleClick}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}>
        {getAvatarContent(systemMode)}
      </IconButton>
      <Menu
        data-theme={isMode}
        className="timesheet_menu_inner_add_link_div mode_nav_inner_menu"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {['light', 'dark', 'system'].map((mode, index) => (
          <MenuItem
            key={mode}
            onClick={() => {
              if (mode == 'light') dispatch(isLight());
              else if (mode == 'dark') dispatch(isDark());
              else dispatch(isSystem());

              handleClose();
            }}>
            <span className="timesheet_menu_inner_icon">
              {mode == 'light' && <MdOutlineLightMode />}
              {mode == 'dark' && <MdOutlineDarkMode />}
              {mode == 'system' && <HiOutlineComputerDesktop />}
            </span>
            <p className="timesheet_menu_inner_text">{mode}</p>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
