import React from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {useState, useEffect} from 'react';
import Calendar from 'react-calendar';
import {BsFillCircleFill} from 'react-icons/bs';
import {Block} from 'notiflix/build/notiflix-block-aio';

import {OutlineBtn} from '../../../../common/ManagementComponents/Btn/Btn';
import {WelcomeNote} from '../../../../common/ManagementComponents/WelcomeNote/WelcomeNote';
import Img from '../../../../common/components/img/Img';
import Icon1 from '../../../../../pics/Management/d1.png';
import Icon2 from '../../../../../pics/Management/d2.png';
import Icon3 from '../../../../../pics/Management/d4.png';
import Icon4 from '../../../../../pics/Management/d3.png';
import {PageInnerTitle} from '../../../../common/ManagementComponents/pageInnerTitle/Title';
import {ExistingClientModal} from '../Dashboard/ExistingClientModal';

import {useDispatch, useSelector} from 'react-redux';
import {getUser} from './../../../../../redux/slices/userSlice';
import Axios from './../../../../../config/api';
import moment from 'moment';
import {getRandomFromArray} from '../../../../../constants/helpers';
//
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import {AiFillCaretDown} from 'react-icons/ai';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import {setGlobal} from '../../../../../redux/slices/globalSlice';

const getMeetingTime = ({date, start_time, isAllDay}) =>
  Number(isAllDay)
    ? moment(date, 'YYYY-MM-DD').calendar(moment(), {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'YYYY-MM-DD',
      })
    : // Combine date and time if it's not an all day event
      moment(`${date}T${start_time}`).calendar();
//
export const Index = () => {
  const user = useSelector(getUser);
  return (
    <>
      <WelcomeNote name={user.name} text="Let's have a productive day! " />

      <ProjectSummary />
      <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
          <ProjectList />
        </div>
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <CalendarApp />
            </div>
          </div>
        </div>
        <div className="col-xxl-6 xol-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <TodayMeetings />
        </div>
        <div className="col-xxl-6 xol-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <PendingTasks />
        </div>
      </div>
    </>
  );
};
const ProjectSummary = () => {
  const [summary, setSummary] = useState({
    tasks: 0,
    meetings: 0,
    newProjects: 0,
    approvalRequests: 0,
  });

  useEffect(() => {
    Block.dots('.project_summary_card');
    getSummaryData();
    return () => {};
  }, []);

  const getSummaryData = () => {
    Axios.fetch('maxproject/dashboard/summary')
      .then(({data}) => {
        Block.remove('.project_summary_card');
        setSummary(data.summary);
      })
      .catch(err => {
        Block.remove('.project_summary_card');
      });
  };
  const Card = props => {
    return (
      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
        <NavLink to={props.link}>
          <div
            className="project_summary_card"
            // style={{backgroundColor: props.backgroundColor}}
            style={{
              backgroundColor: 'white',
            }}>
            <div className="row">
              <div className="col-12 position-relative">
                <Img
                  backgroundImage={props.icon}
                  className="project_summary_card_icon"
                />
                <span className="project_summary_card_icon_dot">
                  <BsFillCircleFill style={{color: props.DotColor}} />
                </span>
                <div>
                  <p
                    //style={{color: props.color}}
                    style={{color: 'black'}}>
                    <span>{props.number}</span>
                    {props.text}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </NavLink>
      </div>
    );
  };

  return (
    <div style={{marginBottom: '1.5rem'}}>
      <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
        <Card
          link="/Management/project_list"
          backgroundColor="#393939"
          icon={Icon1}
          color="white"
          number={summary.newProjects}
          text="New Projects"
          DotColor="white"
        />
        <Card
          link="/Management/meetings"
          backgroundColor="#0A0E30"
          icon={Icon2}
          color="white"
          number={summary.meetings}
          text="Meetings"
          DotColor="#DA1E28"
        />
        <Card
          link="/Management/tasks"
          backgroundColor="#DA1E28"
          icon={Icon3}
          color="white"
          number={summary.tasks}
          text="Pending tasks"
          DotColor="white"
        />
        <Card
          link="/Management/approvals"
          backgroundColor="#F1C21B"
          icon={Icon4}
          color="white"
          number={summary.approvalRequests}
          text="Approval Requests"
          DotColor="#DA1E28"
        />
      </div>
    </div>
  );
};

const ProjectList = () => {
  const [ProjectListData, setProjectListData] = useState([]);
  const [clients, setClients] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    Block.dots('.projects_card');
    getProjectsData();
    getClients();
    return () => {};
  }, []);

  const getProjectsData = () => {
    Axios.fetch('maxproject/dashboard/projects')
      .then(({data}) => {
        Block.remove('.projects_card');
        setProjectListData(data.projects);
      })
      .catch(err => {
        Block.remove('.projects_card');
      });
  };

  const getClients = () => {
    Axios.fetch('maxproject/client/select_list').then(({data}) => {
      let clientsList = data.clients.map(client => ({
        value: client.id,
        label: client.client_name,
      }));
      setClients(clientsList);
    });
  };

  const processSelectedClient = client => {
    if (client) navigate('/Management/add_project/' + client);
    else navigate('/Management/add_project');
  };

  const NoDataView = () => {
    return (
      <div className="row">
        <div className="col-12">
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '3rem',
            }}>
            <Img
              backgroundImage={require('../../../../../pics/no_projects.png')}
              style={{height: 100, width: 100}}
              className="project_summary_card_icon"
            />
            <span className="mb-3">
              {getRandomFromArray([
                'You have completed all your pending projects so sit back and relax!',
                'No pending projects! Grab yourself a snack as a reward',
                'You have no upcoming projects',
              ])}
            </span>
            <ExistingClientModal
              data={clients}
              placeholder="Select Client"
              onClick={processSelectedClient}
            />
          </div>
        </div>
      </div>
    );
  };

  const ProjectRow = props => {
    return (
      <tr>
        <td className="project_list_name project_list_name_new">
          <Tooltip title="Project Name" placement="bottom-start" arrow>
            <NavLink
              className="link_a"
              to={'/Management/project_details/' + props.id}>
              {props.name}
            </NavLink>
          </Tooltip>
        </td>
        <td>
          <div
            className="project_list_priority_div"
            style={{backgroundColor: props?.Priority?.bg_color}}>
            <span className="project_list_priority">
              {props?.Priority?.name}
            </span>
          </div>
        </td>
        <td>
          <div className="project_list_progress_td">
            <div className="project_list_progress">
              <div className=" text-end mb-1">
                <span>{props.progress}%</span>
              </div>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: props.progress + '%',
                  }}
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"></div>
              </div>
            </div>
            <div className="project_list_progress_td_link">
              <NavLink to={'/Management/project_details/' + props.id}>
                View
              </NavLink>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div className="dashbaord_white_card dashbaord_scroll projects_card">
      <div className="row">
        <div className="col-6">
          <PageInnerTitle name="Projects" />
        </div>
        <div className="col-6 project_List_view_all_link">
          <NavLink to="/Management/project_list">View All</NavLink>
        </div>
      </div>
      {ProjectListData?.length ? (
        <div className="dashbaord_scroll_new">
          <table className="table table-hover project_tabel table-borderless text-capitalize">
            <thead>
              <tr className="project_list_title">
                <th scope="col">Project Name</th>
                <th scope="col">Priority</th>
                <th scope="col">Progress</th>
              </tr>
            </thead>
            <tbody className="project_list_name_tr">
              {ProjectListData.map((val, i) => (
                <ProjectRow key={i} {...val} />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <NoDataView />
      )}
    </div>
  );
};
const TodayMeetings = () => {
  const [meetings, setMeetings] = useState([]);
  useEffect(() => {
    Block.dots('.meetings_card');
    getMeetingsData();
    return () => {};
  }, []);

  const getMeetingsData = () => {
    Axios.fetch('maxproject/dashboard/meetings')
      .then(({data}) => {
        Block.remove('.meetings_card');
        setMeetings(data.meetings);
      })
      .catch(err => {
        Block.remove('.meetings_card');
      });
  };
  const NoDataView = () => {
    return (
      <div className="row">
        <div className="col-12">
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '2rem',
            }}>
            <Img
              backgroundImage={require('../../../../../pics/no_meetings.png')}
              style={{height: 100, width: 100}}
              className="project_summary_card_icon"
            />
            <span>
              {getRandomFromArray([
                "You're all done for today!",
                "You've completed all the meetings for today!",
                "Let's put those ideas into action!",
              ])}
            </span>
            <NavLink
              to={'/Management/schedule_meeting'}
              className="management_btn_full mt-3">
              Schedule Meeting
            </NavLink>
          </div>
        </div>
      </div>
    );
  };
  const Meeting = props => {
    return (
      <div className="row project_list_name_top_div ">
        <div className=" col-10 ">
          <div
            style={{borderColor: '#F1C21B'}}
            className="project_list_name project_list_name_boder">
            <h3>{getMeetingTime(props)}</h3>
            {props.title}
            <p>
              Lead by <span>{props?.User?.name}</span>
            </p>
          </div>
        </div>
        <div className="col-2">
          <div className="today_meetings_btn_div">
            <OutlineBtn
              onClick={() => {
                window.open(props.link, '_blank');
              }}
              name="Join"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="dashbaord_white_card dashbaord_scroll ">
      <div className="row">
        <div className="col-12">
          <PageInnerTitle name="Today's Meetings" />
        </div>
      </div>

      {meetings?.length ? (
        meetings.map((val, i) => {
          return <Meeting key={i} {...val} />;
        })
      ) : (
        <NoDataView />
      )}
    </div>
  );
};
const PendingTasks = () => {
  const [tasks, setTasks] = useState([]);
  useEffect(() => {
    Block.dots('.tasks_card');
    getTasksData();
    return () => {};
  }, []);

  const getTasksData = () => {
    Axios.fetch('maxproject/dashboard/tasks')
      .then(({data}) => {
        Block.remove('.tasks_card');
        setTasks(data.tasks);
      })
      .catch(err => {
        Block.remove('.tasks_card');
      });
  };

  const NoDataView = () => {
    return (
      <div className="row">
        <div className="col-12">
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '2rem',
            }}>
            <Img
              backgroundImage={require('../../../../../pics/no_tasks.png')}
              style={{height: 100, width: 100}}
              className="project_summary_card_icon"
            />
            <span>
              {getRandomFromArray([
                "Great work! You've completed all your pending tasks for today ",
                "Good job! You've successfully completed all your tasks!",
              ])}
            </span>
            <NavLink
              to={'/Management/create_task'}
              className="management_btn_full mt-3">
              Create New Task
            </NavLink>
          </div>
        </div>
      </div>
    );
  };
  const Task = props => {
    const dispatch = useDispatch();
    return (
      <tr
        style={{cursor: 'pointer'}}
        onClick={() =>
          dispatch(setGlobal({taskId: props?.id, showTask: true}))
        }>
        <td className="project_list_name">{props?.name}</td>
        <td>
          <div
            className="project_list_priority_div"
            style={{
              backgroundColor: props?.Priority?.bg_color,
            }}>
            <span
              className="project_list_priority"
              style={{color: props?.Priority?.text_color}}>
              {props?.Priority?.name}
            </span>
          </div>
        </td>
        <td>
          <div>View</div>
        </td>
      </tr>
    );
  };

  return (
    <div className="dashbaord_white_card dashbaord_scroll tasks_card">
      <div className="row">
        <div className="col-6">
          <PageInnerTitle name="Pending Tasks" />
        </div>
        <div className="col-6 project_List_view_all_link">
          <NavLink to="/Management/tasks">View All</NavLink>
        </div>
      </div>
      {tasks?.length ? (
        <div className="dashbaord_scroll_new">
          <table className="table table-hover project_tabel table-borderless text-capitalize">
            <thead>
              <tr className="project_list_title">
                <th scope="col">Task Name</th>
                <th scope="col">Priority</th>
              </tr>
            </thead>
            <tbody className="project_list_name_tr">
              {tasks.map((val, i) => (
                <Task key={i} {...val} />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <NoDataView />
      )}
    </div>
  );
};
const CalendarApp = () => {
  const [value, onChangee] = useState(new Date());
  return (
    <div className="row">
      <div className="col-12">
        <div className="dashbaord_white_card dashbaord_scroll_cla">
          <div className="dashbaord_white_title">
            <div>
              <Calendar
                className="dashbaord_calendar"
                onChange={date => {
                  onChangee(date);
                }}
                value={value}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
