import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import Axios from './../../config/api.js';
import {createActivityPayload} from './../../utils/functions.js';
import moment from 'moment';

export const fetchUserActivities = createAsyncThunk(
  'userActivity/fetchUserActivities',
  async (_, thunkAPI) => {
    try {
      const Response = await Axios.fetch('maxproject/user/activities');
      return await Response?.data?.activities;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  },
);

export const slice = createSlice({
  name: 'userActivity',
  initialState: {
    recentActivities: [],
    currentActivity: {
      id: '',
      name: '',
      type: '',
      duration: 0,
      isRunning: false,
      updatedAt: null,
    },
    status: 'idle',
    error: null,
  },
  reducers: {
    setUserActivity: (state, action) => {
      return {...state, ...action.payload};
    },
    clearUserActivity: state => {
      return {
        recentActivities: [],
        currentActivity: {},
        status: 'idle',
        error: null,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUserActivities.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchUserActivities.fulfilled, (state, action) => {
        state.status = 'succeeded';

        const {runningActivities, recentActivities} = action.payload || {};
        // Prepare currentActivityPayload
        let currentActivityPayload = null;
        //if running activities available
        if (runningActivities)
          if (runningActivities.Task)
            currentActivityPayload = createActivityPayload(
              runningActivities.Task,
              'TASK',
              runningActivities.Task.status,
            );
          else if (runningActivities.Meeting)
            currentActivityPayload = createActivityPayload(
              runningActivities.Meeting,
              'MEETING',
              runningActivities.Meeting.status,
            );
          else if (runningActivities.Break)
            currentActivityPayload = createActivityPayload(
              runningActivities.Break,
              'BREAK',
              runningActivities.Break.isActive,
            );
        // Prepare recentActivitiesPayload
        let recentActivitiesPayload = [];
        if (recentActivities) {
          const {Tasks = [], Meetings = [], Break} = recentActivities;

          Tasks.forEach(taskTimer =>
            recentActivitiesPayload.push(
              createActivityPayload(taskTimer, 'TASK'),
            ),
          );
          Meetings.forEach(meetingTimer =>
            recentActivitiesPayload.push(
              createActivityPayload(meetingTimer, 'MEETING'),
            ),
          );
          if (Break)
            recentActivitiesPayload.push(createActivityPayload(Break, 'BREAK'));
        }

        if (!currentActivityPayload && recentActivitiesPayload.length) {
          // Find the latest activity
          currentActivityPayload = recentActivitiesPayload.reduce(
            (latest, current) =>
              new Date(current.updatedAt) > new Date(latest.updatedAt)
                ? current
                : latest,
          );
          // Remove the latest activity from the array
          recentActivitiesPayload = recentActivitiesPayload.filter(
            activity => activity !== currentActivityPayload,
          );
        }
        // Update state
        state.currentActivity = currentActivityPayload;
        state.recentActivities = recentActivitiesPayload;
      })
      .addCase(fetchUserActivities.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      });
  },
});

export const {setUserActivity, clearUserActivity} = slice.actions;

export const getUserActivity = ({userActivity}) => {
  const isToday = date => {
    return moment(date).isSame(moment(), 'day');
  };

  const recentActivities = userActivity.recentActivities.filter(
    ({type, updatedAt}) => !(type === 'BREAK' && !isToday(updatedAt)),
  );

  const currentActivity =
    userActivity.currentActivity?.type === 'BREAK' &&
    !isToday(userActivity.currentActivity.updatedAt)
      ? {}
      : userActivity.currentActivity;

  return {
    ...userActivity,
    recentActivities,
    currentActivity,
  };
};

export default slice.reducer;
