import {PageAllTopTitle} from '../../../../common/ManagementComponents/PageAllTopTitle';
import {BsArrowLeft} from 'react-icons/bs';
import {InputBox} from '../../../../common/ManagementComponents/form/Form';
import Select from 'react-select';
import {useState} from 'react';
import {
  OutlineBtn,
  FullBtn,
} from '../../../../common/ManagementComponents/Btn/Btn';
import * as React from 'react';
import {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Axios from './../../../../../config/api';
import {Notify} from 'notiflix/build/notiflix-notify-aio';
import {Block} from 'notiflix/build/notiflix-block-aio';
import {useForm, Controller} from 'react-hook-form';
import {StatusCode} from '../../../../../constants';
import {Confirm} from 'notiflix';
export const NewNotes = () => {
  const params = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    reset,
    getValues,
  } = useForm();

  const noteOptions = [
    {
      value: 'Project',
      label: 'Project',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ];

  const [note, setNote] = useState({});
  const [meetings, setMeetings] = useState([]);
  const [serverErrors, setServerErrors] = useState({});
  const [meetingData, setMeetingDate] = useState(null);
  const [noteType, setNoteType] = useState(noteOptions[0]);
  const [selectValues, setSelectValues] = useState({
    projects: [],
    meetings: [],
  });

  useEffect(async () => {
    getProjects();
    getMeetings();
    if (params?.meeting_note_id) fetchNoteData();

    return () => {};
  }, []);
  const getProjects = (project_id = 0) => {
    Axios.fetch(`maxproject/random/get_all_projects`)
      .then(({data}) => {
        let projects = data?.projects?.map(el => ({
          value: el?.id,
          label: el?.name,
        }));
        setSelectValues(old => ({
          ...old,
          projects,
        }));
      })
      .catch(err => {
        console.log(err);
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const getMeetings = (project_id = 0) => {
    Axios.fetch(`maxproject/meeting_note/get_meetings?project_id=${project_id}`)
      .then(({data}) => {
        let meetings = data?.meetings?.map(el => ({
          value: el.id,
          label: el.title,
        }));
        setMeetings(data?.meetings);
        setSelectValues(old => ({
          ...old,
          meetings,
        }));
      })
      .catch(err => {
        console.log(err);
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchNoteData = () => {
    //showing up loader
    Block.circle('#form', 'Loading...');
    Axios.fetch(`maxproject/meeting_note/${params.meeting_note_id}`)
      .then(({data}) => {
        let payload = {
          title: data.title,
          project: {
            label: data?.Project?.name,
            value: data?.Project?.id,
          },
          meeting: {
            label: data?.Meeting?.title,
            value: data?.Meeting?.id,
          },
        };
        if (!data?.Project) setNoteType(noteOptions[1]);
        setMeetingDate(data?.Meeting?.date);
        setNote(payload);
        reset(payload);
        Block.remove('#form');
      })
      .catch(err => {
        //removing loader
        Block.remove('#form');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleFormSubmit = data => {
    if(params?.meeting_note_id) return false;
    
    let meeting = meetings.find(m => m.id == getValues('meeting')?.value);
    if (meeting?.MeetingNote)
      return Confirm.show(
        `Alert!`,
        `The meeting notes have already been prepared!`,
        'Take me there',
        'OK',
        () => {
          navigate('/Management/meeting_notes/' + meeting?.MeetingNote.id);
        },
        null,
        {
          width: '400px',
        },
      );
    let payload = {
      title: data.title,
      project_id: noteType.value == 'Project' ? data?.project?.value : null,
      meeting_id: data?.meeting?.value,
    };
    //clearing server errors
    setServerErrors({});
    //showing up loader
    Block.circle('#form', 'Saving...');
    //initiating network request
    const Request = Axios.post('maxproject/meeting_note', payload);

    // const Request = params?.meeting_note_id
    //   ? Axios.put('maxproject/meeting_note/' + params.meeting_note_id, payload)
    //   : Axios.post('maxproject/meeting_note', payload);

    Request.then(({data}) => {
      Block.remove('#form');
      Notify.success(data.message);
      navigate('/Management/meeting_notes');
    }).catch(err => {
      //removing loader
      Block.remove('#form');
      //checking for any error message
      if (err?.response?.data?.message)
        Notify.failure(err?.response?.data?.message);
      //checking and showing express validation errors
      if (err?.response?.status == StatusCode.ExpressErrors)
        err?.response?.data?.errors.forEach(el =>
          setServerErrors(old => ({...old, [el.param]: el.msg})),
        );
    });
  };
  const handleMeetingChange = (el, field) => {
    field.onChange(el);
    let meeting = meetings.find(m => m.id == el.value);
    if (meeting?.MeetingNote) {
      Confirm.show(
        `Alert!`,
        `The meeting notes have already been prepared!`,
        'Take me there',
        'OK',
        () => {
          navigate('/Management/meeting_notes/' + meeting?.MeetingNote.id);
        },
        null,
        {
          width: '400px',
        },
      );
    }
    setMeetingDate(meeting?.date);
  };
  return (
    <div className="custom_container">
      <PageAllTopTitle link={-1} icon={<BsArrowLeft />} title="back" />
      <div className="custom_container_iner">
        <div className="CompanySetup_main_div">
          <div className="CompanySetup_inner_main_div">
            <form id="form" onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
                <div className="col-12">
                  <div className="create_task_title">
                    <h3>Create Note</h3>
                  </div>
                </div>
                <div className="col-12">
                  <label className="all_page_my_label_new">Select type*</label>
                  <div className="dashboard_top_week_Select drop_box">
                    <Select
                      options={noteOptions}
                      value={noteType}
                      isSearchable={true}
                      onChange={option => {
                        setNoteType(option);
                        if (option.value === 'Other') getMeetings(0);
                        else
                          setSelectValues(old => ({
                            ...old,
                            meetings: [],
                          }));
                      }}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
                    {noteType.value === 'Project' ? (
                      <div className="col-12">
                        <label className="all_page_my_label_new">
                          Select project*
                        </label>
                        <div className="dashboard_top_week_Select drop_box">
                          <Controller
                            name="project"
                            control={control}
                            rules={{
                              required: 'Please select a project',
                            }}
                            render={({field}) => (
                              <Select
                                {...field}
                                isSearchable={true}
                                defaultValue={note?.project}
                                onChange={el => {
                                  field.onChange(el);
                                  getMeetings(el.value);
                                }}
                                placeholder={field.value || 'Select Project'}
                                options={selectValues.projects}
                              />
                            )}
                          />
                          <small className="error_message">
                            {errors.project?.message || serverErrors?.project}
                          </small>
                        </div>
                      </div>
                    ) : null}
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <label className="all_page_my_label_new">
                        Select meeting
                      </label>
                      <div className="dashboard_top_week_Select drop_box">
                        <Controller
                          name="meeting"
                          control={control}
                          rules={{
                            required: false,
                          }}
                          render={({field}) => (
                            <Select
                              {...field}
                              isSearchable={true}
                              defaultValue={note?.meeting}
                              onChange={el => handleMeetingChange(el, field)}
                              options={selectValues.meetings}
                              placeholder={field.value || 'Select Meeting'}
                            />
                          )}
                        />
                        <small className="error_message">
                          {errors.meeting?.message || serverErrors?.meeting}
                        </small>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="all_page_my_label_new">Date</label>
                      <input
                        value={meetingData}
                        type="date"
                        className="form-control intput_box"
                        disabled
                      />
                    </div>
                    <div className="col-12">
                      <InputBox
                        register={() =>
                          register('title', {
                            required: 'Please enter title',
                            value: note?.title,
                          })
                        }
                        errorMessage={
                          errors.title?.message || serverErrors?.title
                        }
                        type="text"
                        label="Notes title*"
                        placeholder="Title"
                      />
                    </div>
                    <div className="col-12 mb-3 mt-5">
                      <div className=" d-flex align-items-center justify-content-center">
                        <div className="me-2">
                          <OutlineBtn link={-1} name="Cancel" />
                        </div>
                        <div className=" ms-2">
                          <button
                            className="management_btn_full2"
                            type="submit"
                            form={'form'}>
                            {params?.meeting_note_id ? 'Update' : 'Save'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
