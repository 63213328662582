import {PUBLIC_URL} from '../constants';
import Axios from './../config/api';

async function registerServiceWorker() {
  try {
    if ('serviceWorker' in navigator) {
      let url = PUBLIC_URL + 'sw.js';
      const reg = await navigator.serviceWorker
        .register(url, {scope: '/'})
        .then(async sreg => {
          //checking registration state
          if (sreg.installing) {
            console.log('Service worker installing');
          } else if (reg.waiting) {
            console.log('Service worker installed');
          } else if (reg.active) {
            console.log('Service worker active');
          }
          navigator.serviceWorker.ready.then(async reg => {
            let subscription = await reg.pushManager.getSubscription();

            if (subscription === null)
              subscription = await reg?.pushManager?.subscribe({
                userVisibleOnly: true,
                applicationServerKey:
                  'BM1AwP94g1VXrSZPqan16KfdLcRZjsvivCPMLWvxGr4tQcqgpRRg7xFjNDW-ED5Z1BH0sEPlTt7LgpzuTuFSIsU',
              });
            //sending sub to server
            if (subscription) Axios.post('maxproject/user/sub', {subscription});
          });
        });
      // console.log('service config is', {reg});
      return reg;
    }
    throw Error('serviceworker not supported');
  } catch (error) {
    console.log(error);
  }
}

export {registerServiceWorker};
