import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Menu, MenuItem} from '@material-ui/core';
import {ChevronDownIcon, SearchIcon} from '@primer/octicons-react';
import {Input} from 'antd';
import {CreatedByMenuUserData} from '../Data';
export const CreatedByMenu = ({data, cb = () => {}, selectedUser = null}) => {
  const storData = useSelector(state => state);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = user => {
    cb(user);
    handleClose();
  };
  return (
    <div>
      <p
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="menu_btn_div">
        {selectedUser?.name || 'Anyone'}
        <span>
          <ChevronDownIcon />
        </span>
      </p>
      <Menu
        data-theme={storData.global.isMode}
        className="timesheet_menu_top_Created_by_div"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <div className="input_div">
          <Input
            type="text"
            placeholder="Select user"
            startAdornment={<SearchIcon />}
          />
        </div>
        {data.map((user, index) => (
          <MenuItem key={index} onClick={() => handleSelect(user)}>
            <div className="menu_item_div">
              <img src={user.avatar} alt={user.name} />
              <span>{user.name}</span>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
