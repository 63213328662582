import {PageAllTopTitle} from '../../../../common/ManagementComponents/PageAllTopTitle';
import {IoSearchOutline} from 'react-icons/io5';
import {
  OutlineBtn,
  FullBtn,
  OutlineBtnWithIcon,
} from '../../../../common/ManagementComponents/Btn/Btn';
import {GoDotFill} from 'react-icons/go';
import {useNavigate} from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Accordion from 'react-bootstrap/Accordion';
import {IoCloseSharp} from 'react-icons/io5';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {FaFilter} from 'react-icons/fa';
import useClickOutside from '../../../../Management/CustomHooks/ClickOutside';
import NoResultsSVG from '../../../../../pics/empty_state_search_not_found.svg';
import Img from '../../../../common/components/img/Img';
import ProjectIcon from '../../../../../pics/Management/2.png';
import TextField from '@mui/material/TextField';
import React, {useState, useEffect} from 'react';
import Axios from './../../../../../config/api';
import {Block, Confirm, Notify, Report} from 'notiflix';
import moment from 'moment';
import {useNonInitialEffect} from '../../../../../utils/hooks/non-initial-effect.tsx';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
export const Notes = () => {
  const [query, setQuery] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [notes, setNotes] = useState([]);
  const [participant, setParticipant] = useState([]);
  const [activeNoteId, setActiveNoteId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [allUsers, setAllUsers] = useState([]);
  const [filters, setFilters] = useState({
    projects: [],
    // startDate: null,
    // endDate: null,
    users: [],
  });
  const [assignToModalShow, setAssignToModalShow] = React.useState(false);

  useEffect(() => {
    fetchFilters();
    //getting all users
    Axios.fetch(`maxproject/random/members`)
      .then(({data}) => {
        let options = data.members.map(val => ({
          value: val.id,
          label: <UserUi userName={val.name} userImg={val.avatar} />,
        }));
        setAllUsers(options);
      })
      .catch(err => {});
  }, []);

  useNonInitialEffect(() => {
    // Timer ID for the delayed fetch
    let timerId;
    // Clear previous timer, if any
    clearTimeout(timerId);
    // Set a new timer for the delayed fetch
    timerId = setTimeout(getNotes, 500);
    // Clean up timer on component unmount
    return () => clearTimeout(timerId);
  }, [query]);

  useEffect(() => {
    getNotes();
    return () => {};
  }, [refresh]);

  const getNotes = () => {
    setIsLoading(true);
    Block.circle('.notes');
    let f = {};
    Object.keys(filters).forEach(key => {
      f[key] = filters[key]
        .filter(filter => filter?.isSelected)
        .map(filter => filter.id);
    });
    const q = new URLSearchParams(f);
    Axios.fetch(`maxproject/meeting_note?query=${query}&${q.toString()}`)
      .then(({data}) => {
        setIsLoading(false);
        Block.remove('.notes');
        setNotes(data?.MeetingNotes);
      })
      .catch(err => {
        setIsLoading(false);
        Block.remove('.notes');
      });
  };
  const handleSearch = e => {
    setQuery(e.target.value);
  };

  const fetchFilters = () => {
    Axios.fetch(`maxproject/meeting_note/filters`)
      .then(({data}) => {
        setFilters(old => ({
          ...old,
          users: data?.filters?.users,
          projects: data?.filters?.projects,
        }));
      })
      .catch(err => {});
  };
  const AssignToModal = props => {
    const [meetingNotesUsers, setMeetingNotesUsers] = useState([]);
    //creating default options
    const participants = participant.map(val => ({
      value: val.User.id,
      label: <UserUi userName={val.User.name} userImg={val.User.avatar} />,
    }));

    const handleUpdateParticipants = () => {
      const users = meetingNotesUsers.map(val => val.value);
      Block.dots('.note_participants_modal');
      Axios.put(
        `maxproject/meeting_note/meeting_note_participant/` + activeNoteId,
        {
          users,
        },
      )
        .then(({data}) => {
          //showing message
          Notify.success('Participants updated successfully');
          setNotes(old => {
            let arr = old.slice();
            let index = arr.findIndex(val => val.id === activeNoteId);
            arr[index].MeetingNoteParticipants = data.MeetingNoteParticipant;
            return arr;
          });
          setMeetingNotesUsers([]);
          Block.remove('.note_participants_modal');
          setAssignToModalShow(false);
        })
        .catch(err => {
          Block.remove('.note_participants_modal');
        });
    };

    return (
      <Modal
        size="md"
        show={assignToModalShow}
        onHide={() => setAssignToModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Assign</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row note_participants_modal">
            <div className="col-12">
              <div>
                <Select
                  isMulti
                  isSearchable={true}
                  options={allUsers}
                  defaultValue={participants}
                  onChange={val => setMeetingNotesUsers(val)}
                  placeholder="Select members"
                />
              </div>
            </div>
            <div className="col-12">
              <div className=" text-center mt-5">
                <FullBtn onClick={handleUpdateParticipants} name="Assign" />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <div className="custom_container">
      <AssignToModal />
      <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 header_my">
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12 order-md-1 order-2">
          <PageAllTopTitle title="Notes" />
        </div>
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 order-md-2 order-1">
          <div className="header_input_div">
            <IoSearchOutline />
            <input
              type="search"
              onChange={handleSearch}
              className="form-control"
              placeholder="Search by title here"
            />
          </div>
        </div>
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12 justify-content-end schedule_meeting_btn order-md-3 order-3">
          <div className="blue_btn_top_div ">
            <FullBtn link="/Management/new_meeting_note" name="New Notes" />
          </div>
        </div>
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 filter_btn_top_div order-4">
          <PageRightFilters
            refresh={refresh}
            setRefresh={setRefresh}
            filters={filters}
            setFilters={setFilters}
          />
        </div>
      </div>
      <div className="dashbaord_white_card">
        <NotesList
          notes={notes}
          setNotes={setNotes}
          isLoading={isLoading}
          setAssignToModalShow={setAssignToModalShow}
          setParticipant={setParticipant}
          setActiveNoteId={setActiveNoteId}
        />
      </div>
    </div>
  );
};
const BoxMenu = ({
  setAssignToModalShow,
  noteId,
  idx,
  participant = [],
  setNotes = () => {},
  setParticipant = () => {},
  setActiveNoteId = () => {},
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDeleteNote = () => {
    Confirm.show(
      `Delete?`,
      `This action is undonable!`,
      'Delete',
      'Cancel',
      () => {
        //hiding menu
        handleClose();
        //showing loader
        Block.dots(`#note_` + noteId);
        //initializing network request
        Axios.delete(`maxproject/meeting_note/${noteId}`)
          .then(({data}) => {
            //hiding loader
            Block.remove(`#note_` + noteId);
            //showing success message
            Notify.success(data?.message);
            //removing note
            setNotes(old => {
              let new_notes = old.slice();
              new_notes.splice(idx, 1);
              return new_notes;
            });
          })
          .catch(err => {
            //hiding loader
            Block.remove(`#note_` + noteId);
          });
      },
    );
  };

  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Tooltip title="Menu" position="bottom">
        <MoreHorizIcon
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          className="new_note_box_menu_div"
        />
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
        {/* <MenuItem
          onClick={() => navigate('/Management/meeting_note/' + noteId)}>
          Edit
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            setParticipant(participant);
            setActiveNoteId(noteId);
            setAssignToModalShow(true);
          }}>
          Assign
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (participant.length)
              return Report.info(
                'Attention',
                `Please remove assigners first`,
                'Okay',
                () => {
                  setParticipant(participant);
                  setActiveNoteId(noteId);
                  setAssignToModalShow(true);
                },
                {
                  backOverlayColor: 'rgb(0 0 0 / 51%)',
                },
              );
            //deleting note
            handleDeleteNote();
          }}>
          Delete
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
const NotesList = ({
  setAssignToModalShow,
  notes = [],
  setNotes = () => {},
  setParticipant = () => {},
  setActiveNoteId = () => {},
  isLoading,
}) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const handlePagination = (e, page) => {
    setPage(page);
  };
  const NoDataView = () => {
    return (
      <div className="row">
        <div className="col-12">
          <div
            style={{
              minHeight: '30rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '2rem',
            }}>
            <Img
              backgroundImage={NoResultsSVG}
              style={{
                height: 250,
                width: 250,
              }}
              className="project_summary_card_icon"
            />
            <span className="mb-3 w-50">
              Sorry, We couldn't find any details that match your filter
              criteria. Please consider clearing your filter or refining your
              criteria for better results.
            </span>
          </div>
        </div>
      </div>
    );
  };
  if (!notes.length && !isLoading) return <NoDataView />;

  return (
    <>
      <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 notes">
        {notes?.slice((page - 1) * 9, 9 * page)?.map((note, i) => (
          <div
            id={`note_` + note.id}
            className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"
            key={i}>
            <div className="new_note_box_div">
              {/* <p className="new_note_box_project_title">
                <img src={ProjectIcon} alt="" />

                {'Lorem ipsum, dolor sit amet consectetur'.length > 24
                  ? `${'Lorem ipsum, dolor sit amet consectetur'.substring(
                      0,
                      24,
                    )}...`
                  : 'Lorem ipsum, dolor sit amet consectetur'}
              </p> */}
              <h3
                onClick={() =>
                  navigate('/Management/meeting_notes/' + note.id)
                }>
                {note.title.length > 20
                  ? `${note.title.substring(0, 20)}...`
                  : note.title}
              </h3>
              <ul>
                {note?.Notes.map((n, i) => (
                  <li key={i}>
                    <span>
                      <GoDotFill />
                    </span>
                    {n.title.length > 30
                      ? `${n.title.substring(0, 30)}...`
                      : n.title}
                  </li>
                ))}
              </ul>
              <div className="new_note_box_user_bottom_div">
                <div className="new_note_box_user_icon_div">
                  <Avatar
                    alt={note?.User?.name}
                    src={note?.User?.avatar}
                    sx={{width: 30, height: 30}}
                  />
                  <p>{note?.User?.name}</p>
                </div>
                <p className="new_note_box_user_day_time">
                  {moment(note.createdAt).calendar()}
                </p>
              </div>
              <BoxMenu
                noteId={note.id}
                idx={i}
                setNotes={setNotes}
                setAssignToModalShow={setAssignToModalShow}
                participant={note?.MeetingNoteParticipants}
                setParticipant={setParticipant}
                setActiveNoteId={setActiveNoteId}
              />
            </div>
          </div>
        ))}
      </div>
      {notes?.length > 9 ? (
        <div className="projec_tabelt_list_pagination_div">
          <Stack spacing={2}>
            <Pagination
              onChange={handlePagination}
              count={Math.ceil(notes?.length / 9)}
            />
          </Stack>
        </div>
      ) : null}
    </>
  );
};
const PageRightFilters = ({
  filters = {},
  setFilters = () => {},
  refresh,
  setRefresh,
}) => {
  const [showFilters, setShowFilters] = React.useState(false);

  let domNode = useClickOutside(() => {
    setShowFilters(false);
  });
  const RequestedBy = props => (
    <div className="Requested_by_div">
      <img src={props.UserPhoto} alt="icon" />
      {props.title}
    </div>
  );
  const onFilterChange = (filter, idx, checked) => {
    setFilters(old => {
      let arr = old[filter].slice();
      arr[idx]['isSelected'] = checked;
      return {...old, [filter]: arr};
    });
  };
  const applyFilters = () => {
    console.log(filters);
    setRefresh(!refresh);
  };
  const handleClearAllFilters = () => {
    setFilters(old => {
      let filters = {};
      Object.keys(old).forEach(key => {
        filters[key] = filters[key].map(filter => ({
          ...filter,
          isSelected: false,
        }));
      });
      return filters;
    });
    // fetchTasks(false);
  };
  return (
    <>
      <OutlineBtnWithIcon
        onClick={() => setShowFilters(true)}
        name="All Filters"
        icon={<FaFilter />}
      />

      <Drawer anchor="right" open={showFilters}>
        <div ref={domNode}>
          <Box role="presentation" className="filter_new_top_div">
            <IoCloseSharp onClick={() => setShowFilters(false)} />
            <div className="row mt-3 mb-4">
              <div className="col-6">
                <p className="filter_title_1">All Filters</p>
              </div>
              <div className="col-6 text-end">
                <p className="filter_title_2">Clear All</p>
              </div>
            </div>
            <div className="filter_new_top_div_inner">
              <div className="header_input_div">
                <IoSearchOutline />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Filter"
                />
              </div>
              <Accordion alwaysOpen>
                {filters?.projects.length ? (
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Project</Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <FormGroup>
                          {filters?.projects.map((project, i) => {
                            return (
                              <FormControlLabel
                                onChange={e =>
                                  onFilterChange(
                                    'projects',
                                    i,
                                    e.target.checked,
                                  )
                                }
                                checked={project?.isSelected}
                                control={<Checkbox />}
                                label={project.name}
                              />
                            );
                          })}
                        </FormGroup>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ) : null}
                {/* <Accordion.Item eventKey="2">
                  <Accordion.Header>Date</Accordion.Header>
                  <Accordion.Body>
                    <div className="date_filter_div">
                      <TextField
                        name="someDate"
                        label="From"
                        InputLabelProps={{shrink: true, required: false}}
                        type="date"
                        // defaultValue={}
                        className="from"
                      />
                      <TextField
                        name="someDate"
                        label="To"
                        InputLabelProps={{shrink: true, required: false}}
                        type="date"
                        // defaultValue={}
                        className="to"
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item> */}
                {filters?.users.length ? (
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Created By</Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <FormGroup>
                          {filters?.users.map((user, i) => (
                            <FormControlLabel
                              control={<Checkbox />}
                              onChange={e =>
                                onFilterChange('users', i, e.target.checked)
                              }
                              checked={user?.isSelected}
                              label={
                                <RequestedBy
                                  title={user.name}
                                  UserPhoto={user.avatar}
                                />
                              }
                            />
                          ))}
                        </FormGroup>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ) : null}
              </Accordion>
            </div>
            <div className=" mt-4 d-flex justify-content-center align-items-center">
              <div className=" me-3">
                <FullBtn name="Apply" onClick={applyFilters} />
              </div>
              <div className="add_project_modal_btn_one">
                <OutlineBtn
                  onClick={() => setShowFilters(false)}
                  name="Close"
                />
              </div>
            </div>
          </Box>
        </div>
      </Drawer>
    </>
  );
};
const UserUi = props => {
  return (
    <div className="assign_to_modal_ui">
      <Avatar
        alt={props.userName}
        src={props.userImg}
        sx={{width: 24, height: 24}}
      />
      <span>{props.userName}</span>
    </div>
  );
};
