const Input = props => {
  return (
    <input
      min={props?.min}
      max={props?.max}
      readOnly={props?.readOnly}
      checked={props?.checked}
      type={props.type}
      onChange={props?.onChange}
      value={props?.value}
      className={props.className}
      placeholder={props.placeholder}></input>
  );
};
export default Input;
