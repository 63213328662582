import '../App.css';
import {Routes, Route, BrowserRouter as Router} from 'react-router-dom';
import HrRoutes from '../modules/MaxHr/routes/Index';
import ManagementRoutes from '../modules/Management/routes/Index';
import AccountRoutes from '../modules/Account/routes/Index';
import {Login} from '../modules/auth/Login';
import {ForgotPassword} from '../modules/auth/ForgotPassword';
import {CheckYourEmail} from '../modules/auth/CheckYourEmail';
import {SetNewPassoword} from '../modules/auth/SetNewPassoword';
import {PasswordResetMessagePage} from '../modules/auth/PasswordResetMessagePage';
// import {ManagementSettings} from '../modules/Management/components/pages/Settings/Settings';
import {PrivateRoute} from './Protected';

function MaxHrRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/check_your_email" element={<CheckYourEmail />} />
        <Route path="/set_new_passoword" element={<SetNewPassoword />} />
        <Route path="/password_reset" element={<PasswordResetMessagePage />} />
        {/* <Route path="/Management/settings" element={<ManagementSettings />} /> */}
        <Route
          path="hr/*"
          element={
            <PrivateRoute>
              <HrRoutes />
            </PrivateRoute>
          }
        />
        <Route
          path="management/*"
          element={
            <PrivateRoute>
              <ManagementRoutes />
            </PrivateRoute>
          }
        />
        <Route
          path="account/*"
          element={
            <PrivateRoute>
              <AccountRoutes />
            </PrivateRoute>
          }
        />

        <Route path="*" element={<main>NOT FOUND</main>} />
      </Routes>
    </Router>
  );
}

export default MaxHrRoutes;
