import { Index } from "../components/Pages/Finance/Index";
const Finance = () => {
  return (
    <>
      <div className="custom_container">
        <Index />
      </div>
    </>
  );
};

export default Finance;
