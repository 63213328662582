import useClickOutside from '../../../Management/CustomHooks/ClickOutside';
import {IoSearch} from 'react-icons/io5';
import React, {useState} from 'react';
import './SearchBox.scss';
export const SearchBox = props => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    // setIsVisible(!isVisible);
  };
  let domNode = useClickOutside(() => {
    setIsVisible(false);
  });
  return (
    <>
      <div className="search_box_div">
        <span className="input_icon">
          <IoSearch />
        </span>
        <input
          onClick={toggleVisibility}
          type="text"
          value={props?.value}
          onChange={props?.onChange}
          placeholder={props.placeholder}
        />
        {isVisible && (
          <div className="search_result_div" ref={domNode}>
            <p className="no_data">no data</p>
            {props?.Data?.map((val, i) => {
              return (
                <div key={i} className="search_result_item">
                  <div>
                    <span>
                      <IoSearch />
                    </span>
                  </div>
                  {val.SearchItemName}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};
