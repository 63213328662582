import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {NewBootstrapTooltip} from '../../../common/NewComponents/NewTooltip/NewTooltip';
import {useNavigate} from 'react-router-dom';
import {
  CheckCircleIcon,
  DeviceCameraVideoIcon,
  PlusCircleIcon,
  TasklistIcon,
} from '@primer/octicons-react';
import {useSelector} from 'react-redux';

export const AddLink = () => {
  const {
    global: {isMode},
  } = useSelector(state => state);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const AddLinkData = [
    {
      onClick: () => navigate('/Management/tasks/'),
      icon: <TasklistIcon />,
      text: 'Task',
    },
    {
      onClick: () => navigate('/Management/meetings/'),
      icon: <DeviceCameraVideoIcon />,
      text: 'Meeting',
    },
    {
      onClick: () => navigate('/Management/approvals/'),
      icon: <CheckCircleIcon />,
      text: 'Approval',
    },
  ];
  return (
    <div className="timesheet_menu_div">
      <NewBootstrapTooltip
        ChildComponent={
          <p
            className="top_nav_left_icon"
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}>
            <PlusCircleIcon />
          </p>
        }
        title="Quick Links"
        placement="bottom"
      />
      <Menu
        data-theme={isMode}
        className="timesheet_menu_inner_add_link_div"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{'aria-labelledby': 'basic-button'}}>
        {AddLinkData.map(({onClick, icon, text}, index) => (
          <div onClick={handleClose}>
            <MenuItem key={index} onClick={onClick}>
              <span className="timesheet_menu_inner_icon">{icon}</span>
              <p className="timesheet_menu_inner_text">{text}</p>
            </MenuItem>
          </div>
        ))}
      </Menu>
    </div>
  );
};
