import {BellIcon, XIcon} from '@primer/octicons-react';
import {NewBootstrapTooltip} from '../../../../common/NewComponents/NewTooltip/NewTooltip';
import {Drawer} from '@mui/material';
import {useSelector, useDispatch} from 'react-redux';
import React, {useState} from 'react';
import {NotificationMenu} from './NotificationMenu';
import {TabWithBadge} from './TabWithBadge';
import {
  closeNotificationDrawer,
  openNotificationDrawer,
} from '../../../../../redux/slices/NotificationSlice';

export const Notification = () => {
  const {global, drawerNotification} = useSelector(state => state);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const toggleDrawer = open => () => {
    if (open) {
      dispatch(openNotificationDrawer());
    } else {
      dispatch(closeNotificationDrawer());
    }
    // Only set loading to false after 1300ms if screen width is greater than 767px (assuming mobile threshold is 767px)
    if (window.innerWidth > 767) {
      setLoading(true);
      setTimeout(() => setLoading(false), 1300);
    } else {
      setLoading(false);
    }
  };

  const refreshNotifications = () => {
    setRefresh(!refresh);
  };

  return (
    <div>
      <NewBootstrapTooltip
        ChildComponent={
          <p className="top_nav_left_icon" onClick={toggleDrawer(true)}>
            <BellIcon />
          </p>
        }
        title="Notification"
        placement="bottom"
      />
      <Drawer
        anchor="right"
        open={drawerNotification.isOpen}
        onClose={toggleDrawer(false)}
        className="notification_one_main_div_top"
        data-theme={global.isMode}>
        <div className="notification_one_main_div">
          <div className="notification_top_nav_title">
            <h3>Notification</h3>
            <span onClick={toggleDrawer(false)} className="close_btn_modal">
              <XIcon />
            </span>
            <NotificationMenu refreshNotifications={refreshNotifications} />
          </div>
          <div className="notification_top_nav_tab">
            <TabWithBadge
              setDrawerState={toggleDrawer(false)}
              loading={loading}
              refresh={refresh}
            />
          </div>
        </div>
      </Drawer>
    </div>
  );
};
