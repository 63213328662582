import {useEffect, useState} from 'react';
import axios from 'axios';
import {
  BellIcon,
  CalendarIcon,
  CheckIcon,
  PencilIcon,
} from '@primer/octicons-react';
import {MdAlarm} from 'react-icons/md';
import {UserParticipants} from '../../../../../common/ManagementComponents/Participants';
import {AiOutlineDelete} from 'react-icons/ai';
import {Block, Confirm, Notify} from 'notiflix';
import {AttachmentsView} from '../../../../../common/NewComponents/AttachmentsView/AttachmentsView';
import {NewBootstrapTooltip} from '../../../../../common/NewComponents/NewTooltip/NewTooltip';
import BlankImage from '../../../../../../pics/Management/ReminderBlank.svg';
import {Pagination} from '@mui/material';
import Axios from './../../../../../../config/api';
import moment from 'moment';

const HoverButton = ({onClick, tooltip, children}) => (
  <NewBootstrapTooltip
    ChildComponent={
      <span className="icon_btn_div" onClick={onClick}>
        {children}
      </span>
    }
    title={tooltip}
    placement="bottom"
  />
);

export const ToDoList = ({
  setView,
  searchTerm,
  setReminderId,
  isCompleted: completedList,
  data = [],
  fetchToDoList = () => {},
}) => {
  const [currentPage, setCurrentPage] = useState(1); // State for managing current page
  const itemsPerPage = 5; // Number of items to show per page

  const filteredTasks = data?.filter(reminder =>
    reminder?.title?.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  // Calculate the index for the tasks to display
  const indexOfLastTask = currentPage * itemsPerPage;
  const indexOfFirstTask = indexOfLastTask - itemsPerPage;
  const currentTasks = filteredTasks?.slice(indexOfFirstTask, indexOfLastTask);

  // Handle page change
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const ListCard = ({Reminder}) => {
    const [isMarkComplete, setisMarkComplete] = useState(false);
    const handleDelete = () => {
      Confirm.show(
        'Delete?',
        'This action is undonable!',
        'Delete',
        'Cancel',
        () => {
          Axios.delete(`maxproject/reminder/${Reminder?.id}`).then(res => {
            Notify.success(res.data.message);
            fetchToDoList();
          });
        },
        () => {},
      );
    };

    const handleMarkComplete = () => {
      Axios.put(`maxproject/reminder/complete_status/${Reminder?.id}`, {
        isCompleted: completedList ? 0 : 1,
      }).then(() => {
        setisMarkComplete(true);
      });
    };

    return (
      <div
        className="ToDoList_card"
        style={{opacity: isMarkComplete ? 0.5 : 1}}>
        <div className="ToDoListCardTitle_div">
          <span>
            <MdAlarm />
          </span>
          <h3>
            {isMarkComplete ? <del>{Reminder?.title}</del> : Reminder?.title}
          </h3>
        </div>
        <div className="ToDoListCardPoint_div">
          <div className="row g-3 m-0">
            {Reminder?.Project ? (
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-0">
                <div className="ToDoListCardInnerPoint_div">
                  <p>Project title</p>
                  <NewBootstrapTooltip
                    ChildComponent={
                      <span>
                        {Reminder?.Project?.title?.length > 15
                          ? Reminder?.Project?.title?.substr(0, 15) + '...'
                          : Reminder?.Project?.title}
                      </span>
                    }
                    title={Reminder?.Project?.title}
                    placement="bottom"
                  />
                </div>
              </div>
            ) : null}

            {Reminder?.Task ? (
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-0">
                <div className="ToDoListCardInnerPoint_div">
                  <p>Task title</p>
                  <NewBootstrapTooltip
                    ChildComponent={
                      <span>
                        {Reminder?.Task?.title.length > 15
                          ? Reminder?.Task?.title?.substr(0, 15) + '...'
                          : Reminder?.Task?.title}
                      </span>
                    }
                    title={Reminder?.Task?.title}
                    placement="bottom"
                  />
                </div>
              </div>
            ) : null}

            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-0">
              <div className="ToDoListCardInnerPoint_div">
                <p>Created for</p>
                <div
                  className="Participants_main_div_inner"
                  style={{marginTop: '5px'}}>
                  <UserParticipants data={Reminder?.ReminderUsers || []} />
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-0">
              <div className="ToDoListCardInnerPoint_div">
                <p>Created by</p>
                <div className="ToDoListCardCreatedBy_div">
                  <div className="Participants_main_div_inner">
                    <UserParticipants data={[{User: Reminder?.User}] || []} />
                  </div>
                  <small style={{marginLeft: '15px'}}>
                    {moment(Reminder?.createdAt).format('DD-MM-YYYY')},{' '}
                    {moment(Reminder?.createdAt).format('hh:mm A')}
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-0">
              <div className="ToDoListCardInnerPoint_div">
                <p>Reminder date</p>
                <div className="ToDoListCardCreatedBy_div">
                  <p className="inner_icon_div">
                    <CalendarIcon className="inner_icon" />
                  </p>
                  <small className="inner_iconText">
                    {moment(Reminder?.date).format('DD-MM-YYYY')},{' '}
                    {moment(Reminder?.time, 'HH:mm:ss').format('hh:mm A')}
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-0">
              <div className="ToDoListCardInnerPoint_div">
                <p>Recurring</p>
                <div className="ToDoListCardCreatedBy_div">
                  <p className="inner_icon_div">
                    <BellIcon className="inner_icon" />
                  </p>
                  <small className="inner_iconText">{Reminder?.Snooze}</small>
                </div>
              </div>
            </div>
            <div className="col-12 p-0">
              <div className="ToDoListCardInnerPoint_div">
                <p>Attachments</p>
                <div className="NewAttachments_view">
                  {Reminder?.ReminderAttachments?.map((attachment, i) => (
                    <AttachmentsView
                      key={i}
                      fileUrl={attachment.url}
                      fileTitle={attachment.original_name}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="col-12 p-0">
              <div
                className="ToDoListCardInnerPoint_div d-flex"
                style={{flexDirection: 'column'}}>
                <p>Links</p>
                {Reminder?.ReminderLinks?.map((link, i) => (
                  <a key={i} href={link.link} target="_blank">
                    {link.title}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        {isMarkComplete ? (
          <div className="Mark_delete">
            <HoverButton onClick={handleDelete} tooltip="Delete">
              <AiOutlineDelete className="svg2" style={{color: '#D1242F'}} />
            </HoverButton>
          </div>
        ) : (
          <div className="box_ui">
            <HoverButton
              onClick={() => {
                setView('addReminder');
                setReminderId(Reminder?.id);
              }}
              tooltip="Edit">
              <PencilIcon className="svg1" />
            </HoverButton>
            <HoverButton onClick={handleDelete} tooltip="Delete">
              <AiOutlineDelete className="svg2" />
            </HoverButton>
            <HoverButton
              onClick={handleMarkComplete}
              tooltip={completedList ? 'Unmark Complete' : 'Mark Complete'}>
              <CheckIcon className="svg1" />
            </HoverButton>
          </div>
        )}
      </div>
    );
  };
  return (
    <div id="todoList">
      {currentTasks && currentTasks.length > 0 ? (
        <>
          {currentTasks?.map((Reminder, i) => (
            <ListCard key={i} Reminder={Reminder} />
          ))}

          {/* Conditionally render pagination if there are more tasks than the itemsPerPage */}
          {filteredTasks.length > itemsPerPage && (
            <div className="New_mui_Pagination">
              <Pagination
                count={Math.ceil(filteredTasks.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
              />
            </div>
          )}
        </>
      ) : (
        <div className="BlankImage_div">
          <img src={BlankImage} alt="No Data" />
          <p>No Reminder</p>
        </div>
      )}
    </div>
  );
};
