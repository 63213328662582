import {UserUi} from './QuickNote/QuickNoteList/AssignToModal';
import {FaRegUserCircle} from 'react-icons/fa';
import {MdAlarm} from 'react-icons/md';
import {
  CheckCircleIcon,
  FileDirectoryIcon,
  ProjectIcon,
  RepoTemplateIcon,
  SearchIcon,
  TasklistIcon,
  CheckIcon,
  GearIcon,
  XIcon,
  NoteIcon,
} from '@primer/octicons-react';

//
export const QuickNoteDefaultListData = [
  {
    id: '1',
    content: 'Item 133',
    UserIcon:
      'https://maxcrm.projectmaxlence.com.au/uploads/users/avatars/1706852999766-4940.jpeg',
    UserName: 'Sunny charkhwal',
    time: 'Yesterday 8:38pm',
    pinned: false,
    archived: false,
  },
  {
    id: '2',
    content: 'Bright Spark meeting scheduled for gmb and seo interaction ',
    UserIcon:
      'https://maxcrm.projectmaxlence.com.au/uploads/users/avatars/1706852999766-4940.jpeg',
    UserName: 'Sunny charkhwal',
    time: 'Yesterday 8:38pm',
    pinned: false,
    archived: false,
  },
  {
    id: '3',
    content: 'Item 3',
    UserIcon:
      'https://maxcrm.projectmaxlence.com.au/uploads/users/avatars/1706852999766-4940.jpeg',
    UserName: 'Sunny charkhwal',
    time: 'Yesterday 8:38pm',
    pinned: false,
    archived: false,
  },
  {
    id: '4',
    content: 'Bright Spark meeting scheduled for gmb and seo interaction55 ',
    UserIcon:
      'https://maxcrm.projectmaxlence.com.au/uploads/users/avatars/1706852999766-4940.jpeg',
    UserName: 'Sunny charkhwal',
    time: 'Yesterday 8:38pm',
    pinned: false,
    archived: false,
  },
  {
    id: '5',
    content: 'Item 5',
    UserIcon:
      'https://maxcrm.projectmaxlence.com.au/uploads/users/avatars/1706852999766-4940.jpeg',
    UserName: 'Sunny charkhwal',
    time: 'Yesterday 8:38pm',
    pinned: false,
    archived: false,
  },
  {
    id: '6',
    content: 'Item 5',
    UserIcon:
      'https://maxcrm.projectmaxlence.com.au/uploads/users/avatars/1706852999766-4940.jpeg',
    UserName: 'Sunny charkhwal',
    time: 'Yesterday 8:38pm',
    pinned: false,
    archived: false,
  },
  {
    id: '7',
    content: 'Item 5',
    UserIcon:
      'https://maxcrm.projectmaxlence.com.au/uploads/users/avatars/1706852999766-4940.jpeg',
    UserName: 'Sunny charkhwal',
    time: 'Yesterday 8:38pm',
    pinned: false,
    archived: false,
  },
  {
    id: '8',
    content: 'Item 5',
    UserIcon:
      'https://maxcrm.projectmaxlence.com.au/uploads/users/avatars/1706852999766-4940.jpeg',
    UserName: 'Sunny charkhwal',
    time: 'Yesterday 8:38pm',
    pinned: false,
    archived: false,
  },
];
export const QuickNoteArchivedListData = [
  {
    id: '1',
    content: 'Item 133',
    UserIcon:
      'https://maxcrm.projectmaxlence.com.au/uploads/users/avatars/1706852999766-4940.jpeg',
    UserName: 'Sunny charkhwal',
    time: 'Yesterday 8:38pm',
    pinned: false,
    archived: true,
  },
  {
    id: '2',
    content: 'Bright Spark meeting scheduled for gmb and seo interaction ',
    UserIcon:
      'https://maxcrm.projectmaxlence.com.au/uploads/users/avatars/1706852999766-4940.jpeg',
    UserName: 'Sunny charkhwal',
    time: 'Yesterday 8:38pm',
    pinned: false,
    archived: true,
  },
  {
    id: '3',
    content: 'Item 3',
    UserIcon:
      'https://maxcrm.projectmaxlence.com.au/uploads/users/avatars/1706852999766-4940.jpeg',
    UserName: 'Sunny charkhwal',
    time: 'Yesterday 8:38pm',
    pinned: false,
    archived: true,
  },
  {
    id: '4',
    content: 'Bright Spark meeting scheduled for gmb and seo interaction55 ',
    UserIcon:
      'https://maxcrm.projectmaxlence.com.au/uploads/users/avatars/1706852999766-4940.jpeg',
    UserName: 'Sunny charkhwal',
    time: 'Yesterday 8:38pm',
    pinned: false,
    archived: true,
  },
  {
    id: '5',
    content: 'Item 5',
    UserIcon:
      'https://maxcrm.projectmaxlence.com.au/uploads/users/avatars/1706852999766-4940.jpeg',
    UserName: 'Sunny charkhwal',
    time: 'Yesterday 8:38pm',
    pinned: false,
    archived: true,
  },
  {
    id: '6',
    content: 'Item 5',
    UserIcon:
      'https://maxcrm.projectmaxlence.com.au/uploads/users/avatars/1706852999766-4940.jpeg',
    UserName: 'Sunny charkhwal',
    time: 'Yesterday 8:38pm',
    pinned: false,
    archived: true,
  },
  {
    id: '7',
    content: 'Item 5',
    UserIcon:
      'https://maxcrm.projectmaxlence.com.au/uploads/users/avatars/1706852999766-4940.jpeg',
    UserName: 'Sunny charkhwal',
    time: 'Yesterday 8:38pm',
    pinned: false,
    archived: true,
  },
  {
    id: '8',
    content: 'Item 5',
    UserIcon:
      'https://maxcrm.projectmaxlence.com.au/uploads/users/avatars/1706852999766-4940.jpeg',
    UserName: 'Sunny charkhwal',
    time: 'Yesterday 8:38pm',
    pinned: false,
    archived: true,
  },
];
export const participants = [
  {
    name: 'sunny charkhwal',
    designation: 'React JS Developer',
    img: 'https://mui.com/static/images/avatar/7.jpg',
  },
  {
    name: 'sunny charkhwal',
    designation: 'React JS Developer',
    img: 'https://mui.com/static/images/avatar/7.jpg',
  },
  {
    name: 'sunny charkhwal',
    designation: 'React JS Developer',
    img: 'https://mui.com/static/images/avatar/7.jpg',
  },
  {
    name: 'sunny charkhwal',
    designation: 'React JS Developer',
    img: 'https://mui.com/static/images/avatar/7.jpg',
  },
  {
    name: 'sunny charkhwal',
    designation: 'React JS Developer',
    img: 'https://mui.com/static/images/avatar/7.jpg',
  },
  {
    name: 'sunny charkhwal',
    designation: 'React JS Developer',
    img: 'https://mui.com/static/images/avatar/7.jpg',
  },
  {
    name: 'sunny charkhwal',
    designation: 'React JS Developer',
    img: 'https://mui.com/static/images/avatar/7.jpg',
  },
  {
    name: 'sunny charkhwal',
    designation: 'React JS Developer',
    img: 'https://mui.com/static/images/avatar/7.jpg',
  },
  {
    name: 'sunny charkhwal',
    designation: 'React JS Developer',
    img: 'https://mui.com/static/images/avatar/7.jpg',
  },
];
export const colorButtons = [
  {tooltip: 'Coarl', colorName: '#FAAFA8'},
  {tooltip: 'Peach', colorName: '#F39F76'},
  {tooltip: 'Sand', colorName: '#FFF8B8'},
  {tooltip: 'Mint', colorName: '#E2F6D3'},
  {tooltip: 'Sage', colorName: '#B4DDD3'},
  {tooltip: 'Fog', colorName: '#D4E4ED'},
  {tooltip: 'Storm', colorName: '#AECCDC'},
  {tooltip: 'Dusk', colorName: '#D3BFDB'},
  {tooltip: 'Blossom', colorName: '#F6E2DD'},
  {tooltip: 'Clay', colorName: '#E9E3D4'},
  {tooltip: 'chalk', colorName: '#EFEFF1'},
];
export const QuickNoteAssignUsers = [
  {
    value: 'sunny charkhwal',
    userName: 'sunny charkhwal',
    userImg: 'https://mui.com/static/images/avatar/2.jpg',
  },
  {
    value: 'kamal',
    userName: 'kamal',
    userImg: 'https://mui.com/static/images/avatar/3.jpg',
  },
  {
    value: 'sunny',
    userName: 'sunny',
    userImg: 'https://mui.com/static/images/avatar/4.jpg',
  },
].map(user => ({
  value: user.value,
  label: <UserUi userName={user.userName} userImg={user.userImg} />,
}));
export const NotificationUnreadData = Array(6).fill({
  title: 'EcoFlush homepage design approval requested by Jatin',
  time: '5 mins ago',
  timeActiveColor: '#3656f4',
  titleActive: '600',
  activeDot: 'flex',
  onClick: () => {},
});
export const NotificationData = [
  {
    topTitle: 'Latest',
    innerData: [
      {
        title: 'EcoFlush homepage design approval requested by Jatin',
        time: 'a few seconds ago',
        timeActiveColor: '#3656f4',
        titleActive: '600',
        activeDot: 'flex',
        onClick: () => {},
      },
      {
        title: 'EcoFlush homepage design approval requested by Jatin',
        time: '5 mins ago',
        timeActiveColor: '#3656f4',
        titleActive: '600',
        activeDot: 'flex',
        onClick: () => {},
      },
    ],
  },
  {
    topTitle: 'Earlier',
    innerData: Array(12).fill({
      title: 'EcoFlush homepage design approval requested by Jatin',
      time: '5 mins ago',
      timeActiveColor: '',
      titleActive: '',
      activeDot: '',
      onClick: () => {},
    }),
  },
];
export const SearchResultAllTypeUIData = [
  {
    TopTitle: 'RECENTLY VIEWED',
    SearchResultData: [
      {
        title: 'GSK Plumbing',
        icon: <ProjectIcon />,
        link: '/',
      },
      {
        title: 'TopNav Task Dropdown , Mode Dropdown , Notification ...',
        icon: <CheckCircleIcon />,
        link: '/',
      },
      {
        title: 'GSK Plumbing',
        icon: <NoteIcon />,
        link: '/',
      },
      {
        title: 'Velaqua Plumbing',
        icon: <MdAlarm />,
        link: '/',
      },
      {
        title: 'Velaqua',
        icon: <RepoTemplateIcon />,
        link: '/',
      },
      {
        title: 'Velaqua Plumbing',
        icon: <TasklistIcon />,
        link: '/',
      },
      {
        title: 'TopNav Task Dropdown , Mode Dropdown , Notification ...',
        icon: <FileDirectoryIcon />,
        link: '/',
      },
      {
        title: 'sunny charkhwal',
        icon: <FaRegUserCircle />,
        link: '/',
      },
    ],
  },
  {
    TopTitle: 'RECENt Keyword SEARCHes',
    SearchResultData: [
      {
        title: 'GSK Plumbing',
        icon: <SearchIcon />,
        link: '/',
      },
      {
        title: 'TopNav Task Dropdown , Mode Dropdown , Notification ...',
        icon: <SearchIcon />,
        link: '/',
      },
      {
        title: 'GSK Plumbing',
        icon: <SearchIcon />,
        link: '/',
      },
      {
        title: 'TopNav Task Dropdown , Mode Dropdown , Notification ...',
        icon: <SearchIcon />,
        link: '/',
      },
      {
        title: 'GSK Plumbing',
        icon: <SearchIcon />,
        link: '/',
      },
      {
        title: 'TopNav Task Dropdown , Mode Dropdown , Notification ...',
        icon: <SearchIcon />,
        link: '/',
      },
      {
        title: 'GSK Plumbing',
        icon: <SearchIcon />,
        link: '/',
      },
      {
        title: 'TopNav Task Dropdown , Mode Dropdown , Notification ...',
        icon: <SearchIcon />,
        link: '/',
      },
      {
        title: 'GSK Plumbing',
        icon: <SearchIcon />,
        link: '/',
      },
      {
        title: 'TopNav Task Dropdown , Mode Dropdown , Notification ...',
        icon: <SearchIcon />,
        link: '/',
      },
    ],
  },
];
export const SearchResultProjectsData = [
  {
    TopTitle: 'RECENTLY VIEWED',
    SearchResultData: [
      {
        title: 'GSK Plumbing',
        icon: <ProjectIcon />,
        link: '/',
      },
      {
        title: 'Velaqua Plumbing',
        icon: <ProjectIcon />,
        link: '/',
      },
      {
        title: 'TopNav Task Dropdown , Mode Dropdown , Notification ...',
        icon: <ProjectIcon />,
        link: '/',
      },
      {
        title: 'sunny charkhwal',
        icon: <ProjectIcon />,
        link: '/',
      },
    ],
  },
];
export const SearchResultTaskData = [
  {
    TopTitle: 'RECENTLY VIEWED',
    SearchResultData: [
      {
        title: 'GSK Plumbing',
        icon: <TasklistIcon />,
        link: '/',
      },
      {
        title: 'Velaqua Plumbing',
        icon: <TasklistIcon />,
        link: '/',
      },
      {
        title: 'TopNav Task Dropdown , Mode Dropdown , Notification ...',
        icon: <TasklistIcon />,
        link: '/',
      },
      {
        title: 'sunny charkhwal',
        icon: <TasklistIcon />,
        link: '/',
      },
    ],
  },
];
export const SearchResultApprovalsData = [
  {
    TopTitle: 'RECENTLY VIEWED',
    SearchResultData: [
      {
        title: 'GSK Plumbing',
        icon: <CheckCircleIcon />,
        link: '/',
      },
      {
        title: 'Velaqua Plumbing',
        icon: <CheckCircleIcon />,
        link: '/',
      },
      {
        title: 'TopNav Task Dropdown , Mode Dropdown , Notification ...',
        icon: <CheckCircleIcon />,
        link: '/',
      },
      {
        title: 'sunny charkhwal',
        icon: <CheckCircleIcon />,
        link: '/',
      },
    ],
  },
];
export const SearchResultReportsData = [
  {
    TopTitle: 'RECENTLY VIEWED',
    SearchResultData: [
      {
        title: 'GSK Plumbing',
        icon: <RepoTemplateIcon />,
        link: '/',
      },
      {
        title: 'Velaqua Plumbing',
        icon: <RepoTemplateIcon />,
        link: '/',
      },
      {
        title: 'TopNav Task Dropdown , Mode Dropdown , Notification ...',
        icon: <RepoTemplateIcon />,
        link: '/',
      },
      {
        title: 'sunny charkhwal',
        icon: <RepoTemplateIcon />,
        link: '/',
      },
    ],
  },
];
export const SearchResultFilesData = [
  {
    TopTitle: 'RECENTLY VIEWED',
    SearchResultData: [
      {
        title: 'GSK Plumbing',
        icon: <FileDirectoryIcon />,
        link: '/',
      },
      {
        title: 'Velaqua Plumbing',
        icon: <FileDirectoryIcon />,
        link: '/',
      },
      {
        title: 'TopNav Task Dropdown , Mode Dropdown , Notification ...',
        icon: <FileDirectoryIcon />,
        link: '/',
      },
      {
        title: 'sunny charkhwal',
        icon: <FileDirectoryIcon />,
        link: '/',
      },
    ],
  },
];
export const SearchResultNotesData = [  
  {
    TopTitle: 'RECENTLY VIEWED',
    SearchResultData: [
      {
        title: 'GSK Plumbing',
        icon: <NoteIcon />,
        link: '/',
      },
      {
        title: 'Velaqua Plumbing',
        icon: <NoteIcon />,
        link: '/',
      },
      {
        title: 'TopNav Task Dropdown , Mode Dropdown , Notification ...',
        icon: <NoteIcon />,
        link: '/',
      },
      {
        title: 'sunny charkhwal',
        icon: <NoteIcon />,
        link: '/',
      },
    ],
  },
];
export const SearchResultReminderData = [
  {
    TopTitle: 'RECENTLY VIEWED',
    SearchResultData: [
      {
        title: 'GSK Plumbing',
        icon: <MdAlarm />,
        link: '/',
      },
      {
        title: 'Velaqua Plumbing',
        icon: <MdAlarm />,
        link: '/',
      },
      {
        title: 'TopNav Task Dropdown , Mode Dropdown , Notification ...',
        icon: <MdAlarm />,
        link: '/',
      },
      {
        title: 'sunny charkhwal',
        icon: <MdAlarm />,
        link: '/',
      },
    ],
  },
];
export const PeopleSearchUiData = new Array(8).fill({
  UserImg:
    'https://maxcrm.projectmaxlence.com.au/uploads/users/avatars/1706852999766-4940.jpeg',
  userName: 'sunny charkhwal',
  userEmailId: 'deependra@maxlence.com.au',
});
export const CreatedByMenuUserData = [
  {
    icon: 'https://maxcrm.projectmaxlence.com.au/uploads/users/avatars/1706852999766-4940.jpeg',
    UserName: 'sunny charkhwal',
  },
  {
    icon: 'https://maxcrm.projectmaxlence.com.au/uploads/users/avatars/1706852999766-4940.jpeg',
    UserName: 'sunny sunny',
  },
];
export const AssignedToMenuUserData = [
  {
    icon: 'https://maxcrm.projectmaxlence.com.au/uploads/users/avatars/1706852999766-4940.jpeg',
    UserName: 'sunny charkhwal',
  },
  {
    icon: 'https://maxcrm.projectmaxlence.com.au/uploads/users/avatars/1706852999766-4940.jpeg',
    UserName: 'sunny',
  },
];
