// reminderSlice.js
import {createSlice} from '@reduxjs/toolkit';

export const ReminderSlice = createSlice({
  name: 'reminder',
  initialState: {
    isReminderDrawerOpen: false,
  },
  reducers: {
    openReminderDrawer: state => {
      state.isReminderDrawerOpen = true;
    },
    closeReminderDrawer: state => {
      state.isReminderDrawerOpen = false;
    },
    toggleReminderDrawer: state => {
      state.isReminderDrawerOpen = !state.isReminderDrawerOpen;
    },
  },
});

export const {openReminderDrawer, closeReminderDrawer, toggleReminderDrawer} =
  ReminderSlice.actions;

export default ReminderSlice.reducer;
