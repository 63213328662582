import ProfileImg from "../img/Img";
import { useSelector } from "react-redux";
import { getUser } from "./../../../../redux/slices/userSlice";
const NavProfile = (props) => {
  const user = useSelector(getUser);
  return (
    <div className="profile_img_top_div" {...props}>
      <div className="profile_img_div">
        <ProfileImg className="profile_img" backgroundImage={user.avatar} />
      </div>
    </div>
  );
};
export default NavProfile;
