import PageTop from '../../../../../common/components/topPageTitle/Index';
import {BsArrowLeft, BsFileMinus, BsTrash} from 'react-icons/bs';
import {useState, useEffect} from 'react';
import Select from 'react-select';
import {useNavigate, useParams} from 'react-router-dom';
import {ProfileImg} from '../../../../../common/ManagementComponents/ProfileImg';
import {
  InputBox,
  SelectBox,
  TextareaBox,
} from '../../../../../common/ManagementComponents/form/Form';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {AiOutlineCheckCircle} from 'react-icons/ai';
import {OutlineBtnWithIcon} from '../../../../../common/ManagementComponents/Btn/Btn';
import {BsPlus} from 'react-icons/bs';
import {FiCircle} from 'react-icons/fi';
import React, {Component} from 'react';
import {BsUpload} from 'react-icons/bs';
import PdfIcon from '../../../../../../pics/Management/new.jpg';
import {DocumentsAttachments} from '../../../../../common/ManagementComponents/DocumentsAttachments';
import FilePreview from '../../../../../common/ManagementComponents/FilePreview';

import {Notify} from 'notiflix/build/notiflix-notify-aio';
import {Block} from 'notiflix/build/notiflix-block-aio';
import {Report} from 'notiflix/build/notiflix-report-aio';
import {Confirm} from 'notiflix/build/notiflix-confirm-aio';
import Axios from './../../../../../../config/api';
import {useForm, Controller, useFieldArray} from 'react-hook-form';
import {StatusCode, getProjectFileUrl} from '../../../../../../constants';
import Photo from '../../../../../../pics/Management/logo-placeholder-image.png';

// mui
import {CgAttachment} from 'react-icons/cg';
import {NewEditor} from '../../../../../common/NewComponents/NewEditor/NewEditor';

export const ClientProfile = () => {
  return (
    <div className="custom_container">
      <div>
        <PageTop
          TitleLink={-1}
          TilelIcon={<BsArrowLeft />}
          Name="Create Client Profile"
        />
      </div>
      <ClientProfileStep />
    </div>
  );
};

export const ClientProfileStep = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (params?.project_id) setCurrentStep(2);

    return () => {};
  }, []);

  const steps = [
    <Step1 setCurrentStep={setCurrentStep} />,
    <Step2 setCurrentStep={setCurrentStep} />,
    <Step3 setCurrentStep={setCurrentStep} />,
    <Step4 />,
  ];

  const getCurrentStepCheckbox = index => {
    if (currentStep === index) return <AiOutlineCheckCircle />;
    else if (currentStep > index) return <AiOutlineCheckCircle />;
    else return <FiCircle className="ClientProfile_normal_dot" />;
  };
  const saveAndMoveStep = () => {
    setCurrentStep(old => old + 1);
    // if (steps.length === currentStep + 1) navigate("/");
  };
  return (
    <div className="custom_container_iner">
      <div className="row"></div>
      <div className="Appraisal_progress_bar_custom_container">
        <div className=" Client_Profile_main_top_div ">
          <div className="Client_Profile_main_top_div_inner">
            <progress
              className="Appraisal_progress_bar Appraisal_progress_bar_man"
              value={(100 / steps.length) * (currentStep + 1)}
              max="100"
              variant="warning">
              32%
            </progress>
          </div>

          <div className="Appraisal_progress_text_bar Appraisal_progress_text_bar_mann">
            <ul className="mb-0 Client_Profile_svg">
              <li>
                <span>{getCurrentStepCheckbox(0)}</span>
                <label>Client Profile</label>
              </li>
              <li>
                <span>{getCurrentStepCheckbox(1)}</span>
                <label>Project Details</label>
              </li>
              <li>
                <span>{getCurrentStepCheckbox(2)}</span>
                <label>Services</label>
              </li>
              <li>
                <span className="Client_Profile_svg_last">
                  {getCurrentStepCheckbox(3)}
                </span>
                <p className="Client_Profile_svg_last_text">Project Leads</p>
              </li>
            </ul>
          </div>
        </div>
        <div>{steps[currentStep]}</div>
        <div>
          <div className=" Client_Profile_btn_div">
            <div>
              <button
                className="management_btn_outline"
                onClick={() =>
                  currentStep
                    ? setCurrentStep(old => old - 1)
                    : navigate('/Management/project_list')
                }>
                Previous
              </button>
            </div>
            <div>
              <div>
                <button
                  className="management_btn_full"
                  // onClick={saveAndMoveStep}
                  type="submit"
                  form={`form_${currentStep}`}
                  // disabled={steps.length === currentStep + 1}
                >
                  {steps.length === currentStep + 1
                    ? 'Save'
                    : 'Save and Continue'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Step1 = ({setCurrentStep}) => {
  const navigate = useNavigate();
  const params = useParams();

  const [serverErrors, setServerErrors] = useState({});
  const [client, setClient] = useState(null);
  const [countries, setCountries] = useState([]);
  const [countriesCodes, setCountriesCodes] = useState([]);

  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm();

  useEffect(async () => {
    fetchCountries();
    if (params?.client_id) fetchClientData();

    return () => {};
  }, []);

  const fetchClientData = () => {
    //showing up loader
    Block.circle('.custom_container_iner', 'Loading...');
    Axios.fetch(`maxproject/client/${params.client_id}`)
      .then(({data}) => {
        setClient(data.client);
        reset(data.client);
        Block.remove('.custom_container_iner');
      })
      .catch(err => {
        //removing loader
        Block.remove('.custom_container_iner');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchCountries = () => {
    //showing up loader
    Block.circle('.custom_container_iner', 'Loading...');
    Axios.fetch(`maxproject/random/get_all_countries`)
      .then(({data}) => {
        let countryList = data?.countries?.map(country => ({
          optionValue: country.id,
          option: country.name,
        }));
        let countryCodeList = data?.countries?.map(country => ({
          optionValue: country.id,
          option: '+' + country.code,
        }));
        setCountries(countryList);
        setCountriesCodes(countryCodeList);
        Block.remove('.custom_container_iner');
      })
      .catch(err => {
        //removing loader
        Block.remove('.custom_container_iner');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleFormSubmit = data => {
    //clearing server errors
    setServerErrors({});
    //showing up loader
    Block.circle('.custom_container_iner', 'Saving...');
    //initiating network request
    const Request = params?.client_id
      ? Axios.put('maxproject/client/' + params.client_id, data)
      : Axios.post('maxproject/client', data);

    Request.then(({data}) => {
      Block.remove('.custom_container_iner');
      Notify.success(data.message);
      if (data.client_id) navigate(String(data.client_id));
      setCurrentStep(1);
    }).catch(err => {
      //removing loader
      Block.remove('.custom_container_iner');
      //checking for any error message
      if (err?.response?.data?.message)
        Notify.failure(err?.response?.data?.message);
      //checking and showing express validation errors
      if (err?.response?.status == StatusCode.ExpressErrors)
        err?.response?.data?.errors.forEach(el =>
          setServerErrors(old => ({...old, [el.param]: el.msg})),
        );
    });
  };
  return (
    <>
      <div className="Client_Profile_title">
        <h3>Client Profile</h3>
      </div>
      <form id="form_0" onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <InputBox
              defaultValue={client?.client_name}
              register={() =>
                register('client_name', {
                  required: 'Please enter client name',
                  value: client?.client_name,
                })
              }
              type="text"
              placeholder="Enter full name of client"
              label="Client Name* (POC/Owner)"
              errorMessage={
                errors.client_name?.message || serverErrors?.client_name
              }
            />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <InputBox
              defaultValue={client?.designation}
              register={() =>
                register('designation', {
                  required: 'Please enter designation or role',
                })
              }
              errorMessage={
                errors.designation?.message || serverErrors?.designation
              }
              type="text"
              placeholder="Designation"
              label="Designation / role"
            />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <InputBox
              defaultValue={client?.org_name}
              register={() =>
                register('org_name', {
                  required: 'Please enter Organization Name',
                })
              }
              errorMessage={errors.org_name?.message || serverErrors?.org_name}
              type="text"
              placeholder="Client's organization name if any available"
              label="Organisation Name*"
            />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="row">
              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4">
                <SelectBox
                  defaultValue={client?.country_code_id}
                  register={() =>
                    register('country_code_id', {
                      required: 'Please enter country code',
                    })
                  }
                  errorMessage={
                    errors.country_code_id?.message ||
                    serverErrors?.country_code_id
                  }
                  label="country"
                  SelectBoxData={countriesCodes}
                />
              </div>
              <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-8 col-sm-8 col-8">
                <InputBox
                  defaultValue={client?.phone}
                  register={() =>
                    register('phone', {
                      required: 'Please enter your phone',
                    })
                  }
                  errorMessage={errors.phone?.message || serverErrors?.phone}
                  type="number"
                  placeholder=" Add contact number"
                  label=" Contact Number*"
                />
              </div>
            </div>
          </div>

          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <InputBox
              defaultValue={client?.client_email}
              register={() =>
                register('client_email', {
                  required: "Please enter client's personal email ID",
                })
              }
              errorMessage={
                errors.client_email?.message || serverErrors?.client_email
              }
              type="text"
              label="Client's Email Address*"
              placeholder="Client's personal email ID"
            />
          </div>

          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <TextareaBox
              defaultValue={client?.address_1}
              register={() =>
                register('address_1', {
                  required: 'Please enter address',
                })
              }
              errorMessage={
                errors.address_1?.message || serverErrors?.address_1
              }
              label="Address Line 1"
              rows="1"
              placeholder="Address line 1"
            />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <TextareaBox
              defaultValue={client?.address_2}
              register={() => register('address_2')}
              label="Address Line 2 (Optional)"
              rows="1"
              placeholder="Address line 2(Optional)"
            />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <InputBox
              defaultValue={client?.city}
              register={() =>
                register('city', {
                  required: 'Please enter city',
                })
              }
              errorMessage={errors.city?.message || serverErrors?.city}
              type="text"
              label="City"
              placeholder="Full Name of your city"
            />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="dashboard_top_week_Select drop_box">
              <SelectBox
                defaultValue={client?.country_id}
                register={() =>
                  register('country_id', {
                    required: 'Please select country_id',
                  })
                }
                errorMessage={
                  errors.country_id?.message || serverErrors?.country_id
                }
                label="country"
                SelectBoxData={countries}
              />
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <InputBox
              defaultValue={client?.abn_no}
              register={() =>
                register('abn_no', {
                  required: 'Please enter ABN no.',
                })
              }
              errorMessage={errors.abn_no?.message || serverErrors?.abn_no}
              type="text"
              label="Business ABN Number*"
              placeholder="Business ABN Number (If country is selected as AU)"
            />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <InputBox
              defaultValue={client?.pin_code}
              register={() =>
                register('pin_code', {
                  required: 'Please enter PIN code',
                })
              }
              errorMessage={errors.pin_code?.message || serverErrors?.pin_code}
              type="text"
              label="ZIP /Postcode"
              placeholder="Your local ZIP/Postcode"
            />
          </div>
        </div>
      </form>
    </>
  );
};
const Step2 = ({setCurrentStep}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [serverErrors, setServerErrors] = useState({});
  const [projectData, setProjectData] = useState({});
  const [attachedFilesPreview, setAttachedFilesPreview] = useState([]);
  const [uploadedAttachments, setUploadedAttachments] = useState([]);
  const [projectImageUrl, setProjectImageUrl] = useState(Photo);

  const [selectValues, setSelectValues] = useState({
    leadSources: [],
    priorities: [],
    statuses: [],
    benchmarks: [],
    currencies: [],
    frequencies: [],
  });

  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    reset,
  } = useForm();

  useEffect(async () => {
    fetchProjectSelectFieldsData();
    if (params?.project_id) fetchProjectData();

    return () => {};
  }, []);
  const fetchProjectData = () => {
    //showing up loader
    Block.circle('.custom_container_iner', 'Loading...');
    Axios.fetch(`maxproject/project/${params.project_id}`)
      .then(({data}) => {
        let payload = {
          ...data.project,
          lead_source: {
            label: data?.project?.LeadSource?.name,
            value: data?.project?.LeadSource?.id,
          },
          priority: {
            label: data?.project?.Priority?.name,
            value: data?.project?.Priority?.id,
          },
          status: {
            label: data?.project?.Status?.name,
            value: data?.project?.Status?.id,
          },
          benchmark: {
            label: data?.project?.Benchmark?.name,
            value: data?.project?.Benchmark?.id,
          },
          frequency: {
            label: data?.project?.Frequency?.name,
            value: data?.project?.Frequency?.id,
          },
          currency_id: data?.project?.Currency?.id,
          brief: data.project.brief,
          objective: data.project.objective,
          scope: data.project.scope,
        };
        setProjectData(payload);
        setProjectImageUrl(getProjectFileUrl(payload.project_image));
        setUploadedAttachments(data.project.ProjectAttachments);
        reset(payload);
        Block.remove('.custom_container_iner');
      })
      .catch(err => {
        //removing loader
        Block.remove('.custom_container_iner');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchProjectSelectFieldsData = () => {
    //showing up loader
    Block.circle('.custom_container_iner', 'Loading...');
    Axios.fetch(`maxproject/random/project_select_fields`)
      .then(({data}) => {
        let leadSources = data?.lead_sources?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        let priorities = data?.priorities?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        let statuses = data?.statuses?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        let benchmarks = data?.benchmarks?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        let frequencies = data?.frequencies?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        let currencies = data?.currencies?.map(el => ({
          optionValue: el.id,
          option: `${el.symbol}(${el.name})`,
        }));
        setSelectValues({
          leadSources,
          priorities,
          benchmarks,
          currencies,
          frequencies,
          statuses,
        });

        Block.remove('.custom_container_iner');
      })
      .catch(err => {
        //removing loader
        Block.remove('.custom_container_iner');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };

  const handleFormSubmit = data => {
    //clearing server errors
    setServerErrors({});
    let formData = new FormData();
    //preparing payload
    let payload = {
      ...data,
      lead_source_id: data.lead_source.value,
      priority_id: data.priority.value,
      status_id: data.status.value,
      benchmark_id: data.benchmark.value,
      frequency_id: data.frequency.value,
      project_image: data.project_image[0],
      brief: projectData.brief,
      objective: projectData.objective,
      scope: projectData.scope,
    };
    Object.keys(payload).forEach(k => formData.append(k, payload[k]));
    if (data?.attachments.length)
      Array.from(data.attachments).forEach(attachment =>
        formData.append('attachments[]', attachment),
      );
    //showing up loader
    Block.circle('.custom_container_iner', 'Saving...');
    //initiating network request
    const Request = params?.project_id
      ? Axios.put('maxproject/project/' + params.project_id, formData, {
          'Content-Type': 'multipart/form-data',
        })
      : Axios.post('maxproject/project/' + params.client_id, formData, {
          'Content-Type': 'multipart/form-data',
        });

    Request.then(({data}) => {
      Block.remove('.custom_container_iner');
      Notify.success(data.message);
      if (data.project_id) navigate(String(data.project_id));
      setCurrentStep(old => old + 1);
    }).catch(err => {
      //removing loader
      Block.remove('.custom_container_iner');
      //checking for any error message
      if (err?.response?.data?.message)
        Notify.failure(err?.response?.data?.message);
      //checking and showing express validation errors
      if (err?.response?.status == StatusCode.ExpressErrors)
        err?.response?.data?.errors.forEach(el =>
          setServerErrors(old => ({...old, [el.param]: el.msg})),
        );
    });
  };
  const onProjectImageChange = e => {
    if (e.target.files && e.target.files[0])
      setProjectImageUrl(URL.createObjectURL(e.target.files[0]));

    // if (e.target.files && e.target.files[0]) {
    //   let reader = new FileReader();
    //   reader.onload = e => {
    //     setProjectImage(e.target.result);
    //     console.log(e.target.result);
    //   };
    //   reader.readAsDataURL(e.target.files[0]);
    // }
  };
  const onAttachmentChange = e => {
    let files = e.target.files;
    if (files) {
      let previews = [];
      Array.from(files).forEach(file =>
        previews.push({
          name: file.name,
          url:
            file.type == 'image/jpeg' || file.type == 'image/png'
              ? URL.createObjectURL(file)
              : PdfIcon,
        }),
      );
      setAttachedFilesPreview(previews);
    }
  };
  const handleDeleteAttachment = (i, file) => {
    Confirm.show(
      `Delete ${file.original_name}`,
      `${file.original_name} will be permanently deleted and can't be recovered.`,
      'Delete',
      'Cancel',
      () => {
        //request to delete file from server
        Axios.delete(`maxproject/attachment/${file.id}`).then(({data}) => {
          Notify.success(data.message);
          setUploadedAttachments(old => {
            let arr = old.slice();
            arr.splice(i, 1);
            return arr;
          });
        });
      },
      () => {},
      {},
    );
  };

  return (
    <>
      <div className="Client_Profile_title">
        <h3>Project Details</h3>
      </div>
      <form
        id="form_1"
        onSubmit={handleSubmit(handleFormSubmit)}
        encType="multipart/form-data">
        <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
          <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 order-md-1 order-2">
              <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
                <div className="col-12">
                  <InputBox
                    defaultValue={projectData?.name}
                    register={() =>
                      register('name', {
                        required: 'Please enter project name',
                        value: projectData?.name,
                      })
                    }
                    errorMessage={errors.name?.message || serverErrors?.name}
                    type="text"
                    label="Project Name*"
                    placeholder="Project Name"
                  />
                </div>
                <div className="col-12">
                  <label className="all_page_my_label_new">Lead Source*</label>
                  <div className="dashboard_top_week_Select drop_box">
                    <Controller
                      name="lead_source"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: 'Please select lead source',
                      }}
                      render={({field}) => (
                        <Select
                          {...field}
                          defaultValue={projectData?.lead_source}
                          onChange={el => field.onChange(el)}
                          options={selectValues.leadSources}
                          placeholder={field.value || 'Select Lead Source'}
                        />
                      )}
                    />
                    <small className="error_message">
                      {errors.lead_source_id?.message ||
                        serverErrors?.lead_source_id}
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 order-md-2 order-1 user_logo_inner_div">
              <div>
                <ProfileImg
                  register={() =>
                    register('project_image', {
                      required: !params?.project_id
                        ? 'Please select project image'
                        : false,
                      onChange: onProjectImageChange,
                    })
                  }
                  errorMessage={
                    errors.project_image?.message || serverErrors?.project_image
                  }
                  Photo={projectImageUrl}
                />
              </div>
            </div>
          </div>

          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <label className="all_page_my_label_new">Priority*</label>
            <div className="dashboard_top_week_Select drop_box">
              <Controller
                name="priority"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Please select project priority',
                }}
                render={({field}) => (
                  <Select
                    {...field}
                    onChange={el => field.onChange(el)}
                    defaultValue={projectData?.priority}
                    options={selectValues.priorities}
                    placeholder={field.value || 'Select Priority'}
                  />
                )}
              />
              <small className="error_message">
                {errors.priority_id?.message || serverErrors?.priority_id}
              </small>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <label className="all_page_my_label_new">Status*</label>
            <div className="dashboard_top_week_Select drop_box">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Please select project status',
                }}
                render={({field}) => (
                  <Select
                    {...field}
                    onChange={el => field.onChange(el)}
                    defaultValue={projectData?.status}
                    options={selectValues.statuses}
                    placeholder={field.value || 'Select status'}
                  />
                )}
              />
              <small className="error_message">
                {errors.status_id?.message || serverErrors?.status_id}
              </small>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <label className="all_page_my_label_new">Benchmark*</label>
            <div className="dashboard_top_week_Select drop_box">
              <Controller
                name="benchmark"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Please select benchmark',
                }}
                render={({field}) => (
                  <Select
                    {...field}
                    onChange={el => field.onChange(el)}
                    defaultValue={projectData?.benchmark}
                    options={selectValues.benchmarks}
                    placeholder={field.value || 'Select Benchmark'}
                  />
                )}
              />
              <small className="error_message">
                {errors.benchmark_id?.message || serverErrors?.benchmark_id}
              </small>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <InputBox
              defaultValue={projectData?.onboarding_date}
              register={() =>
                register('onboarding_date', {
                  required: 'Please enter onboarding date',
                  value: projectData?.onboarding_date,
                })
              }
              errorMessage={
                errors.onboarding_date?.message || serverErrors?.onboarding_date
              }
              type="date"
              label="Onboarding Date*"
              placeholder=""
            />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
              <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-6 col-sm-12 col-12">
                <SelectBox
                  defaultValue={projectData?.currency_id}
                  register={() =>
                    register('currency_id', {
                      required: 'Please select currency',
                      value: projectData?.currency_id,
                    })
                  }
                  errorMessage={
                    errors.currency_id?.message || serverErrors?.currency_id
                  }
                  label="Select Currency*"
                  SelectBoxData={selectValues.currencies}
                />
              </div>
              <div className="col-xxl-8 col-xl-8 col-lg-7 col-md-6 col-sm-12 col-12">
                <InputBox
                  defaultValue={projectData?.service_charge}
                  register={() =>
                    register('service_charge', {
                      required: 'Please enter project service_charge',
                      value: projectData?.service_charge,
                    })
                  }
                  errorMessage={
                    errors.service_charge?.message ||
                    serverErrors?.service_charge
                  }
                  type="number"
                  placeholder="Service Charge*"
                  label="Service Charge*"
                />
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <InputBox
              defaultValue={projectData?.start_date}
              register={() =>
                register('start_date', {
                  required: 'Please enter start date',
                  value: projectData?.start_date,
                })
              }
              errorMessage={
                errors.start_date?.message || serverErrors?.start_date
              }
              type="date"
              label="Project Start Date*"
              placeholder=""
            />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <label className="all_page_my_label_new">Frequency</label>
            <div className="dashboard_top_week_Select drop_box">
              <Controller
                name="frequency"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Please select frequency',
                }}
                render={({field}) => (
                  <Select
                    {...field}
                    onChange={el => field.onChange(el)}
                    defaultValue={projectData?.frequency}
                    options={selectValues.frequencies}
                    placeholder={field.value || 'Select frequency'}
                  />
                )}
              />
              <small className="error_message">
                {errors.frequency_id?.message || serverErrors?.frequency_id}
              </small>
            </div>
          </div>

          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <InputBox
              defaultValue={projectData?.due_date}
              register={() =>
                register('due_date', {
                  required: 'Please enter due date',
                  value: projectData?.due_date,
                })
              }
              errorMessage={errors.due_date?.message || serverErrors?.due_date}
              type="date"
              label="Project Due Date*"
              placeholder=""
            />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
              <div className=" d-flex  col-12">
                <ul className="client_profile_uplod_btn_ul">
                  <li>
                    <p>
                      <span>
                        <CgAttachment />
                      </span>
                      Attachments :
                    </p>
                  </li>
                  <li>
                    <input
                      type="file"
                      style={{display: 'none'}}
                      multiple={true}
                      id="attachemnt_input"
                      {...register('attachments', {
                        required: !params?.project_id
                          ? 'Please select attachments'
                          : false,
                        onChange: onAttachmentChange,
                      })}
                    />
                    <label
                      htmlFor="attachemnt_input"
                      className="management_btn_full upload_document_btn">
                      Upload document
                      <span>
                        <BsUpload />
                      </span>
                    </label>
                    <small className="error_message">
                      {errors.attachments?.message || serverErrors?.attachments}
                    </small>
                  </li>
                </ul>
              </div>
              <div className="col-12">
                <div className="row g-3">
                  {attachedFilesPreview.map((val, i) => (
                    <FilePreview
                      cols="col-4"
                      key={i}
                      name={val.name}
                      url={val.url}
                    />
                  ))}
                </div>
              </div>
              {uploadedAttachments.length ? (
                <div className="col-12">
                  <div className="row g-3">
                    <p>Uploaded Attachments:</p>
                  </div>
                  <div className="row g-3">
                    {uploadedAttachments.map((val, i) => (
                      <DocumentsAttachments
                        cols="col-4"
                        key={i}
                        original_name={val.original_name}
                        url={getProjectFileUrl(val.name)}
                        handleDelete={() => handleDeleteAttachment(i, val)}
                      />
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="col-12">
            <label className="breff_label_add_project">Brief</label>
            <NewEditor
              placeholder="Enter Brief"
              value={projectData?.brief}
              onChange={text => setProjectData(old => ({...old, brief: text}))}
            />
            <div className="Hire_editer">
              <small className="error_message">{serverErrors?.brief}</small>
            </div>
          </div>
          <div className="col-12">
            <label className="breff_label_add_project">Objectives</label>
            <NewEditor
              placeholder="Enter Objectives"
              value={projectData?.objective}
              onChange={text =>
                setProjectData(old => ({...old, objective: text}))
              }
            />
            <div className="Hire_editer">
              <small className="error_message">{serverErrors?.objective}</small>
            </div>
          </div>
          <div className="col-12">
            <label className="breff_label_add_project">Scope</label>
            <NewEditor
              placeholder="Enter Scope"
              value={projectData?.scope}
              onChange={text => setProjectData(old => ({...old, scope: text}))}
            />
            <div className="Hire_editer">
              <small className="error_message">{serverErrors?.scope}</small>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
const Step3 = ({setCurrentStep}) => {
  const params = useParams();
  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    reset,
    setValue,
    getFieldState,
    getValues,
  } = useForm();

  const {fields, append, remove, replace} = useFieldArray({
    control,
    name: 'services',
  });

  const [selectValues, setSelectValues] = useState({
    departments: [],
    currencies: [],
    frequencies: [],
  });

  useEffect(() => {
    fetchServiceSelectFieldsData();
    if (params?.project_id) fetchProjectServices();

    return () => {};
  }, []);

  const fetchServiceSelectFieldsData = () => {
    //showing up loader
    Block.circle('.custom_container_iner', 'Loading...');
    Axios.fetch(`maxproject/random/project_service_select_fields`)
      .then(({data}) => {
        let departments = data?.departments?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        let frequencies = data?.frequencies?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        let currencies = data?.currencies?.map(el => ({
          optionValue: el.id,
          option: `${el.symbol}(${el.name})`,
        }));
        setSelectValues({
          departments,
          currencies,
          frequencies,
        });

        Block.remove('.custom_container_iner');
      })
      .catch(err => {
        //removing loader
        Block.remove('.custom_container_iner');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchProjectServices = () => {
    //showing up loader
    Block.circle('.custom_container_iner', 'Loading...');
    Axios.fetch(`maxproject/project_service/${params.project_id}`)
      .then(({data}) => {
        if (data.services.length) {
          let payload = data.services.map(s => ({
            project_service_id: s.id,
            sub_department: s.Service?.department_id
              ? {
                  value: s.Service?.Department?.id,
                  label: s.Service?.Department?.name,
                }
              : null,
            department: s.Service?.Department?.Department
              ? {
                  value: s.Service?.Department?.Department?.id,
                  label: s.Service?.Department?.Department?.name,
                }
              : null,
            service: s.service_id
              ? {
                  value: s.service_id,
                  label: s.Service?.name,
                }
              : null,
            currency: s?.Currency?.id,
            service_charge: s?.service_charge,
            reporting_date: s?.reporting_date,
            frequency: {
              value: s?.Frequency?.id,
              label: s?.Frequency?.name,
            },
          }));
          replace(payload);
        } else
          replace([
            {
              project_service_id: null,
              department: null,
              sub_department: null,
              service: null,
              currency: null,
              service_charge: null,
              frequency: null,
              reporting_date: null,
            },
          ]);
        Block.remove('.custom_container_iner');
      })
      .catch(err => {
        console.log(err);
        //removing loader
        Block.remove('.custom_container_iner');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleFormSubmit = data => {
    //preparing payload
    let payload = data.services.map(s => ({
      service_id: s.service?.value,
      department_id: s.department?.value,
      sub_department_id: s.sub_department?.value,
      currency_id: s.currency,
      frequency_id: s.frequency?.value,
      project_service_id: s.project_service_id,
      reporting_date: s.reporting_date,
    }));
    console.log(payload);
    //showing up loader
    Block.circle('.custom_container_iner', 'Saving...');
    //initiating network request
    Axios.post(`maxproject/project_service/${params.project_id}`, payload)
      .then(({data}) => {
        Block.remove('.custom_container_iner');
        Notify.success(data.message);
        setCurrentStep(old => old + 1);
      })
      .catch(err => {
        //removing loader
        Block.remove('.custom_container_iner');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const addNewService = () => {
    append({
      project_service_id: null,
      department: null,
      subDepartment: null,
      service: null,
      currency: null,
      budget: null,
      frequency: null,
      reporting_date: null,
    });
  };
  const handleRemoveService = idx => {
    remove(idx);
  };
  const Service = ({index, item}) => {
    const [subDepartments, setSubDepartments] = useState([]);
    const [services, setServices] = useState([]);
    const onDepartmentChange = (department_id, i) => {
      // update(i, {service_charge: 1234});
      setValue(`services.${i}.sub_department`, null);
      setValue(`services.${i}.service`, null);
      //getting sub departmets
      Axios.fetch('maxproject/department/' + department_id)
        .then(({data}) => {
          let sub_dep = data.sub_departments.map(d => ({
            value: d.id,
            label: d.name,
          }));
          setSubDepartments(sub_dep);
        })
        .catch(err => {
          //checking for any error message
          if (err?.response?.data?.message)
            Notify.failure(err?.response?.data?.message);
        });
    };
    const onSubDepartmentChange = (sub_dep_id, i) => {
      setValue(`services.${i}.service`, null);
      //getting services
      Axios.fetch('maxproject/department/services/' + sub_dep_id)
        .then(({data}) => {
          let services = data.services.map(s => ({
            value: s.id,
            label: s.name,
          }));
          setServices(services);
        })
        .catch(err => {
          //checking for any error message
          if (err?.response?.data?.message)
            Notify.failure(err?.response?.data?.message);
        });
    };
    return (
      <div key={item.id} className="col-12 Select_Services_row_new_inner_top">
        <input
          hidden
          {...register(`services.${index}.project_service_id`)}
          type="number"
        />
        <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3  align-items-baseline  Select_Services_row Select_Services_row_new">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
            <div className=" Select_Services_row_new_inner">
              <p>{index}</p>
            </div>

            <label className="all_page_my_label_new">Select Department*</label>
            <div className="dashboard_top_week_Select drop_box">
              <Controller
                name={`services.${index}.department`}
                control={control}
                defaultValue=""
                rules={{
                  required: 'Please select department',
                }}
                render={({field}) => (
                  <Select
                    {...field}
                    onChange={({value, label}) => {
                      field.onChange({value, label});
                      onDepartmentChange(value, index);
                    }}
                    defaultValue={field.value?.label}
                    options={selectValues.departments}
                    placeholder={'Select Department'}
                  />
                )}
              />
              <small className="error_message">
                {errors?.services?.[index]?.department_id?.message}
              </small>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <label className="all_page_my_label_new">
              Select Sub-Department*
            </label>
            <div className="dashboard_top_week_Select drop_box">
              <Controller
                name={`services.${index}.sub_department`}
                control={control}
                defaultValue=""
                rules={{
                  required: 'Please select Sub-Department',
                }}
                render={({field}) => (
                  <Select
                    {...field}
                    onChange={({value, label}) => {
                      field.onChange({value, label});
                      onSubDepartmentChange(value, index);
                    }}
                    defaultValue={field.value?.label}
                    options={subDepartments}
                    placeholder={'Select Sub-Department'}
                  />
                )}
              />
              <small className="error_message">
                {errors?.services?.[index]?.sub_department?.message}
              </small>
            </div>
          </div>
          <div className="col-12 px-4">
            <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
              <div className="col-12">
                <div>
                  <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label className="all_page_my_label_new">Service*</label>
                      <div className="dashboard_top_week_Select drop_box">
                        <Controller
                          name={`services.${index}.service`}
                          control={control}
                          defaultValue=""
                          rules={{
                            required: 'Please select Service',
                          }}
                          render={({field}) => (
                            <Select
                              {...field}
                              onChange={({value, label}) =>
                                field.onChange({value, label})
                              }
                              defaultValue={field.value}
                              options={services}
                              placeholder={'Select Service'}
                            />
                          )}
                        />
                        <small className="error_message">
                          {errors?.services?.[index]?.service?.message}
                        </small>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                          <SelectBox
                            register={() =>
                              register(`services.${index}.currency`, {
                                // required: 'Please select a currency',
                                value: `services.${index}.currency`,
                              })
                            }
                            label="Currency"
                            SelectBoxData={selectValues.currencies}
                          />
                          <small className="error_message">
                            {errors?.services?.[index]?.currency_id?.message}
                          </small>
                        </div>
                        <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                          <InputBox
                            register={() =>
                              register(`services.${index}.service_charge`, {
                                // required: 'Please select service charge',
                                value: `services.${index}.service_charge`,
                              })
                            }
                            type="number"
                            placeholder="Service Charge"
                            label="Service Charge"
                          />
                          <small className="error_message">
                            {errors?.services?.[index]?.service_charge?.message}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <label className="all_page_my_label_new">Frequency</label>
                    <div className="dashboard_top_week_Select drop_box">
                      <Controller
                        name={`services.${index}.frequency`}
                        control={control}
                        defaultValue=""
                        rules={
                          {
                            // required: 'Please select a frequency',
                          }
                        }
                        render={({field}) => (
                          <Select
                            {...field}
                            onChange={val => field.onChange(val)}
                            defaultValue={field.value}
                            options={selectValues.frequencies}
                            placeholder={field.value || 'Select Frequency'}
                          />
                        )}
                      />
                      <small className="error_message">
                        {errors?.services?.[index]?.frequency_id?.message}
                      </small>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <InputBox
                      register={() =>
                        register(`services.${index}.reporting_date`, {
                          // required: 'Please select reporting date',
                          value: `services.${index}.reporting_date`,
                        })
                      }
                      type="date"
                      placeholder="Reporting Date"
                      label="Reporting Date"
                    />
                    <small className="error_message">
                      {errors?.services?.[index]?.reporting_date?.message}
                    </small>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                          <a
                            className="management_btn_outline_with_icon mt-4"
                            onClick={() => handleRemoveService(index)}>
                            <span>Remove Service</span> <BsTrash />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-12">
                  <div className="row">
                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12 ">
                      <OutlineBtnWithIcon
                        name="Add  Service"
                        icon={<BsPlus />}
                      />
                    </div>
                  </div>
                </div> */}
            </div>
          </div>

          <hr className="d-none d-xxl-block d-xl-block d-md-block " />
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="Client_Profile_title">
        <h3>Services</h3>
      </div>
      <form id="form_2" onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
          {/* {services.map((val, i) => (
            <Service key={i} {...val} i={i} state={services[i]} />
          ))} */}
          {fields.map((item, index) => (
            <Service key={index} index={index} item={item} />
          ))}
          <div
            onClick={addNewService}
            className="col-xxl-4 col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 add_Department_btn_div">
            <OutlineBtnWithIcon name="Add More Department" icon={<BsPlus />} />
          </div>
        </div>
      </form>
    </>
  );
};
const Step4 = () => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    reset,
  } = useForm();

  const {fields, append, remove, replace} = useFieldArray({
    control,
    name: 'leads',
  });

  const [departmentUsers, setDepartmentUsers] = useState([]);

  useEffect(() => {
    if (params?.project_id) fetchProjectLeads();

    return () => {};
  }, []);
  const fetchProjectLeads = () => {
    //showing up loader
    Block.circle('.custom_container_iner', 'Loading...');
    const URL = `maxproject/project_service/departments/${params.project_id}?includeDepartmentUsers=1&includeDepartmentLeaders=1`;
    Axios.fetch(URL)
      .then(({data}) => {
        let leads = [],
          departmentUsers = [];
        data.departments.forEach(d => {
          leads.push({
            department: d.Department.name,
            leader: d.ProjectDepartmentLeaders?.map(leader => ({
              label: leader?.Leader?.name,
              value: leader?.leader_id,
            })),
            id: d.id,
          });
          departmentUsers.push(
            d.departmentUsers?.map(u => ({label: u.name, value: u.id})),
          );
        });
        setDepartmentUsers(departmentUsers);
        replace(leads);
        Block.remove('.custom_container_iner');
      })
      .catch(err => {
        console.log(err);
        //removing loader
        Block.remove('.custom_container_iner');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleFormSubmit = data => {
    //preparing payload
    let payload = data.leads.map(d => ({
      department_id: d.id,
      leaders: d.leader?.map(l => l.value),
    }));
    //showing up loader
    Block.circle('.custom_container_iner', 'Saving...');
    //initiating network request
    Axios.post(
      `maxproject/project_service/departments/${params.project_id}`,
      payload,
    )
      .then(({data}) => {
        Block.remove('.custom_container_iner');
        Notify.success(data.message);
        Report.success(
          'Hurray',
          'You have completed all of the steps!',
          'Okay',
        );
        navigate('/Management/project_list');
      })
      .catch(err => {
        //removing loader
        Block.remove('.custom_container_iner');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const Leader = ({index, item}) => {
    return (
      <div className="col-12 Select_Services_row_new_inner_top">
        <div className="row    Select_Services_row Select_Services_row_new">
          <div className="col-12 ">
            <div className=" Select_Services_row_new_inner">
              <p>{index}</p>
            </div>
            <p className="project_leads">{item.department}</p>
            <label className="all_page_my_label_new">Leads*</label>
            <div className="dashboard_top_week_Select drop_box drop_box2">
              <input hidden {...register(`leads.${index}.id`)} />
              <Controller
                name={`leads.${index}.leader`}
                control={control}
                defaultValue=""
                rules={{
                  required: 'Please select a leader',
                }}
                render={({field}) => (
                  <Select
                    {...field}
                    onChange={val => field.onChange(val)}
                    defaultValue={field.value}
                    options={departmentUsers[index]}
                    placeholder={'Select Leads*'}
                    isMulti
                  />
                )}
              />
            </div>
            <small className="error_message">
              {errors?.leads?.[index]?.leader?.message}
            </small>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="Client_Profile_title">
        <h3>Project Leads</h3>
      </div>
      <form id="form_3" onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="m_t">
          <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
            {fields.map((item, index) => (
              <Leader key={index} index={index} item={item} />
            ))}
          </div>
        </div>
      </form>
    </>
  );
};
