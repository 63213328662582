import {ChevronLeftIcon, XIcon} from '@primer/octicons-react';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {closeReminderDrawer} from '../../../../../../redux/slices/ReminderSlice';
import {Button} from '@mui/material';
import {NewBootstrapTooltip} from '../../../../../common/NewComponents/NewTooltip/NewTooltip';
const IconWithTooltip = ({IconComponent, title, onClick}) => (
  <NewBootstrapTooltip
    ChildComponent={
      <span className="icon_btn" onClick={onClick}>
        {IconComponent}
      </span>
    }
    title={title}
    placement="bottom"
  />
);
export const AddReminderHeader = ({setView}) => {
  const dispatch = useDispatch();
  const handleQuickNoteClose = () => {
    dispatch(closeReminderDrawer());
  };
  return (
    <div className="quick_note_header_div position-relative pb-0">
      <div className="row m-0">
        <div className="col-6 p-0 d-flex justify-content-start icon_btn_div">
          <Button variant="text" onClick={() => setView('default')}>
            <ChevronLeftIcon /> Back
          </Button>
        </div>
        <div className="col-6 p-0 d-flex justify-content-end icon_btn_div">
          <IconWithTooltip
            IconComponent={<XIcon />}
            title="Close"
            onClick={handleQuickNoteClose}
          />
        </div>
      </div>
    </div>
  );
};
