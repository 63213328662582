// store.js
import {configureStore, combineReducers} from '@reduxjs/toolkit';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import UserReducer from './slices/userSlice';
import UserActivityReducer from './slices/userActivitySlice';
import GlobalReducer from './slices/globalSlice';
import ProfileDropdownReducer from './slices/ProfileDropdownConfigSlice';
import drawerNotificationReducer from './slices/NotificationSlice';
import modalSearchBoxReducer from './slices/SearchBoxSlice';
import drawerQuickNoteReducer from './slices/QuickNoteSlice';
import reminderReducer from './slices/ReminderSlice';
import assignToModalReducer from './slices/AssignToModalSlice';
// Redux Persist configuration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'userActivity', 'global'], // Specify which slices of state to persist (you can modify this)
};

// Combine all reducers into a root reducer
const rootReducer = {
  user: UserReducer,
  userActivity: UserActivityReducer,
  global: GlobalReducer,
  profileDropdown: ProfileDropdownReducer,
  drawerNotification: drawerNotificationReducer,
  modalSearchBox: modalSearchBoxReducer,
  drawerQuickNote: drawerQuickNoteReducer,
  reminder: reminderReducer,
  modal: assignToModalReducer,
};

// Create a persisted reducer
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers(rootReducer),
);

// Create the Redux store
const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializability check for Redux Persist
    }),
});

// Create the persistor
const persistor = persistStore(store);

export {store, persistor};
