import {useState} from 'react';
import {Routes, Route} from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar';
import Container from '../components/Container/Container';
import {TopNavbar} from '../components/TopNavbar/TopNavbar';
import Dashboard from '../page/Dashboard';
import {Benchmark} from '../page/Benchmark';

import NavContext from '../Context/NavContext';
import {ClientProfile} from '../components/pages/Dashboard/page/ClientProfilePage';
import {ProjectList} from '../components/pages/Dashboard/page/ProjectList';
import {ProjectDetails} from '../components/pages/Dashboard/page/ProjectDetails';
import {AssignProject} from '../components/pages/Dashboard/page/AssignProject';
import {CreateTask} from '../components/pages/Dashboard/page/CreateTask';
import {Tasks} from '../page/Tasks';
import {ViewAllTasks} from '../components/pages/Tasks/ViewAllTasks';
import {Meetings} from '../components/pages/Meetings/Meetings';
import {MeetingsNotes} from '../components/pages/Meetings/MeetingsNotes';
import {Approvals} from '../components/pages/Approvals/Approvals';
import {Clients} from '../components/pages/Clients/Clients';
import {ClientsSms} from '../components/pages/ClientSms/SmsList';
import {ClientsProfile} from '../components/pages/Clients/ClientProfile';
import {ClientsSmsForm} from '../components/pages/Clients/ClientSMSForm';
import {ClientProfileDetails} from '../components/pages/Clients/ClientProfileDetails';
import {NotificationsAll} from '../page/NotificationsAll';
import {JoinMeeting} from '../components/pages/Meetings/JoinMeeting';
import {Chats} from '../page/Chats';
import {Reports} from '../page/Reports';
import {Settings} from '../components/pages/Settings/Settingss';
import {Help} from '../page/Help';
import {EditNotes} from '../components/pages/Meetings/EditNotes';
// import {SearchResult} from '../components/TopNavbar/Submenus/SearchBar/SearchResult';
import {SentApprovalList} from '../components/pages/Approvals/SentApprovalList.jsx';
import {ReceivedApprovalList} from '../components/pages/Approvals/ReceivedApprovalList.jsx';

import {ScheduleMeeting} from '../components/pages/Meetings/ScheduleMeeting';
import {Notes} from '../components/pages/Notes/Notes';
import {NotesDetails} from '../components/pages/Notes/NotesDetails';
import {NewNotes} from '../components/pages/Notes/NewNotes';
import {MyTimesheet} from '../components/pages/Timesheet/MyTimesheet';
import {UserTimesheetDetail} from '../components/pages/Timesheet/UserTimesheetDetail.jsx';
import {AllTimesheet} from '../components/pages/Timesheet/AllTimesheet';
import {MyTimesheetProjectDetail} from '../components/pages/Timesheet/MyTimesheetProjectDetail';
import {MyTimesheetOtherTasksDetail} from '../components/pages/Timesheet/MyTimesheetOtherTasksDetail.jsx';
import {UserTimesheetOtherTasksDetail} from '../components/pages/Timesheet/UserTimesheetOtherTasksDetail.jsx';
import {UserTimesheetProjectDetail} from '../components/pages/Timesheet/UserTimesheetProjectDetail.jsx';

const Index = () => {
  const [nav, setNav] = useState(true);
  const value = {nav, setNav};
  return (
    <div className="App">
      <NavContext.Provider value={value}>
        <Navbar />
        <Container
          stickyNav={<TopNavbar />}
          content={
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/add_project" element={<ClientProfile />} />
              <Route
                path="/add_project/:client_id"
                element={<ClientProfile />}
              />
              <Route
                path="/add_project/:client_id/:project_id"
                element={<ClientProfile />}
              />
              <Route path="/benchmark" element={<Benchmark />} />
              <Route path="/project_list" element={<ProjectList />} />
              <Route
                path="/project_details/:project_id"
                element={<ProjectDetails />}
              />
              <Route
                path="/assign_project/:project_id"
                element={<AssignProject />}
              />
              <Route
                path="/create_client_task/:client_id"
                element={<CreateTask type="client" />}
              />
              <Route path="/create_task" element={<CreateTask />} />
              <Route
                path="/update_client_task/:task_id"
                element={<CreateTask isUpdate={true} type="client" />}
              />
              <Route
                path="/update_task/:task_id"
                element={<CreateTask isUpdate={true} type="internal" />}
              />
              <Route
                path="/update_other_task/:task_id"
                element={<CreateTask isUpdate={true} type="other" />}
              />
              <Route path="/tasks" element={<Tasks />} />
              <Route path="/view_all_tasks" element={<ViewAllTasks />} />
              <Route path="/meetings" element={<Meetings />} />
              {/* <Route
                path="/meeting_notes/:meeting_id"
                element={<MeetingsNotes />}
              /> */}
              <Route path="/approvals" element={<Approvals />} />
              <Route path="/clients" element={<Clients />} />
              {/* <Route path="/client_sms" element={<ClientsSms />} /> */}
              {/* <Route path="/client_sms/add" element={<ClientsSmsForm />} /> */}
              <Route
                path="/client_sms/:client_id"
                element={<ClientsSmsForm />}
              />
              <Route path="/clients/add" element={<ClientsProfile />} />
              <Route
                path="/clients/edit/:client_id"
                element={<ClientsProfile />}
              />
              <Route
                path="/clients/:client_id"
                element={<ClientProfileDetails />}
              />
              <Route path="/all_notifications" element={<NotificationsAll />} />
              <Route path="/meeting/:meeting_id" element={<JoinMeeting />} />

              <Route path="/chats" element={<Chats />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/help" element={<Help />} />
              <Route path="/edit_notes" element={<EditNotes />} />
              {/* <Route path="/search_result" element={<SearchResult />} /> */}
              <Route path="/schedule_meeting" element={<ScheduleMeeting />} />
              <Route
                path="/update_meeting/:meeting_id"
                element={<ScheduleMeeting />}
              />
              <Route
                path="/update_meeting/:meeting_id"
                element={<ScheduleMeeting />}
              />
              <Route path="/meeting_notes" element={<Notes />} />
              <Route
                path="/meeting_notes/:meeting_note_id"
                element={<NotesDetails />}
              />
              <Route path="/new_meeting_note" element={<NewNotes />} />
              <Route
                path="/sent_approval/:approval_id"
                element={<SentApprovalList />}
              />
              <Route
                path="/received_approval/:approval_id"
                element={<ReceivedApprovalList />}
              />
              <Route path="/my_timesheet" element={<MyTimesheet />} />
              <Route path="/all_timesheet" element={<AllTimesheet />} />
              <Route
                path="/my_timesheet_Detail/project/:project_id"
                element={<MyTimesheetProjectDetail />}
              />
              <Route
                path="/my_timesheet_Detail/other_tasks"
                element={<MyTimesheetOtherTasksDetail />}
              />
              <Route
                path="/user_timesheet_detail/:user_id/other_tasks"
                element={<UserTimesheetOtherTasksDetail />}
              />
              <Route
                path="/user_timesheet_Detail/:user_id/project/:project_id"
                element={<UserTimesheetProjectDetail />}
              />
              <Route
                path="/user_timesheet_detail/:user_id"
                element={<UserTimesheetDetail />}
              />

              <Route path="*" element={<main>NOT FOUND</main>} />
            </Routes>
          }
        />
      </NavContext.Provider>
    </div>
  );
};
export default Index;
