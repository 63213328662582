import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Menu, MenuItem} from '@material-ui/core';
import ChevronDownIcon from '@material-ui/icons/ExpandMore';

export const SearchStatusUi = ({cb = () => {}, selectedStatus = null}) => {
  const storData = useSelector(state => state);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = option => {
    cb(option);
    handleClose();
  };

  return (
    <div>
      <p
        id="basic-button"
        aria-controls={anchorEl ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={!!anchorEl}
        onClick={handleClick}
        className="menu_btn_div">
        {selectedStatus || 'Any'}
        <ChevronDownIcon />
      </p>
      <Menu
        data-theme={storData.global.isMode}
        className="timesheet_status_filter_div"
        id="basic-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {['open', 'close', 'hold'].map(option => (
          <MenuItem key={option} onClick={() => handleMenuItemClick(option)}>
            <div className="menu_item_div">
              <span>{option}</span>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
