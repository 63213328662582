import React, {useEffect, useRef, useState} from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {createEventId} from './event-utils';
import './style.css';

export default ({events, onNewEventChangeCb, setActiveTab}) => {
  const calendarRef = useRef(null);
  const [weekendsVisible, setWeekendsVisible] = useState(true);

  // useEffect(() => {
  //   console.log(activeTab);
  //   // setTimeout(() => {
  //   //   const calendarApi = calendarRef.current.getApi();
  //   //   calendarApi.render();
  //   // }, 6000);
  // }, [activeTab]);

  const handleDateSelect = selectInfo => {
    let calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    onNewEventChangeCb({
      id: createEventId(),
      title: 'New Meeting',
      start: selectInfo.startStr,
      end: selectInfo.endStr,
      editable: true,
      className: 'newEvent',
      backgroundColor: 'orange',
    });
  };
  const handleEventClick = e => {
    console.log(e);
  };
  const handleEventChange = ({event}) => {
    onNewEventChangeCb({
      id: createEventId(),
      title: 'New Meeting',
      start: event.startStr,
      end: event.endStr,
      editable: true,
      className: 'newEvent',
      backgroundColor: 'orange',
    });
  };

  return (
    <div className="dashbaord_white_card meeting_calendar_top_div">
      <div>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          ref={calendarRef}
          initialView="timeGridWeek"
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          contentHeight={'auto'}
          weekends={weekendsVisible}
          events={events}
          select={handleDateSelect}
          eventClassNames={'calendarEvents'}
          eventContent={renderEventContent} // custom render function
          eventClick={handleEventClick}
          eventChange={handleEventChange}
          responsive={true}

          // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
          /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventRemove={function(){}}
            */
        />
      </div>
    </div>
  );
};
const renderEventContent = eventInfo => {
  return (
    <>
      <i>{eventInfo.event.title}</i>
      <br />
      <b>{eventInfo.timeText}</b>
    </>
  );
};
// defaultView="month"
// responsive={{
//   xs: 'agendaDay',
//   sm: 'agendaWeek',
//   md: 'month',
// }}
// touchUi={true}
