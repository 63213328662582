import * as React from 'react';
import Img from '../components/img/Img';
export default ({name, cols, url}) => {
  return (
    <div className={cols}>
      <div className="Attachments_top_div">
        <div>
          <Img className="doc_img" backgroundImage={url} />
          <div>
            <p>{name}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
