import React, {useState, useEffect, useRef} from 'react';
import PaintBrushIcon from '../../../../../../../pics/Management/brush.svg';
import {NewBootstrapTooltip} from '../../../../../../common/NewComponents/NewTooltip/NewTooltip';
import {
  ArchiveIcon,
  ChevronLeftIcon,
  PersonAddIcon,
  ScreenFullIcon,
  XIcon,
} from '@primer/octicons-react';
import {Button} from '@mui/material';
import NoteThemIcon from '../../../../../../../pics/Management/noteThemIcon.png';
import {AddNotesMenu} from './AddNotesMenu';
import {ColorBtn} from '../../QuickNoteList/ColorBtn';

import {closeQuickNoteDrawer} from '../../../../../../../redux/slices/QuickNoteSlice';
import {useDispatch} from 'react-redux';

const IconWithTooltip = ({IconComponent, title, onClick}) => (
  <NewBootstrapTooltip
    ChildComponent={
      <span className="icon_btn" onClick={onClick}>
        {IconComponent}
      </span>
    }
    title={title}
    placement="bottom"
  />
);

export const AddNotesHeader = ({
  onBackClick,
  onSettingsClick,
  AssignTo,
  handleColorChange,
  handleReset,
  onNotesInnerPageClick,
  handleArchiveUnarchiveNote,
  handleDelete,
}) => {
  const dispatch = useDispatch();
  const [isVisibleColorDiv, setIsVisibleColorDiv] = useState(false);

  const divRef = useRef(null);

  const toggleColorDivVisibility = () => setIsVisibleColorDiv(prev => !prev);

  const handleClickOutside = event => {
    if (divRef.current && !divRef.current?.contains(event.target)) {
      setIsVisibleColorDiv(false);
    }
  };

  useEffect(() => {
    if (isVisibleColorDiv) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isVisibleColorDiv]);
  //
  const handleQuickNoteClose = () => {
    dispatch(closeQuickNoteDrawer());
  };
  return (
    <div className="quick_note_header_div position-relative">
      {isVisibleColorDiv && (
        <div
          ref={divRef}
          className="top_nav_note_visible_color_div"
          style={{top: '50px'}}>
          <NewBootstrapTooltip
            ChildComponent={
              <span
                className="top_nav_note_visible_color_div_btn"
                onClick={() => {
                  setIsVisibleColorDiv(false);
                }}>
                <XIcon className="close_btn" />
              </span>
            }
            title="Close"
            placement="bottom"
          />
          <NewBootstrapTooltip
            ChildComponent={
              <span
                className="top_nav_note_visible_color_div_btn"
                onClick={handleReset}>
                <img className="img_1" src={PaintBrushIcon} alt="icon" />
              </span>
            }
            title="Default"
            placement="bottom"
          />
          <ColorBtn divRef={divRef} handleColorChange={handleColorChange} />
        </div>
      )}
      <div className="row m-0">
        <div className="col-6 p-0 d-flex justify-content-start icon_btn_div">
          <Button variant="text" onClick={onBackClick}>
            <ChevronLeftIcon /> Back
          </Button>
        </div>
        <div className="col-6 p-0 d-flex justify-content-end icon_btn_div">
          <IconWithTooltip
            IconComponent={<PersonAddIcon />}
            title="Assign to"
            onClick={AssignTo}
          />
          <IconWithTooltip
            IconComponent={<img src={NoteThemIcon} alt="icon" />}
            title="Add to Theme"
            onClick={toggleColorDivVisibility}
          />
          <IconWithTooltip
            IconComponent={<ArchiveIcon />}
            title="Add to archive"
            onClick={() => handleArchiveUnarchiveNote(true)}
          />
          <AddNotesMenu
            onSettingsClick={onSettingsClick}
            AssignTo={AssignTo}
            handleDelete={handleDelete}
          />
          <IconWithTooltip
            IconComponent={<ScreenFullIcon />}
            title="Full Screen"
            onClick={() => {
              onNotesInnerPageClick();
            }}
          />
          <IconWithTooltip
            IconComponent={<XIcon />}
            title="Close"
            onClick={handleQuickNoteClose}
          />
        </div>
      </div>
    </div>
  );
};
