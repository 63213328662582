import {AiOutlineSearch} from 'react-icons/ai';
export const InputBox = props => {
  return (
    <>
      <label className="all_page_my_label_new">{props.label}</label>
      <input
        {...(props.register && props.register())}
        type={props.type}
        className="form-control intput_box "
        placeholder={props.placeholder}
        // defaultValue={"Dummy"}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        min={props?.min}
        max={props?.max}
      />
      <small className="error_message">{props.errorMessage}</small>
    </>
  );
};
export const TextareaBox = props => {
  return (
    <>
      <label className="all_page_my_label_new ">{props.label}</label>
      <textarea
        disabled={props.disabled}
        {...(props.register && props.register())}
        className="form-control textareaBox_box"
        rows={props.rows}
        placeholder={props.placeholder}
        // defaultValue={"Dummy"}
        defaultValue={props.defaultValue}></textarea>
      <small className="error_message">{props.errorMessage}</small>
    </>
  );
};
export const SelectBox = props => {
  return (
    <>
      <label className="all_page_my_label_new ">{props.label}</label>
      <select
        disabled={props.disabled}
        {...(props.register && props.register())}
        className="form-select SelectBox_box text-capitalize">
        <option value="">Select</option>
        {props.SelectBoxData?.map((val, i) => (
          <option
            key={i}
            selected={val?.optionValue == props?.defaultValue}
            value={val.optionValue}>
            {val.option}
          </option>
        ))}
      </select>
      <small className="error_message">{props.errorMessage}</small>
    </>
  );
};
export const CheckBox = props => {
  return (
    <div className="form-check">
      <input
        disabled={props.disabled}
        {...(props.register && props.register())}
        className="form-check-input"
        type="checkbox"
        id={props.id}
      />
      <label className="form-check-label checkbox_label" htmlFor={props.id}>
        {props.text}
      </label>
      <small className="error_message">{props.errorMessage}</small>
    </div>
  );
};

export const SearchBox = props => {
  return (
    <div className="Filters_Search_div">
      <span>
        <AiOutlineSearch />
      </span>
      <input
        disabled={props.disabled}
        {...(props.register && props.register())}
        type="text"
        className="form-control"
        placeholder={props.placeholder}
      />
      <small className="error_message">{props.errorMessage}</small>
    </div>
  );
};
