import { Chat } from "../components/pages/chat/Chat";
export const Chats = () => {
  return (
    <>
      <div className="custom_container">
        <Chat />
      </div>
    </>
  );
};
