import {OutlineBtnWithIcon} from '../../../../common/ManagementComponents/Btn/Btn';
import {FaFilter} from 'react-icons/fa';
import {PageAllTopTitle} from '../../../../common/ManagementComponents/PageAllTopTitle';
import {
  FullBtn,
  OutlineBtn,
} from '../../../../common/ManagementComponents/Btn/Btn';
import {IoSearchOutline} from 'react-icons/io5';
import {useNavigate} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import {BiDotsHorizontalRounded} from 'react-icons/bi';
import {useState, useEffect} from 'react';
import {BsFillInfoCircleFill} from 'react-icons/bs';
// mui
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {NavLink} from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Axios from './../../../../../config/api';
import {Notify} from 'notiflix/build/notiflix-notify-aio';
import {Block} from 'notiflix/build/notiflix-block-aio';
import {useNonInitialEffect} from '../../../../../utils/hooks/non-initial-effect.tsx';
import {useSelector} from 'react-redux';
import {getUser} from '../../../../../redux/slices/userSlice';
import {PageRightFilters} from '../../../../common/ManagementComponents/PageRightFilters';

// index
export const Clients = () => {
  const [clients, setClients] = useState([]);
  const [query, setQuery] = useState('');
  const [permissions, setPermissions] = useState({});
  const [smsPermissions, setSmsPermissions] = useState({});
  const user = useSelector(getUser);

  useEffect(() => {
    getClients();
    return () => {};
  }, []);

  useNonInitialEffect(() => {
    // Timer ID for the delayed fetch
    let timerId;
    // Clear previous timer, if any
    clearTimeout(timerId);
    // Set a new timer for the delayed fetch
    timerId = setTimeout(getClients, 500);
    // Clean up timer on component unmount
    return () => clearTimeout(timerId);
  }, [query]);

  const getClients = () => {
    Block.circle('.clients_table');
    Axios.fetch(`maxproject/client/list?query=${query}`)
      .then(({data}) => {
        Block.remove('.clients_table');
        setClients(data?.clients);
        setPermissions(data?.Permissions);
        setSmsPermissions(data?.ClientSmsPermissions);
      })
      .catch(err => {
        Block.remove('.clients_table');
      });
  };

  const handleSearch = e => {
    setQuery(e.target.value);
  };
  const ClientsList = () => {
    const [page, setPage] = useState(1);

    const handleTaskPagination = (e, page) => {
      setPage(page);
    };

    const Client = props => {
      const [showDelete, setShowDelete] = React.useState(false);
      const [isClientActive, setIsClientActive] = React.useState(
        props?.isActive,
      );
      // Delete Modal
      const handleOpen = () => setShowDelete(true);
      const handleClose = () => setShowDelete(false);
      // Delete Modal end
      const handleDeleteClient = () => {
        Block.circle('.delete_modal');
        Axios.delete('/maxproject/client/' + props.id)
          .then(({data}) => {
            handleClose();
            setClients(old => {
              let arr = old.slice();
              arr.splice(props.index, 1);
              return arr;
            });
            Block.remove('.delete_modal');
            Notify.success(data.message);
          })
          .catch(err => {
            //removing loader
            Block.remove('.delete_modal');
            //checking for any error message
            if (err?.response?.data?.message)
              Notify.failure(err?.response?.data?.message);
          });
      };
      const handleEnableDisable = () => {
        Block.circle('.progress_all_tabel_tabel');
        Axios.put(`/maxproject/client/${props.id}/active_status`, {
          status: isClientActive ? 0 : 1,
        })
          .then(({data}) => {
            setIsClientActive(!isClientActive);
            Block.remove('.progress_all_tabel_tabel');
            Notify.success(data.message);
          })
          .catch(err => {
            //removing loader
            Block.remove('.progress_all_tabel_tabel');
            //checking for any error message
            if (err?.response?.data?.message)
              Notify.failure(err?.response?.data?.message);
          });
      };
      const ClientsListDropDown = () => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const handleClick = event => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };
        const navigate = useNavigate();
        const options = [
          {link: '/Management/clients/' + props.id, name: 'View'},
          {link: '/Management/clients/edit/' + props.id, name: 'Edit'},
          {
            onClick: handleOpen,
            name: 'Delete',
            disabled: !permissions?.delete,
          },
          {
            onClick: handleEnableDisable,
            name: isClientActive ? 'Disable' : 'Enable',
            disabled: !permissions?.put,
          },
          {
            link: '/Management/client_sms/' + props.id,
            name: 'SMS',
            disabled: !smsPermissions?.get,
          },
          {
            // link: '/Management/chats',
            name: 'Chat',
            disabled: true,
          },
        ];

        const ITEM_HEIGHT = 30;
        return (
          <div className="chat_option">
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              className=" m-0 p-0">
              <BiDotsHorizontalRounded />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '15ch',
                },
              }}>
              {options.map((option, i) => (
                <div
                  key={i}
                  onClick={() =>
                    option?.disabled ? null : navigate(option.link)
                  }>
                  <MenuItem disabled={option?.disabled} onClick={handleClose}>
                    <div
                      onClick={option.onClick}
                      className="chat_option_name_div">
                      {option.name}
                    </div>
                  </MenuItem>
                </div>
              ))}
              <MenuItem>
                <a
                  href={`mailto:${props?.client_email}`}
                  target="_blank"
                  className="chat_option_name_div">
                  E-Mail
                </a>
              </MenuItem>
            </Menu>
          </div>
        );
      };
      const DeleteModal = () => {
        const style = {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        };
        return (
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showDelete}
            onClose={handleClose}
            closeAfterTransition>
            <Fade in={showDelete}>
              <Box sx={style}>
                <div className="delete_modal">
                  <ul>
                    <li>
                      <BsFillInfoCircleFill />
                    </li>
                    <li>
                      <h3>Delete "{props?.client_name}"?</h3>
                      <p>
                        "{props?.client_name}" Client will be deleted
                        permanently
                      </p>
                    </li>
                  </ul>
                  <div className="delete_modal_btn_div">
                    <ul>
                      <li className="btn_one">
                        <OutlineBtn onClick={handleClose} name="Cancel" />
                      </li>
                      <li className="btn_two">
                        <FullBtn onClick={handleDeleteClient} name="Delete" />
                      </li>
                    </ul>
                  </div>
                </div>
              </Box>
            </Fade>
          </Modal>
        );
      };
      return (
        <tr
          className="progress_all_tabel_tr_mor"
          style={{backgroundColor: !isClientActive ? '#eeeeee' : null}}>
          <DeleteModal />
          <td className="fw-normal">
            <NavLink to={`/Management/clients/${props?.id}`} className="link_a">
              {(page - 1) * 10 + props.index + 1}
              {' - '}
              {props?.client_name}
            </NavLink>
          </td>
          <td className="fw-normal">{props?.isActive ? 'Active': 'In-active'}</td>
          <td className="fw-normal">{props?.designation}</td>
          <td className="fw-bold">{props?.org_name}</td>
          <td className="fw-normal">
            <a href={`tel:+${props?.CountryCode?.code}${props?.phone}`}>
              {'+' + props?.CountryCode?.code + '-' + props?.phone}
            </a>
          </td>
          <td className="fw-normal">
            <a href={`mailto:${props?.client_email}`}>{props?.client_email}</a>
          </td>
          <td className="fw-normal">{props?.city}</td>
          <td className="fw-normal">{props?.Country?.name}</td>
          <td className="fw-normal">
            <div>
              <div className="progress_all_option_icon">
                <ClientsListDropDown />
              </div>
            </div>
          </td>
        </tr>
      );
    };
    const ClientsTable = () => {
      return (
        <>
          <div>
            <table className="table table-hover text-capitalize progress_all_tabel_tabel">
              <thead>
                <tr className="progress_all_tabel_tr">
                  <th scope="col">Client Name</th>
                  <th scope="col">Status</th>
                  <th scope="col">Designation</th>
                  <th scope="col">Project</th>
                  <th scope="col">Contact No</th>
                  <th scope="col">Email Id</th>
                  <th scope="col">City</th>
                  <th scope="col">Country</th>
                  <th scope="col">action</th>
                </tr>
              </thead>
              <tbody>
                {clients?.slice((page - 1) * 10, 10 * page)?.map((val, i) => (
                  <Client key={i} index={i} {...val} />
                ))}
              </tbody>
            </table>
          </div>
          {clients?.length > 10 ? (
            <div className="projec_tabelt_list_pagination_div">
              <Stack spacing={2}>
                <Pagination
                  onChange={handleTaskPagination}
                  count={Math.ceil(clients?.length / 10)}
                />
              </Stack>
            </div>
          ) : null}
        </>
      );
    };
    return (
      <div className="dashbaord_white_card">
        {clients.length ? ClientsTable() : <span>No result found</span>}
      </div>
    );
  };
  return (
    <div className="custom_container">
      <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3  header_my">
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-2 col-sm-12 col-12 ">
          <PageAllTopTitle title="Clients" />
        </div>
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-7 col-sm-12 col-12 ">
          <div className="header_input_div">
            <IoSearchOutline />
            <input
              type="search"
              value={query}
              onChange={handleSearch}
              className="form-control"
              placeholder="Search client name here"
            />
          </div>
        </div>
        {permissions?.post ? (
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12 header_btn ">
            <div className="cl_new_btn">
              <FullBtn link="/Management/clients/add" name="Add New Client" />
            </div>
          </div>
        ) : null}
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12   ">
          <div className="row justify-content-end">
            <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 filter_btn_top_new ">
              <PageRightFilters />
            </div>
          </div>
        </div>
        <div className="col-12 clients_table">
          <ClientsList />
        </div>
      </div>
    </div>
  );
};
