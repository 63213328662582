import {CheckIcon, CopyIcon} from '@primer/octicons-react';
import {Avatar, Skeleton} from 'antd';
import {useRef, useState} from 'react';
import {PeopleSearchUiData} from '../Data';

export const PeopleSearchUi = ({loading, data}) => {
  const [copySuccess, setCopySuccess] = useState('');
  const textRef = useRef(null);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textRef.current.innerText);
      setCopySuccess('Copied!');
    } catch {
      setCopySuccess('Failed to copy!');
    }
    setTimeout(() => setCopySuccess(''), 1500);
  };

  const stringToColor = string => {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    return `#${[0, 1, 2]
      .map(i => `00${((hash >> (i * 8)) & 0xff).toString(16)}`.slice(-2))
      .join('')}`;
  };

  const stringAvatar = name => ({
    style: {backgroundColor: stringToColor(name)},
    children: name
      .split(' ')
      .map(n => n[0])
      .join(''),
  });

  return (
    <div className="top_nav_search_all_scroll">
      <div className="top_nav_search_all_inner_scroll">
        <div className="top_nav_search_all">
          {data.map((user, index) => (
            <ul key={index} className="top_nav_search_people_data">
              <Skeleton active avatar paragraph={{rows: 1}} loading={loading}>
                <li className="user_img_div">
                  {user?.avatar ? (
                    <img className="user_img" src={user?.avatar} alt="img" />
                  ) : (
                    <Avatar {...stringAvatar(user?.name)} />
                  )}
                </li>
                <li>
                  <h3>{user?.name}</h3>
                  <p ref={textRef}>
                    {user?.email}
                    <span className="icon_copy" onClick={copyToClipboard}>
                      {copySuccess ? <CheckIcon /> : <CopyIcon />}
                    </span>
                  </p>
                </li>
              </Skeleton>
            </ul>
          ))}
        </div>
      </div>
    </div>
  );
};
