import React, {useEffect, useRef} from 'react';
import {IoSearchOutline} from 'react-icons/io5';
import {Nav, Tab} from 'react-bootstrap';
import {useState} from 'react';
import {Block, Confirm, Notify} from 'notiflix';
import {MdOutlineAddPhotoAlternate} from 'react-icons/md';
import {PageAllTopTitle} from '../../../../common/ManagementComponents/PageAllTopTitle';
import AlkiPng from '../../../../../pics/Management/akki.png';
import AllUserIcon from '../../../../../pics/Management/AllUser.png';
import {
  FullBtn,
  OutlineBtn,
} from '../../../../common/ManagementComponents/Btn/Btn';
import Axios from './../../../../../config/api';
import {InputBox} from '../../../../common/ManagementComponents/form/Form';
import {AiFillEye, AiFillEyeInvisible} from 'react-icons/ai';
import {useForm} from 'react-hook-form';
import {StatusCode} from '../../../../../constants';
import {MemoryClient} from '../../../../../utils';
export const Settings = () => {
  const Profile = () => {
    //hooks
    const inputRef = useRef();
    const {
      register,
      handleSubmit,
      formState: {errors},
      control,
      reset,
      getValues,
    } = useForm();
    //preparing states
    const [avatar, setAvatar] = useState(null);
    const [profileData, setProfileData] = useState({});
    const [serverErrors, setServerErrors] = useState({});
    const [editeProfile, setShowEditeProfile] = useState(false);
    //hooks
    useEffect(() => {
      //getting user profile data
      fetchUserProfileData();
    }, []);
    //functions
    const fetchUserProfileData = async () => {
      //showing loading
      Block.circle('.profile');
      //getting user profile data
      Axios.fetch('maxproject/user/profile').then(({data}) => {
        //hiding loader
        Block.remove('.profile');
        //getting profile data
        const {profile} = data;
        //setting profile data
        setProfileData(profile);
      });
    };

    const EditeProfileHandleChange = () => {
      setShowEditeProfile(!editeProfile);
    };
    const FilehandleClick = () => {
      inputRef.current.click();
    };
    const handleFileChange = e => {
      const file = e.target.files[0];
      setAvatar(file);
    };
    const handleFormSubmit = data => {
      //preparing form data
      let formdata = new FormData();
      //adding form data
      formdata.append('name', data.name);
      formdata.append('phone', data.phone);
      //adding attachments
      formdata.append('avatar', avatar);
      //clearing server errors
      setServerErrors({});
      //showing up loader
      Block.circle('#form', 'Updating...');
      //initiating network request
      Axios.put('maxproject/user/profile', formdata)
        .then(({data}) => {
          Block.remove('#form');
          Notify.success(data.message);
          setShowEditeProfile(false);
          setProfileData(data.profile);
        })
        .catch(err => {
          //removing loader
          Block.remove('#form');
          //checking for any error message
          if (err?.response?.data?.message)
            Notify.failure(err?.response?.data?.message);
          //checking and showing express validation errors
          if (err?.response?.status == StatusCode.ExpressErrors)
            err?.response?.data?.errors.forEach(el =>
              setServerErrors(old => ({...old, [el.param]: el.msg})),
            );
        });
    };
    return (
      <>
        <div className="row">
          <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
            <div className="dashbaord_white_card position-relative profile">
              {editeProfile ? (
                <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
                  <form id="form" onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="col-12">
                      <div className="setting_profile_img_div">
                        <div className="setting_profile_edit_img_div">
                          <UserPhoto
                            imageUrl={profileData?.avatar || AllUserIcon}
                          />
                          <div
                            className="setting_profile_edit_inner_img_div"
                            onClick={FilehandleClick}>
                            <MdOutlineAddPhotoAlternate />
                          </div>
                          <input
                            type="file"
                            ref={inputRef}
                            onChange={handleFileChange}
                            style={{display: 'none'}}
                          />
                        </div>
                        <div>
                          <h3>{profileData?.name}</h3>
                          <p>{profileData?.Designation?.name}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                          <InputBox
                            type="text"
                            label="Full name"
                            register={() =>
                              register('name', {
                                required: 'Please enter your name',
                                value: profileData?.name,
                              })
                            }
                            errorMessage={
                              errors.name?.message || serverErrors?.name
                            }
                          />
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                          <div className="received_list_modal_disabled_input">
                            <InputBox
                              type="text"
                              defaultValue={profileData?.Designation?.name}
                              disabled
                              className="opacity-25"
                              label="Designation"
                            />
                          </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                          <div className="received_list_modal_disabled_input2">
                            <InputBox
                              type="email"
                              defaultValue={profileData?.email}
                              disabled
                              className=" opacity-25"
                              label="Email"
                            />
                          </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                          <InputBox
                            register={() =>
                              register('phone', {
                                required: 'Please enter your phone',
                                value: profileData?.phone,
                              })
                            }
                            errorMessage={
                              errors.phone?.message || serverErrors?.phone
                            }
                            type="number"
                            defaultValue={profileData?.phone}
                            className="opacity-25"
                            label="Phone"
                          />
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                          <div className="received_list_modal_disabled_input2">
                            <InputBox
                              type="text"
                              defaultValue={
                                profileData?.Designation?.Department?.name
                              }
                              disabled
                              className=" opacity-25"
                              label="Department"
                            />
                          </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                          <PasswordInput
                            label="New Password"
                            type="password"
                            EyeIcon={true}
                            className="form-control intput_box"
                            placeholder="New Password"
                            defaultValue="wqwrwerwerew"
                          />
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                          <PasswordInput
                            label="Confirm Password"
                            type="password"
                            EyeIcon={true}
                            className="form-control intput_box"
                            placeholder="New Password"
                            defaultValue="wqwrwerwerew"
                          />
                        </div>
                        <div className="col-12">
                          <div className="setting_profile_save_btn">
                            <OutlineBtn
                              name="Cancel"
                              onClick={EditeProfileHandleChange}
                            />
                            <FullBtn name="Update" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <>
                  <div className="setting_profile_edit_btn">
                    <FullBtn onClick={EditeProfileHandleChange} name="Edit" />
                  </div>
                  <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
                    <div className="col-12">
                      <div className="setting_profile_img_div">
                        <UserPhoto
                          imageUrl={profileData?.avatar || AllUserIcon}
                        />
                        <div>
                          <h3>{profileData?.name}</h3>
                          <p>{profileData?.Designation?.name}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                          <div className="setting_profile_text_div">
                            <p>Full name</p>
                            <h3 className=" text-capitalize">
                              {profileData?.name}
                            </h3>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                          <div className="setting_profile_text_div">
                            <p>Designation</p>
                            <h3 className=" text-capitalize ">
                              {profileData?.Designation?.name}
                            </h3>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                          <div className="setting_profile_text_div">
                            <p>Email</p>
                            <h3>{profileData?.email}</h3>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                          <div className="setting_profile_text_div">
                            <p>Phone</p>
                            <h3>{profileData?.phone}</h3>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                          <div className="setting_profile_text_div">
                            <p>Department</p>
                            <h3>
                              {profileData?.Designation?.Department?.name}
                            </h3>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                          <div className="setting_profile_text_div">
                            <p>Password</p>
                            <h3>************</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
  const Password = () => {
    //hooks
    const inputRef = useRef();
    const {
      register,
      handleSubmit,
      formState: {errors},
      watch,
      setError,
      reset,
    } = useForm();
    //preparing states
    const [serverErrors, setServerErrors] = useState({});
    const [rePassError, setRePassError] = useState(null);
    //functions
    const handleFormSubmit = data => {
      //clearing server errors
      setServerErrors({});
      //showing up loader
      Block.circle('#form', 'Updating...');
      //initiating network request
      Axios.put('maxproject/user/password', {
        old_pass: data.oldPass,
        new_pass: data.newPass,
      })
        .then(({data}) => {
          Block.remove('#form');
          Notify.success(data.message);
          MemoryClient.set('lp', data.token);
          MemoryClient.set('rlp', data.refreshToken);
          reset({oldPass: '', newPass: '', rePass: ''});
        })
        .catch(err => {
          //removing loader
          Block.remove('#form');
          //checking for any error message
          if (err?.response?.data?.message)
            Notify.failure(err?.response?.data?.message);
          //checking and showing express validation errors
          if (err?.response?.status == StatusCode.ExpressErrors)
            err?.response?.data?.errors.forEach(el =>
              setServerErrors(old => ({...old, [el.param]: el.msg})),
            );
        });
    };
    // Set up validation rules
    const validateRepeatPassword = value => {
      const password = watch('newPass', '');
      if (value === password) {
        setRePassError(null);
        return true;
      } else {
        setError('rePass', {
          type: 'manual',
          message: 'New password and repeat password does not match',
        });
        setRePassError('New password and repeat password does not match');
        return false;
      }
    };
    return (
      <>
        <div className="row">
          <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
            <div className="dashbaord_white_card position-relative profile">
              <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
                <form id="form" onSubmit={handleSubmit(handleFormSubmit)}>
                  <div className="col-12">
                    <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
                      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <InputBox
                          type="password"
                          label="Old Password"
                          register={() =>
                            register('oldPass', {
                              required: 'Please enter your old password',
                            })
                          }
                          errorMessage={
                            errors.oldPass?.message || serverErrors?.oldPass
                          }
                        />
                      </div>
                      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <InputBox
                          type="password"
                          label="New Password"
                          register={() =>
                            register('newPass', {
                              required: 'Please enter your new password',
                            })
                          }
                          errorMessage={
                            errors.newPass?.message || serverErrors?.newPass
                          }
                        />
                      </div>
                      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <InputBox
                          type="password"
                          label="Repeat Password"
                          register={() =>
                            register('rePass', {
                              required: 'Please enter your new password again',
                              validate: validateRepeatPassword,
                            })
                          }
                          errorMessage={
                            errors.rePass?.message ||
                            serverErrors?.rePass ||
                            rePassError
                          }
                        />
                      </div>
                      <div className="col-12">
                        <div className="setting_profile_save_btn">
                          <FullBtn name="Update" />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="custom_container">
        <div className="row g-3 header_my">
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-1 col-sm-12 col-12 order-md-1 order-2">
            <PageAllTopTitle title="Settings" />
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 order-md-2 order-1">
            <div className="header_input_div">
              <IoSearchOutline />
              <input
                type="text"
                className="form-control"
                placeholder="Search any approval"
              />
            </div>
          </div>
        </div>
        <div className="project_list_tabs_main_div">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <div className="project_tab_mb">
              <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
                <div className="col-xxl-10 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                  <Nav variant="pills" className="flex">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Profile</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="pass">Password</Nav.Link>
                    </Nav.Item>
                    <Nav.Item style={{opacity: '0.4', cursor: 'no-drop'}}>
                      <Nav.Link eventKey="Notification" disabled>
                        Notification
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item style={{opacity: '0.4', cursor: 'no-drop'}}>
                      <Nav.Link eventKey="Approvals" disabled>
                        Approvals
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
            </div>

            <Tab.Content>
              <Tab.Pane eventKey="first">
                <Profile />
              </Tab.Pane>
              <Tab.Pane eventKey="pass">
                <Password />
              </Tab.Pane>
              <Tab.Pane eventKey="Notification">2</Tab.Pane>
              <Tab.Pane eventKey="Approvals">3</Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};
const PasswordInput = props => {
  const [showPass, setShowPass] = useState(false);
  return (
    <>
      <div className="ApprovalDetailsPage_date_picker">
        <label style={{marginLeft: '13px'}}>{props.label}</label>
        <div className="ApprovalDetailsPage_date_picker_iner">
          <span onClick={() => setShowPass(!showPass)}>
            <p>
              {props.EyeIcon &&
                (showPass ? <AiFillEye /> : <AiFillEyeInvisible />)}
            </p>
          </span>
          <input
            disabled
            type={props.EyeIcon ? (showPass ? 'text' : props.type) : props.type}
            className={props.className}
            placeholder={props.placeholder}
            defaultValue={props.defaultValue}
          />
        </div>
      </div>
    </>
  );
};
const UserPhoto = ({imageUrl}) => {
  const hasImage =
    imageUrl !== undefined && imageUrl !== null && imageUrl !== '';
  const defaultImageUrl = AllUserIcon;
  return (
    <div>
      {hasImage ? (
        <img src={imageUrl} alt="User Provided Image" />
      ) : (
        <img src={defaultImageUrl} alt="Default Image" />
      )}
    </div>
  );
};
