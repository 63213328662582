import {useState, useEffect} from 'react';
import NotificationImg from '../../common/components/img/Img';
import {RiDeleteBinLine} from 'react-icons/ri';
import {PageAllTopTitle} from '../../common/ManagementComponents/PageAllTopTitle';
import NoNotificationImg from '../../../pics/no_notifications.png';
import {Block} from 'notiflix/build/notiflix-block-aio';
import Axios from './../../../config/api';
import moment from 'moment';
import {Notify} from 'notiflix/build/notiflix-notify-aio';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setGlobal} from '../../../redux/slices/globalSlice';

export const NotificationsAll = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    fetchNotifications();
    return () => {};
  }, []);

  useEffect(() => {
    handleNotificationAction();
  }, [params]);

  const handleNotificationAction = () => {
    if (!params.has('action')) return;
    //switch case
    switch (params.get('action')) {
      case 'task':
        return dispatch(
          setGlobal({taskId: params.get('context_id'), showTask: true}),
        );
      case 'project':
        return navigate(
          '/Management/project_details/' + params.get('context_id'),
        );
      case 'meeting':
        return navigate('/Management/meeting/' + params.get('context_id'));
      case 'received_approval':
        return navigate(
          '/Management/received_approval/' + params.get('context_id'),
        );
      case 'sent_approval':
        return navigate(
          '/Management/sent_approval/' + params.get('context_id'),
        );
      default:
        break;
    }
  };
  const handleOnNotificationClick = data => {
    //marking notification as read
    if (!data?.isRead) handleMarkNotificationaAsRead(data?.id, data?.idx);
    //getting notifaction payload
    let payload = data?.payload;
    //handle on notification click event
    if (typeof payload == 'string') payload = JSON.parse(payload);
    //switch case
    switch (payload?.type) {
      case 'TASK':
        return dispatch(
          setGlobal({taskId: payload?.context_id, showTask: true}),
        );
      case 'PROJECT':
        return navigate('/Management/project_details/' + payload?.context_id);
      case 'MEETING':
        return navigate('/Management/meeting/' + payload?.context_id);
      case 'RECEIVED_APPROVAL':
        return navigate('/Management/received_approval/' + payload?.context_id);
      case 'SENT_APPROVAL':
        return navigate('/Management/sent_approval/' + payload?.context_id);
      default:
        break;
    }
  };

  const fetchNotifications = () => {
    Block.circle('.loading');
    Axios.fetch(`maxproject/notification`)
      .then(({data}) => {
        Block.remove('.loading');
        setNotifications(data?.notifications);
      })
      .catch(err => {
        Block.remove('.loading');
      });
  };
  const handleRemoveNotification = (id, idx) => {
    Block.dots('#noti_' + idx);
    Axios.delete(`maxproject/notification/${id}`)
      .then(({data}) => {
        Block.remove('#noti_' + idx);
        Notify.success(data?.message);
        setNotifications(old => {
          let arr = old.slice();
          arr.splice(idx, 1);
          return arr;
        });
      })
      .catch(err => {
        Block.remove('#noti_' + idx);
      });
  };
  const handleMarkNotificationaAsRead = (id, idx) => {
    Axios.post(`maxproject/notification/read/${id}`)
      .then(({data}) => {
        setNotifications(old => {
          let arr = old.slice();
          arr[idx]["isRead"] = 1;
          return arr;
        });
      })
      .catch(err => {
        Block.remove('#noti_' + idx);
      });
  };
  const NoNotifications = () => (
    <div className="no_notifications_div">
      <div className="no_notifications_inner">
        <NotificationImg
          className="no_notifications"
          backgroundImage={NoNotificationImg}
        />
        <h3>No notifications yet</h3>
      </div>
    </div>
  );
  const Notification = props => (
    <div
      style={{cursor: 'pointer'}}
      className="col-12"
      id={`noti_${props.idx}`}>
      <ul
        className="notification_outer"
        style={{
          background: props?.isRead ? 'white' : '#f5f7ff',
          ...(props?.isRead ? {border: '0.5px solid #c8c8c8'} : {}),
        }}>
        <li onClick={() => handleOnNotificationClick(props)}>
          <div className="notification_img_div">
            <NotificationImg
              className="notification_img"
              backgroundImage={props?.User?.avatar}
            />
          </div>
        </li>
        <li
          onClick={() => handleOnNotificationClick(props)}
          className="notification_outer_text">
          <div>
            <p>
              {props?.title}
              <b> {props?.body}</b>.
            </p>
            <small>{moment(props?.createdAt).calendar()}</small>
          </div>
        </li>
        <li className="notification_outer_delete">
          <RiDeleteBinLine
            onClick={() => handleRemoveNotification(props.id, props.idx)}
          />
        </li>
      </ul>
    </div>
  );
  return (
    <div className="custom_container">
      <PageAllTopTitle title="Notifications" />
      {/* no notification yat ui */}
      <div className="notifications_all_div loading">
        {!notifications.length ? <NoNotifications /> : null}
        {/* no notification yat ui end */}
        <div className="row g-3">
          {notifications.map((val, i) => (
            <Notification idx={i} key={i} {...val} />
          ))}
        </div>
      </div>
    </div>
  );
};
