import {PageAllTopTitle} from '../../../../common/ManagementComponents/PageAllTopTitle';
import {BsArrowLeft} from 'react-icons/bs';
import Axios from './../../../../../config/api';
import {useForm} from 'react-hook-form';
import {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {StatusCode} from '../../../../../constants';
import {Notify} from 'notiflix/build/notiflix-notify-aio';
import {Block} from 'notiflix/build/notiflix-block-aio';
import {TextareaBox} from '../../../../common/ManagementComponents/form/Form';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';

export const ClientsSmsForm = () => {
  return (
    <div className="custom_container">
      <PageAllTopTitle link={-1} icon={<BsArrowLeft />} title="back" />
      <div className="custom_container_iner">
        <Form />
      </div>
    </div>
  );
};
const Form = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [serverErrors, setServerErrors] = useState({});
  const [smsData, setSmsData] = useState([]);
  const [client, setClient] = useState([]);
  const [permissions, setPermissions] = useState({});

  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm();

  useEffect(async () => {
    fetchClient();
    if (params?.client_id) fetchSmsData();

    return () => {};
  }, []);

  const fetchSmsData = () => {
    //showing up loader
    Block.circle('.custom_container_iner', 'Loading...');
    Axios.fetch(`maxproject/client_sms/${params.client_id}`)
      .then(({data}) => {
        setSmsData(data.data);
        setPermissions(data?.Permissions);
        // reset({
        //   message: sms?.message,
        //   client_id: sms?.client_id,
        // });
        Block.remove('.custom_container_iner');
      })
      .catch(err => {
        //removing loader
        Block.remove('.custom_container_iner');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchClient = () => {
    //showing up loader
    Block.circle('.custom_container_iner', 'Loading...');
    Axios.fetch(`maxproject/client/` + params?.client_id)
      .then(({data}) => {
        setClient(data?.client);
        Block.remove('.custom_container_iner');
      })
      .catch(err => {
        //removing loader
        Block.remove('.custom_container_iner');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleFormSubmit = data => {
    //clearing server errors
    setServerErrors({});
    //showing up loader
    Block.circle('.custom_container_iner', 'Saving...');
    //initiating network request
    // const Request = params?.client_id
    //   ? Axios.put('maxproject/client_sms/' + params.sms_id, data)
    //   : Axios.post('maxproject/client_sms', data);
    const Request = Axios.post(
      'maxproject/client_sms/' + params?.client_id,
      data,
    );

    Request.then(({data}) => {
      Block.remove('.custom_container_iner');
      Notify.success(data.message);
      setSmsData(old => [...old, data.data]);
      reset({message: ''});
    }).catch(err => {
      //removing loader
      Block.remove('.custom_container_iner');
      //checking for any error message
      if (err?.response?.data?.message)
        Notify.failure(err?.response?.data?.message);
      //checking and showing express validation errors
      if (err?.response?.status == StatusCode.ExpressErrors)
        err?.response?.data?.errors.forEach(el =>
          setServerErrors(old => ({...old, [el.param]: el.msg})),
        );
    });
  };
  const Chat = () => {
    const LeftChat = ({data}) => (
      <div className="col-12 mt-0">
        <div className="left_chat_div">
          <ul>
            <li>
              <div className="left_chat_user">
                <Stack direction="row" spacing={2}>
                  <Avatar
                    sx={{width: 26, height: 26}}
                    alt={client?.client_name}
                    src={client?.avatar}
                  />
                </Stack>
              </div>
              <div>
                <div className="row g-2">
                  <div className="col-12 ">
                    <div className="li_left_chat_div">
                      <div className="left_chat_div_inner">{data?.message}</div>
                    </div>
                  </div>
                </div>
                <div className="left_chat_time" style={{textTransform: 'none'}}>
                  {moment(data?.createdAt).calendar()}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
    const RightChat = ({data, idx}) => {
      const [loading, setLoading] = useState(false);
      const handleSendSms = () => {
        //showing up loader
        setLoading(true);
        //initiating network request
        Axios.post('maxproject/client_sms/send_sms/' + data.id)
          .then(({data}) => {
            Block.remove('#msg-' + idx);
            Notify.success(data.message);
            setSmsData(old => {
              let arr = old.slice();
              arr[idx].isSent = 1;
              return arr;
            });
            setLoading(false);
          })
          .catch(err => {
            //removing loader
            Block.remove('#msg-' + idx);
          });
      };
      return (
        <div className="col-12 mt-0">
          <div className="right_chat_div">
            <ul>
              <li style={{alignItems: 'baseline'}}>
                {!data?.isSent ? (
                  <LoadingButton
                    onClick={handleSendSms}
                    style={{
                      fontSize: '10px',
                      minWidth: '40px',
                      marginRight: 10,
                    }}
                    size="small"
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SendIcon />}
                    variant="outlined">
                    {loading ? 'Sending...' : 'Send'}
                  </LoadingButton>
                ) : null}

                <div>
                  <div className="text-end">
                    <div className="row g-2">
                      <div className="col-12 col_div_inner">
                        <div className="right_chat_div">
                          <div className="right_chat_div_inner">
                            {data?.message}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="left_chat_time"
                      style={{textTransform: 'none'}}>
                      {moment(data?.createdAt).calendar()}
                    </div>
                  </div>
                </div>
                <div className="right_chat_user">
                  <Stack direction="row" spacing={2}>
                    <Avatar
                      sx={{width: 26, height: 26}}
                      alt={data?.User?.name}
                      src={data?.User?.avatar}
                    />
                  </Stack>
                </div>
              </li>
            </ul>
          </div>
        </div>
      );
    };
    return (
      <div className="chat_mes_main_div" style={{height: '40rem'}}>
        <div className="chating_div" style={{height: '28rem'}}>
          <div className="row g-4 mb-5">
            {smsData?.map((sms, i) =>
              sms.isReply ? (
                <LeftChat data={sms} idx={i} />
              ) : (
                <RightChat data={sms} idx={i} />
              ),
            )}
          </div>
        </div>
        {permissions?.post ? (
          <div className="chat_mes_main_div_input_box">
            <div className="chat_mes_input_div">
              <Box
                sx={{
                  width: '100%',
                }}>
                <div className="chat_Input_main_div" style={{marginLeft: 0}}>
                  <form id="form" onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
                      <div
                        className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                        style={{marginTop: 0}}>
                        <TextareaBox
                          rows={3}
                          // defaultValue={smsData?.message}
                          register={() =>
                            register('message', {
                              required: 'Message is required',
                              // value: smsData?.message,
                            })
                          }
                          type="text"
                          placeholder="Please type message here"
                          label="Message*"
                          errorMessage={
                            errors.message?.message || serverErrors?.message
                          }
                        />
                      </div>

                      <div className="col-12">
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="ms-2">
                            <a
                              className="me-2 management_btn_outline"
                              onClick={() => navigate(-1)}>
                              <span>Cancel</span>
                            </a>
                          </div>
                          <div className="ms-2">
                            <button
                              form="form"
                              type="submit"
                              className="management_btn_full2">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Box>
            </div>
          </div>
        ) : null}
      </div>
    );
  };
  return (
    <div className="CompanySetup_main_div">
      <div className="CompanySetup_inner_main_div" style={{width: '80%'}}>
        <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
          <div className="create_task_title">
            <h3>{client?.client_name}</h3>
          </div>
          <Chat />
        </div>
      </div>
    </div>
  );
};
